import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, insuranceCompanyFilterOptions } from "../utils";

import { Card } from "../Card";

import { EmptyProfileInsurance } from "./EmptyProfileInsurance";
import { EditProfileInsurance } from "./EditProfileInsurance";
import { CompletedProfileInsurance } from "./CompletedProfileInsurance";

import "./styles.scss";

export const MemberProfileInsurance = ({
    className,
    isEmpty,
    isEditing,
    onEditButtonClick,
    onAddButtonClick,

    // -- Empty Props
    emptyTitle,
    emptyDetails,

    // -- Edit Props
    editTitle,

    // Formik props
    values,
    errors,
    touched,
    handleChange,
    handleFocus,
    handleBlur,

    insuranceCompaniesOptions,
    stateOfInsurancePlanOptions,
    policyHolderOptions,

    files,
    onBrowseButtonClicked,
    onFileDelete,

    // -- Completed Props
    completedTitle,

    insuranceCompany,
    policyHolder,
    memberId,
    groupId,
    stateOfInsurancePlan,
    policyHolderName,
    policyHolderDOB,

    insuranceCardImgFrontSrc,
    insuranceCardImgFrontStatus,
    insuranceCardImgBackSrc,
    insuranceCardImgBackStatus,
    onInsuranceCardImgFrontClick,
    onInsuranceCardImgBackClick,

    onSubmitButtonClick,
    onCancelButtonClick,

    // Carrier Specific
    memberIdPlaceholder,
    memberIdTip,
    memberIdTooltipContents,
    groupIdTooltipContents,

    noInsurance,
    ...props
}) => {
    return (
        <Card
            className={createClassNameString(["apti-MemberProfileInsurance", className])}
            {...props}>
            {isEmpty && !isEditing && (
                <EmptyProfileInsurance
                    title={emptyTitle}
                    details={emptyDetails}
                    onAddButtonClick={onAddButtonClick}
                />
            )}
            {isEditing && (
                <EditProfileInsurance
                    title={editTitle}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    insuranceCompaniesOptions={insuranceCompaniesOptions}
                    insuranceCompanyFilterOptions={insuranceCompanyFilterOptions}
                    stateOfInsurancePlanOptions={stateOfInsurancePlanOptions}
                    policyHolderOptions={policyHolderOptions}
                    files={files}
                    onBrowseButtonClicked={onBrowseButtonClicked}
                    onFileDelete={onFileDelete}
                    onSubmitButtonClick={onSubmitButtonClick}
                    onCancelButtonClick={onCancelButtonClick}
                    noInsurance={noInsurance}
                    memberIdPlaceholder={memberIdPlaceholder}
                    memberIdTip={memberIdTip}
                    memberIdTooltipContents={memberIdTooltipContents}
                    groupIdTooltipContents={groupIdTooltipContents}
                />
            )}
            {!isEmpty && !isEditing && (
                <CompletedProfileInsurance
                    title={completedTitle}
                    insuranceCompany={insuranceCompany}
                    policyHolder={policyHolder}
                    memberId={memberId}
                    groupId={groupId}
                    stateOfInsurancePlan={stateOfInsurancePlan}
                    policyHolderName={policyHolderName}
                    policyHolderDOB={policyHolderDOB}
                    insuranceCardImgFrontSrc={insuranceCardImgFrontSrc}
                    insuranceCardImgFrontStatus={insuranceCardImgFrontStatus}
                    insuranceCardImgBackSrc={insuranceCardImgBackSrc}
                    insuranceCardImgBackStatus={insuranceCardImgBackStatus}
                    onEditButtonClick={onEditButtonClick}
                    onInsuranceCardImgFrontClick={onInsuranceCardImgFrontClick}
                    onInsuranceCardImgBackClick={onInsuranceCardImgBackClick}
                />
            )}
        </Card>
    );
};

MemberProfileInsurance.propTypes = {
    className: PropTypes.string,
    isEmpty: PropTypes.bool,
    isEditing: PropTypes.bool,
    onEditButtonClick: PropTypes.func,
    onAddButtonClick: PropTypes.func,

    // -- Empty Props
    emptyTitle: PropTypes.string,
    emptyDetails: PropTypes.string,

    // -- Edit Props
    editTitle: PropTypes.string,

    // Formik props
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,

    insuranceCompaniesOptions: PropTypes.arrayOf(PropTypes.object),
    stateOfInsurancePlanOptions: PropTypes.arrayOf(PropTypes.object),
    policyHolderOptions: PropTypes.arrayOf(PropTypes.object),

    files: PropTypes.arrayOf(PropTypes.string),
    onBrowseButtonClicked: PropTypes.func,
    onFileDelete: PropTypes.func,

    // -- Completed Props
    completedTitle: PropTypes.string,

    insuranceCompany: PropTypes.string,
    policyHolder: PropTypes.string,
    memberId: PropTypes.string,
    groupId: PropTypes.string,
    stateOfInsurancePlan: PropTypes.string,
    policyHolderName: PropTypes.string,
    policyHolderDOB: PropTypes.string,

    insuranceCardImgFrontSrc: PropTypes.string,
    insuranceCardImgFrontStatus: PropTypes.string,

    insuranceCardImgBackSrc: PropTypes.string,
    insuranceCardImgBackStatus: PropTypes.string,

    onInsuranceCardImgFrontClick: PropTypes.func,
    onInsuranceCardImgBackClick: PropTypes.func,

    onSubmitButtonClick: PropTypes.func,
    onCancelButtonClick: PropTypes.func,

    noInsurance: PropTypes.bool,
};

MemberProfileInsurance.defaultProps = {
    className: undefined,
    isEmpty: false,
    isEditing: false,
    onEditButtonClick: () => {},
    onAddButtonClick: () => {},

    // -- Empty Props
    emptyTitle: "",
    emptyDetails: "",

    // -- Edit Props
    editTitle: "",

    // Formik props
    values: {},
    errors: {},
    touched: {},
    handleChange: () => {},
    handleFocus: () => {},
    handleBlur: () => {},

    insuranceCompaniesOptions: [],
    stateOfInsurancePlanOptions: [],
    policyHolderOptions: [],

    files: [],
    onBrowseButtonClicked: () => {},
    onFileDelete: () => {},

    // -- Completed Props
    completedTitle: "",

    insuranceCompany: "",
    policyHolder: "",
    memberId: "",
    groupId: "",
    stateOfInsurancePlan: "",
    policyHolderName: "",
    policyHolderDOB: "",

    insuranceCardImgFrontSrc: "",
    insuranceCardImgFrontStatus: "",

    insuranceCardImgBackSrc: "",
    insuranceCardImgBackStatus: "",

    onInsuranceCardImgFrontClick: () => {},
    onInsuranceCardImgBackClick: () => {},

    onSubmitButtonClick: () => {},
    onCancelButtonClick: () => {},

    noInsurance: false,
};
