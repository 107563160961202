import moment from "moment-timezone";
import { toSentenceCase } from "../../utils/filters";
import { STATUS_LOOKUP } from "./constants";
import { api } from "../../APIRequests";

export const formatTimestamp = (timestamp) => {
    if (!timestamp) {
        return;
    }
    return moment.utc(timestamp).local().format("MM/DD/YYYY hh:mma");
};

export const formatInsuranceType = (insuranceType) => {
    if (!insuranceType) {
        return "Primary";
    }
    return toSentenceCase(insuranceType);
};

export const getStatusClass = (status) => {
    if (!status || !STATUS_LOOKUP[status]) {
        return;
    }
    return STATUS_LOOKUP[status].class;
};

export const getFormattedStatus = (status) => {
    if (!status || !STATUS_LOOKUP[status]) {
        return;
    }
    return STATUS_LOOKUP[status].formattedStatus;
};

export const getStatusDefinition = (status) => {
    if (!status || !STATUS_LOOKUP[status]) {
        return;
    }
    return STATUS_LOOKUP[status].definition;
};

export const buildErrorDescription = (error) => {
    return error && error["description"] ? error["description"] + "." : "";
};

export const buildErrorCode = (error) => {
    return error && error["code"] ? "(Error Code: " + error["code"] + ")" : "";
};

export const buildErrorMessages = (errors) => {
    const messages = [];
    errors.forEach((error) => {
        const description = buildErrorDescription(error);
        const code = buildErrorCode(error);

        const message = description && code ? description + " " + code : description + code;

        if (message !== "") {
            messages.push(message);
        }
    });
    return messages;
};

async function getRawResponsePresignedUrl(eligibility) {
    const data = {
        method: "get_object",
        entity_id: eligibility["id"],
        entity_type: "raw_eligibility_response",
        file_type: "ELIGIBILITY",
        file_sub_type: eligibility["insurance_type"],
        file_key: eligibility["raw_response_key"],
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ data });
    return response.message;
}

export const showRawResponse = async (eligibility) => {
    let file_url = await getRawResponsePresignedUrl(eligibility);
    window.open(file_url);
};
