// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form } from "formik";
import {
    BASIC_SCHEMA,
    PATIENT_BASIC_SCHEMA,
    PREFERRED_NAME_SCHEMA_NON_PATIENT,
} from "../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import FormikInput from "../../UI/FormikInput";
import ProfileDetailsCard from "./ProfileDetailsCard";
import {
    PATIENT_VALIDATION_SCHEMA,
    VALIDATION_SCHEMA,
} from "../../../containers/Dashboard/Patient/schema/PatientProfileValidation";
import { renderTitleWithValue } from "./index";
import SubmitControls from "./SubmitControls";
import Hr from "../../UI/Hr";
import FormikMobileInput from "../../UI/formikMobileInput";
import { isUserAdmin, isUserPatient } from "../../../redux/actions/auth";
import { CustomForm } from "../../../component-library/CustomForm";

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileData: props.data,
            editableData: null,
            edit: props.edit,
        };
    }

    componentDidMount() {
        let profileData = this.state.profileData;
        if (isUserAdmin()) {
            profileData = this.trasformDOBsToValidStrings(profileData);
        }

        this.setState({
            editableData: profileData,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            let profileData = this.state.profileData;
            if (isUserAdmin()) {
                profileData = this.trasformDOBsToValidStrings(profileData);
            }

            this.setState({
                editableData: profileData,
            });
        }
    }

    toggleEditBasicInfo = () => {
        this.setState((ps) => {
            return {
                edit: !ps.edit,
            };
        });
    };

    renderBasicForm = (formikProps) => {
        let schema = PATIENT_BASIC_SCHEMA;

        if (!isUserPatient()) {
            schema = BASIC_SCHEMA;
        }

        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container px-0">
                        <div className="row">
                            {schema.map((formEl) => {
                                const testId = `PatientProfileBasicInfo_${formEl.name}`;
                                if (formEl.elementType === "segment") {
                                    return (
                                        <h6 key={formEl.displayName} className="col-12">
                                            <b>{formEl.displayName}</b>
                                        </h6>
                                    );
                                } else if (formEl.elementType === "readOnly") {
                                    return (
                                        <div key={formEl.name} className={"col-12 col-lg-6"}>
                                            <FormikInput
                                                disabled
                                                inputBorderDisabled="border-0 px-0"
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={formEl.name} className="col-12 col-lg-6">
                                            {formEl.name === "email" && this.state.edit ? (
                                                <FormikInput
                                                    disabled
                                                    inputBorderDisabled="border-0 px-0"
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name == "mobile" ? (
                                                <FormikMobileInput
                                                    value={formikProps && formikProps.values.mobile}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "mobile",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("mobile");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                    testId={testId}
                                                />
                                            ) : formEl.name == "dob" ? (
                                                <FormikMobileInput
                                                    value={formikProps && formikProps.values.dob}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "dob",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("dob");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                    testId={testId}
                                                />
                                            ) : (
                                                <FormikInput
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                    testId={testId}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="mb-3 d-lg-none">
                    <Hr />
                </div>
                <SubmitControls cancelHandler={this.toggleEditBasicInfo} />
            </Form>
        );
    };

    alteredFormData = (data) => {
        let alteredData = { ...data };

        let DOBnumber = {
            dob: alteredData.dob,
            // primaryDOB: alteredData.primary_insurance.policy_dob,
            // secondaryDOB: alteredData.secondary_insurance.policy_dob,
        };

        DOBnumber = {
            dob: `${DOBnumber.dob.substring(0, 2)}/${DOBnumber.dob.substring(
                2,
                4,
            )}/${DOBnumber.dob.substring(4, 8)}`,
            // primaryDOB: `${DOBnumber.primaryDOB.substring(0, 2)}/${DOBnumber.primaryDOB.substring(2, 4)}/${DOBnumber.primaryDOB.substring(4, 8)}`,
            // secondaryDOB: `${DOBnumber.secondaryDOB.substring(0, 2)}/${DOBnumber.secondaryDOB.substring(2, 4)}/${DOBnumber.secondaryDOB.substring(4, 8)}`,
        };
        return DOBnumber;
    };

    trasformDOBsToValidStrings = (data) => {
        let updatedData = { ...data };
        if (updatedData.hasOwnProperty("dob")) {
            updatedData["dob"] = updatedData["dob"].replace(/\//g, "");
        }

        return updatedData;
    };

    submitHandler = (formData, action) => {
        let newFormData = { ...formData };
        if (isUserAdmin()) {
            let newDOBData = this.alteredFormData(formData);
            newFormData.dob = newDOBData.dob;
        }
        // newFormData.primary_insurance.policy_dob = newDOBData.primaryDOB;
        // newFormData.secondary_insurance.policy_dob = newDOBData.secondaryDOB;

        let data = { ...newFormData };
        this.setState((ps) => {
            return {
                profileData: { ...data },
                // editableProfileData: this.getEditableData(data),
                editableProfileData: null,
                edit: !ps.edit,
            };
        });

        let schema = PATIENT_BASIC_SCHEMA;

        if (!isUserPatient()) {
            schema = BASIC_SCHEMA;
        }

        this.props.submit(data, action, schema);
    };

    render = () => {
        let validationSchema = VALIDATION_SCHEMA;
        if (!isUserAdmin()) {
            validationSchema = PATIENT_VALIDATION_SCHEMA;
        }
        return (
            <ProfileDetailsCard
                onclick={this.toggleEditBasicInfo}
                edit={this.state.edit}
                heading="Basic Information"
                isEditable
                classes={this.props.classes}>
                <div className="PatientProfile__basic-info px-2 no-gutters row my-2 pt-4">
                    {!this.state.edit &&
                        iterableBasicInfo(this.state.profileData).map((item) => {
                            let value = item.value;
                            if (item.title === "Email") {
                                const valueSuffix = ` (${
                                    this.state.profileData.email_verified
                                        ? "verified"
                                        : "not verified"
                                })`;
                                value = `${value}${valueSuffix}`;
                            }

                            return (
                                <div key={item.title} className="col-12 col-lg-6">
                                    {renderTitleWithValue(
                                        item.title,
                                        value,
                                        item.subtitle,
                                        item.optional,
                                    )}
                                </div>
                            );
                        })}
                    {this.state.edit && (
                        <CustomForm
                            initialValues={this.state.editableData}
                            validationSchema={validationSchema}
                            onSubmit={this.submitHandler}
                            render={this.renderBasicForm}
                        />
                    )}
                </div>
            </ProfileDetailsCard>
        );
    };
}

export default BasicInfo;

export const iterableBasicInfo = (profileData) => [
    { title: "Legal First Name", value: profileData.first_name || "" },
    { title: "Legal Last Name", value: profileData.last_name || "" },
    {
        title: PREFERRED_NAME_SCHEMA_NON_PATIENT.elementConfig.label,
        value: profileData.preferred_name || "",
        optional: true,
    },
    { title: "Preferred Pronouns", value: profileData.preferred_pronouns || "", optional: true },
    { title: "Date of Birth", value: profileData.dob || "" },
    { title: "Gender", value: profileData.gender || "" },
    { title: "Mobile", value: profileData.mobile || "" },
    { title: "Email", value: profileData.email || "" },
    { title: "Street", value: profileData.address.street || "" },
    { title: "State", value: profileData.address.state || "" },
    { title: "City", value: profileData.address.city || "" },
    { title: "Zip", value: profileData.address.zip_code || "" },
];
