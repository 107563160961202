import React from "react";
import { Form } from "formik";
import FormikInput from "../../../../UI/FormikInput";
import { STATES } from "../../../../../constants/states";
import Button from "../../../../UI/Button";
import * as yup from "yup";
import { mergeObjectOnlyExistingFields } from "../../../../../utils/objectUtils";
import { CustomForm } from "../../../../../component-library/CustomForm";
import { COMPLETE_PROFILE_STEPS } from "./index";

export const CompleteProfileAddress = (props) => {
    const { setCurrentCompleteProfileStep, submitHandler, profile, closeModal } = props;

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <>
                            <div className={"col-12"}>
                                <FormikInput
                                    key="address.street"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "address.street",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Street Address",
                                            placeholder: "Enter Street Address",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12 col-lg-6"}>
                                <FormikInput
                                    key="address.city"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "address.city",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "City",
                                            placeholder: "Enter City",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12 col-lg-6"}>
                                <FormikInput
                                    key="address.state"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "address.state",
                                        elementType: "select",
                                        elementConfig: {
                                            options: STATES,
                                            label: "State",
                                            placeholder: "Enter State",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-6"}>
                                <FormikInput
                                    key="address.zip_code"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "address.zip_code",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Zip",
                                            placeholder: "Enter Zip",
                                        },
                                    }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="px-0 row">
                        <div className="col text-center">
                            <Button type="submit" className="Btn Btn--pri self-signup-btn">
                                Save & Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <CustomForm
                initialValues={mergeObjectOnlyExistingFields(ADDRESS_INITIAL_VALUES, profile)}
                validationSchema={addressValidationSchema}
                onSubmit={(formData, actions) => {
                    submitHandler(formData, actions);
                    setCurrentCompleteProfileStep(COMPLETE_PROFILE_STEPS.EMERGENCY_CONTACT);
                }}
                render={renderForm}
            />
        </>
    );
};

const ADDRESS_INITIAL_VALUES = {
    username: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
};

const addressValidationSchema = yup.object().shape({
    address: yup.object().shape({
        street: yup.string().trim().required("Street is required"),
        city: yup
            .string()
            .required("City is required")
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid city")
            .nullable(),
        state: yup.string().required("State is required").nullable(),
        zip_code: yup
            .string()
            .required("Zip code is required")
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
});
