// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import SearchBar from "../../../../components/UI/SearchBar";
import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../../../utils/filters";
import { api } from "../../../../APIRequests";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../../components/UI/ListView";
import "../../../../components/Provider/PatientView/styles.scss";
import Button from "../../../../components/UI/Button";
import { Select as BasicSelect } from "../../../../components/UI/StyledInput";
import Select, { components } from "react-select";
import { isAuthorized } from "../../../../redux/actions/auth";
import images from "../../../../utils/images";
import { connect } from "react-redux";
import Circle from "../../../../components/UI/Circle";
import { admin_bp, provider_case_manager, provider_peer } from "../../../../config/roles";
import { MemberHeaderHOC } from "../../../../component-library/hocs/MemberHeaderHOC";
import { getAutomationDataAttr } from "../../../../utils/automationUtils";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";
import store from "../../../../redux/store";

class CareTeamList extends Component {
    show_popup = getQueryParams("showAddMember", this.props.location.search);
    isCurrentUserAdmin =
        isAuthorized("admin:customer_success_executive") ||
        isAuthorized("admin:customer_success_manager") ||
        isAuthorized("admin:admin");
    isCurrentUserBHS = isAuthorized("provider:behavioral");

    constructor(props) {
        super(props);
        this.state = {
            isdisabled: true,
            providerType: "",
            providerName: null,
            providerTypeOptions: null,
            providerNameOptions: null,
            careTeam: "",
            search: "",
            showPopUp: this.show_popup === "true",
            patientId: this.props.match.params.patientId,
            careTeamProviderIds: [],
            patientInsurance: [],
        };
    }

    componentDidMount() {
        this.getPatientDetails();
        this.getCareTeamMemberList();
    }

    getProviderList = () => {
        // This line accomplishes multiple things
        // it temporarily hides all the calendar options and prevents peers/case managers
        // access to an endpoint they may not need
        if (isAuthorized(provider_case_manager) || isAuthorized(provider_peer)) {
            return;
        }
        let queryParams = { insurance: this.state.patientInsurance.join(",") };
        if (
            !(this.isCurrentUserAdmin || this.props.profile?.provider_type === "BEHAVIORAL_INTAKE")
        ) {
            queryParams["provider_type"] = "PRESCRIBE";
        }
        api.shared
            .fetch_providers({ queryParams })
            .then((response) => {
                if (response && response["providers"]) {
                    let providers = response["providers"];
                    providers = providers.filter(
                        (provider) =>
                            !this.state.careTeamProviderIds.includes(provider["username"]),
                    );
                    this.setState({
                        providers,
                        providerNameOptions: [],
                        providerTypeOptions: this.getProviderTypeOptions(),
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getPatientDetails = () => {
        let urlParams = { patientId: this.state.patientId };
        const options = { params: { showLoader: false } };
        api.shared
            .fetch_patient_details({ urlParams, options })
            .then((response) => {
                let newState = { patientInsurance: [] };
                if (response && response.user) {
                    const patient = response.user;
                    if (patient.primary_insurance && patient.primary_insurance.carrier) {
                        newState.patientInsurance.push(patient.primary_insurance.carrier);
                    }
                    if (
                        patient.secondary_insurance &&
                        patient.secondary_insurance.carrier &&
                        !newState.patientInsurance.includes(patient.secondary_insurance.carrier)
                    ) {
                        newState.patientInsurance.push(patient.secondary_insurance.carrier);
                    }
                }
                this.setState(newState);
                this.getProviderList();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getCareTeamMemberList = (refresh = false) => {
        let urlParams = { patientId: this.state.patientId };
        api.shared
            .fetch_patient_careteam({ urlParams })
            .then((response) => {
                if (response) {
                    const careTeam = response;
                    const careTeamProviderIds = careTeam.map((member) => member["provider_id"]);
                    this.setState(
                        {
                            careTeam: careTeam,
                            careTeamProviderIds: careTeamProviderIds,
                        },
                        () => {
                            if (refresh) {
                                this.setState({ providerType: "" });
                                this.getProviderList();
                            }
                        },
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    addTeamMember = async () => {
        const urlParams = { patientId: this.state.patientId };
        const queryParams = { providerId: this.state.providerName };
        const params = { urlParams, queryParams };
        api.shared
            .add_provider_to_care_team(params)
            .then((response) => {
                if (
                    this.state.providerType === "BEHAVIORAL" &&
                    this.doesCareTeamHaveProviderType("Intake Behavioral Specialist")
                ) {
                    store.dispatch(
                        addToast({
                            type: toastMessageTypes.success,
                            message: "Behavioral Specialist added. Intake removed automatically.",
                        }),
                    );
                }
                this.setState({
                    showPopUp: false,
                });
                this.getCareTeamMemberList(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    deleteProviderFromCareTeam = async (providerId) => {
        const urlParams = { patientId: this.state.patientId };
        const queryParams = { providerId: providerId };
        const params = { urlParams, queryParams };
        try {
            await api.admin.delete_provider_from_care_team(params);
            this.getCareTeamMemberList(true);
        } catch (e) {
            console.log(e);
        }
    };

    doesCareTeamHaveProviderType = (care_team_provider_type) =>
        this.state.careTeam.some((member) => member.type === care_team_provider_type);

    getProviderTypeOptions = () => {
        if (this.isCurrentUserAdmin) {
            const doesCareTeamHaveBHS = this.doesCareTeamHaveProviderType("Behavioral Specialist");
            return [
                { value: "PCP", display: "PCP" },
                { value: "CARE_MANAGER", display: "Care Manager" },
                { value: "BEHAVIORAL", display: "Behavioral Specialist" },
                {
                    value: "BEHAVIORAL_INTAKE",
                    display: "Intake Behavioral Specialist",
                    disabled: doesCareTeamHaveBHS,
                    toolTip: doesCareTeamHaveBHS
                        ? {
                              text: "Intakes cannot be added while a BHS is on the care team",
                              id: "test",
                          }
                        : undefined,
                },
                { value: "PRESCRIBE", display: "Prescriber" },
                { value: "PEER", display: "Peer" },
                { value: "CASE_MANAGER", display: "Case Manager" },
            ];
        } else if (this.props.profile?.provider_type === "BEHAVIORAL_INTAKE") {
            return [
                { value: "BEHAVIORAL", display: "Behavioral Specialist" },
                { value: "PRESCRIBE", display: "Prescriber" },
            ];
        } else {
            return [{ value: "PRESCRIBE", display: "Prescriber" }];
        }
    };

    setProviderNameOptions = (namesArray) => {
        return namesArray.map((el) => {
            return {
                value: el["username"],
                label: el.name,
                accept_new_patients: el.accept_new_patients,
            };
        });
    };
    providerTypeChangeHandler = (e) => {
        let typeValue = e.target.value;
        let providers = this.state.providers;
        if (providers) {
            providers = providers.filter((provider) => provider["provider_type"] === typeValue);
        }
        this.setState({
            providerType: typeValue,
            providerNameOptions: this.setProviderNameOptions(providers),
            providerTypeOptions: this.getProviderTypeOptions(),
        });
    };
    providerNameChangeHandler = (e) => {
        let nameValue = e.value;
        this.setState({
            providerName: nameValue,
            isdisabled: false,
        });
    };
    renderControls = () => {
        return (
            <div className="d-flex">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
                {!isAuthorized("provider:pcp") &&
                    !isAuthorized(provider_case_manager) &&
                    !isAuthorized(provider_peer) && (
                        <Button
                            onClick={this.showPopup}
                            disabled={isAuthorized(admin_bp)}
                            className="Btn d-none d-lg-block Btn--pri Btn-sm ml-4 fs-16 text-center text-white btn-sm">
                            Add Member
                        </Button>
                    )}
            </div>
        );
    };
    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };
    showPopup = (e) => {
        this.setState({
            showPopUp: true,
            providerName: null,
            isdisabled: true,
        });
    };
    cancelPopup = (e) => {
        this.setState({
            showPopUp: false,
        });
    };

    isScheduleActiveForProviderType(type) {
        return (
            (this.isCurrentUserAdmin &&
                ["Prescriber", "Behavioral Specialist", "Intake Behavioral Specialist"].includes(
                    type,
                )) ||
            (this.isCurrentUserBHS && ["Prescriber", "Behavioral Specialist"].includes(type))
        );
    }

    render() {
        let filteredMembers = this.state.careTeam;

        if (filteredMembers) {
            filteredMembers = this.state.careTeam.filter((team) => {
                let firstName = team.name.split(" ")[0];
                let lastName = team.name.split(" ")[1];
                let filteredpatientsFullName;
                let filteredpatientsFN;
                let filteredpatientsLN;
                let primaryFilter = this.state.search.replace(
                    new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]", "g"),
                    "\\$&",
                );
                filteredpatientsFullName = team.name.match(
                    new RegExp("^" + primaryFilter.toLowerCase(), "i"),
                );

                filteredpatientsFN = firstName.match(
                    new RegExp("^" + primaryFilter.toLowerCase(), "i"),
                );
                if (lastName) {
                    filteredpatientsLN = lastName.match(
                        new RegExp("^" + primaryFilter.toLowerCase(), "i"),
                    );
                }

                return filteredpatientsFullName || filteredpatientsFN || filteredpatientsLN;
            });
        }

        let listItems = (
            <Fragment>
                {filteredMembers &&
                    filteredMembers.map((team) => {
                        return (
                            <ListItem key={team.email}>
                                {/* for mobile devices */}
                                <div className="d-flex d-lg-none justify-content-between">
                                    <div className="w-75 d-flex fs-14 flex-column">
                                        <span>{team.name}</span>
                                        <span>{team.type}</span>
                                        <span>{team.email}</span>
                                    </div>
                                    {(this.isCurrentUserAdmin ||
                                        (this.props.profile?.provider_type ===
                                            "BEHAVIORAL_INTAKE" &&
                                            ["Prescriber", "Behavioral Specialist"].includes(
                                                team.type,
                                            ))) && (
                                        <div className={"d-flex fs-14 flex-column"}>
                                            <span
                                                className="fs-16 fw-bold mobile-item-link"
                                                onClick={() =>
                                                    this.deleteProviderFromCareTeam(
                                                        team.provider_id,
                                                    )
                                                }>
                                                Delete
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {/* for desktop */}
                                <div
                                    className="no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between"
                                    {...getAutomationDataAttr("CareTeamMemberListItem")}>
                                    <span className="col-3">{team.name}</span>
                                    <span className="col-3">{team.type}</span>
                                    <span className="col-4">{team.email}</span>
                                    {this.isScheduleActiveForProviderType(team.type) ? (
                                        <div className="col-1 d-flex d-flex justify-content-center">
                                            <div className="cursor">
                                                <img
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            "/app/schedule/" +
                                                                team.provider_id +
                                                                "?patientId=" +
                                                                this.state.patientId +
                                                                "&providerName=" +
                                                                team.name +
                                                                "&providerType=" +
                                                                team.type,
                                                        )
                                                    }
                                                    {...getAutomationDataAttr(
                                                        `ScheduleCareTeamMember_${team.email}`,
                                                    )}
                                                    src={images("./icons/calender-icon.svg")}
                                                    alt="view icon"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-1" />
                                    )}
                                    {(this.isCurrentUserAdmin ||
                                        (this.props.profile?.provider_type ===
                                            "BEHAVIORAL_INTAKE" &&
                                            ["Prescriber", "Behavioral Specialist"].includes(
                                                team.type,
                                            ))) && (
                                        <div className="col-1 d-flex d-flex justify-content-center">
                                            <div className="cursor">
                                                <img
                                                    onClick={() =>
                                                        this.deleteProviderFromCareTeam(
                                                            team.provider_id,
                                                        )
                                                    }
                                                    {...getAutomationDataAttr(
                                                        "DeleteCareTeamMember",
                                                    )}
                                                    src={images("./icons/delete.svg")}
                                                    alt="view icon"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(!this.isCurrentUserAdmin ||
                                        !this.isCurrentUserBHS ||
                                        (this.isCurrentUserBHS && team.type !== "Prescriber")) && (
                                        <div className="col-1" />
                                    )}
                                </div>
                            </ListItem>
                        );
                    })}
            </Fragment>
        );
        let AddMemberPopup = (
            <Fragment>
                {
                    <div className="addMemberPopup position-fixed d-flex align-items-center justify-content-center">
                        <div className="addMemberPopup__inner d-flex flex-column position-absolute px-4">
                            <h2 className="fs-17 fw-bold mt-4 text-center">Add Member</h2>
                            <span className="fs-15 my-2 fw-bold mt-1 pt-1 d-block ">
                                Select Provider Type
                            </span>
                            <BasicSelect
                                name="provider_type"
                                changeHandler={this.providerTypeChangeHandler}
                                value={this.state.providerType}
                                options={this.state.providerTypeOptions}
                                testId={"AddCareTeamMemberProviderTypeDropdown"}
                            />
                            <span className="fs-15 my-2 fw-bold mt-1 pt-1 d-block ">
                                Select Member
                            </span>
                            {this.state.providerNameOptions && (
                                <Select
                                    components={{ Option }}
                                    name="provider_type"
                                    placeholder="Select Name"
                                    options={this.state.providerNameOptions}
                                    classNamePrefix="select"
                                    onChange={this.providerNameChangeHandler}
                                    {...getAutomationDataAttr(
                                        "AddCareTeamMemberProviderNameDropdown",
                                        "id",
                                    )}
                                />
                            )}
                            {this.state.providerType === "BEHAVIORAL" &&
                                this.doesCareTeamHaveProviderType(
                                    "Intake Behavioral Specialist",
                                ) && (
                                    <div className="Intake-removal-warning">
                                        <span>
                                            Adding a BHS will automatically remove the intake
                                            provider from the care team.
                                        </span>
                                    </div>
                                )}
                            <div className="addMemberPopup__btn-wpr  d-flex justify-content-center my-4">
                                <Button
                                    onClick={this.cancelPopup}
                                    type="button"
                                    className="Btn Btn--otl-pri Btn-sm mx-2">
                                    CANCEL
                                </Button>
                                <Button
                                    type="button"
                                    className="Btn Btn--pri Btn-sm mx-2"
                                    onClick={this.addTeamMember}
                                    disabled={this.state.isdisabled}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-2 p-3 pl-lg-0 pr-lg-0">
                    <span
                        className="survey-back-link"
                        onClick={() =>
                            this.props.history.push(
                                `/app/patient/${this.state.patientId}/profile/v2`,
                            )
                        }>
                        <img
                            className="img-fluid"
                            src={images("./common/solidBckBtn.svg")}
                            alt={"<"}
                        />
                        <p className="d-inline ml-2">Back to Patient Profile</p>
                    </span>
                </div>
                <div className={"d-flex justify-content-center"}>
                    <MemberHeaderHOC
                        className={"cc-member-header mt-5"}
                        memberId={this.state.patientId}
                    />
                </div>
                {this.state.showPopUp && this.state.providerTypeOptions && AddMemberPopup}
                <ListBody>
                    <ListHeader title={"Care Team"} render={this.renderControls} />
                    <ListTitleBar>
                        <TitleBarItem classes="col-3" text="Name" />
                        <TitleBarItem classes="col-3" text="Provider Type" />
                        <TitleBarItem classes="col-4" text="Email Address" />
                        <TitleBarItem classes="col-1" text="" />
                        {this.isCurrentUserAdmin && <TitleBarItem classes="col-1" text="" />}
                    </ListTitleBar>
                    <List>{this.state.careTeam && listItems}</List>
                </ListBody>
            </div>
        );
    }
}

const Option = (props) => {
    const option = props.data;
    const circleType = option.accept_new_patients ? "green" : "red";
    return (
        <components.Option {...props}>
            {option.label} <Circle classes="float-right" type={circleType}></Circle>
        </components.Option>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default withRouter(connect(mapStateToProps)(CareTeamList));
