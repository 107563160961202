// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Styles.scss";
import images from "../../utils/images";
import CardSecondary from "../UI/Card/CardSecondary";
import CardHeading from "../UI/Headings/CardHeading";
import Brand from "../../components/Navigation/Toolbar/Brand";

const PrivacyPolicy = (props) => {
    const { baseRoute } = props;

    return (
        <Fragment>
            {!baseRoute && (
                <header className="Toolbar">
                    <nav className="Toolbar__nav-desktop d-none d-lg-flex px-lg-5 align-items-center">
                        <Brand />
                    </nav>
                    <div className="Tootlbar__nav-center-items d-flex d-lg-none justify-content-center align-items-center my-4">
                        <Brand />
                    </div>
                    <div className="Toolbar__margin-fix d-none d-lg-block"></div>
                </header>
            )}
            <div className="container">
                {baseRoute ? (
                    <div className="PrivacyPolicyTermsandConditions-back-link-wpr mx-auto fs-16 py-4">
                        <Link
                            className="PrivacyPolicyTermsandConditions-back-link"
                            onClick={() => props.history.goBack()}>
                            <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                            <p className="d-inline ml-2">
                                Back{baseRoute && <span> to Settings</span>}
                            </p>
                        </Link>
                    </div>
                ) : (
                    <div className="PrivacyPolicyTermsandConditions-back-link-wpr mx-auto fs-16 pt-0 pb-4 pt-lg-4">
                        <Link
                            className="PrivacyPolicyTermsandConditions-back-link"
                            onClick={() => props.history.goBack()}>
                            <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                            <p className="d-inline ml-2">Back to Patient Consent</p>
                        </Link>
                    </div>
                )}

                <CardSecondary className="PrivacyPolicyTermsandConditions-card mb-md-5 mx-auto">
                    <div className="mx-md-2 p-0 p-md-4 mb-0">
                        <CardHeading text="HIPAA Notice of Privacy Practices" className="fs-18" />
                        <i>Last updated: May 2020</i>
                    </div>
                    <hr className="m-0 d-none d-md-block" />
                    <div className="py-3 px-0 py-md-4 px-md-4">
                        <h2 className="PrivacyPolicyTermsandConditions-heading fs-20">
                            Your Rights
                        </h2>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            When it comes to your health information, you have certain rights. This
                            section explains your rights and some of our responsibilities to help
                            you.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Get an electronic or paper copy of your medical record
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can ask to see or get an electronic or paper copy of your
                                medical record and other health information we have about you. Ask
                                us how to do this.
                            </p>
                            <p className="list-style">
                                We will provide a copy or a summary of your health information,
                                usually within 30 days of your request. We may charge a reasonable,
                                cost-based fee.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Ask us to correct your medical record
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can ask us to correct health information about you that you
                                think is incorrect or incomplete. Ask us how to do this.
                            </p>
                            <p className="list-style">
                                We may say “no” to your request, but we’ll tell you why in writing
                                within 60 days.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Request confidential communications
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can ask us to contact you in a specific way (for example, home
                                or office phone) or to send mail to a different address.
                            </p>
                            <p className="list-style">
                                We will say “yes” to all reasonable requests.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Ask us to limit what we use or share
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can ask us not to use or share certain health information for
                                treatment, payment, or our operations. We are not required to agree
                                to your request, and we may say “no” if it would affect your care.
                            </p>
                            <p className="list-style">
                                If you pay for a service or health care item out-of-pocket in full,
                                you can ask us not to share that information for the purpose of
                                payment or our operations with your health insurer. We will say
                                “yes” unless a law requires us to share that information.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Get a list of those with whom we’ve shared information
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can ask for a list (accounting) of the times we’ve shared your
                                health information for six years prior to the date you ask, who we
                                shared it with, and why.
                            </p>
                            <p className="list-style">
                                We will include all the disclosures except for those about
                                treatment, payment, and health care operations, and certain other
                                disclosures (such as any you asked us to make). We’ll provide one
                                accounting a year for free but will charge a reasonable, cost-based
                                fee if you ask for another one within 12 months.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Get a copy of this privacy notice
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-3">
                            You can ask for a paper copy of this notice at any time, even if you
                            have agreed to receive the notice electronically. We will provide you
                            with a paper copy promptly.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Choose someone to act for you
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                If you have given someone medical power of attorney or if someone is
                                your legal guardian, that person can exercise your rights and make
                                choices about your health information.
                            </p>
                            <p className="list-style">
                                We will make sure the person has this authority and can act for you
                                before we take any action.
                            </p>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            File a complaint if you feel your rights are violated
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            <p className="list-style">
                                You can complain if you feel we have violated your rights by
                                contacting us:
                                <br />
                                &nbsp; Attn: Privacy & Compliance Officer
                                <br />
                                &nbsp; aptihealth, inc.
                                <br />
                                &nbsp; 1785 U.S. Route 9
                                <br />
                                &nbsp; Clifton Park, NY, 12065
                                <br />
                                &nbsp; (888) 454-3827
                                <br />
                                &nbsp;{" "}
                                <a
                                    className="PrivacyPolicyTermsandConditions-support-link"
                                    href="mailto: support@aptihealth.com?subject=Web Support Request">
                                    support@aptihealth.com
                                </a>
                            </p>
                            <p className="list-style">
                                You can file a complaint with the U.S. Department of Health and
                                Human Services Office for Civil Rights by sending a letter to 200
                                Independence Avenue, S.W., Washington, D.C. 20201, calling
                                1-877-696-6775, or visiting{" "}
                                <a
                                    className="PrivacyPolicyTermsandConditions-support-link"
                                    href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/">
                                    www.hhs.gov/ocr/privacy/hipaa/complaints/
                                </a>
                                .
                            </p>
                            <p className="list-style">
                                We will not retaliate against you for filing a complaint.
                            </p>
                        </p>
                        <h2 className="PrivacyPolicyTermsandConditions-heading fs-20">
                            Your Choices
                        </h2>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            <span className="fw-bold">
                                For certain health information, you can tell us your choices about
                                what we share.
                            </span>
                            <br />
                            If you have a clear preference for how we share your information in the
                            situations described below, talk to us. Tell us what you want us to do,
                            and we will follow your instructions.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            In these cases, you have both the right and choice to tell us to:
                        </p>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3 mb-3">
                            <li className="list-style">
                                Share information with your family, close friends, or others
                                involved in your care
                            </li>
                            <li className="list-style">
                                Share information in a disaster relief situation
                            </li>
                            <li className="list-style">
                                Include your information in a hospital directory
                            </li>
                        </ul>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            <i>
                                If you are not able to tell us your preference, for example if you
                                are unconscious, we may go ahead and share your information if we
                                believe it is in your best interest. We may also share your
                                information when needed to lessen a serious and imminent threat to
                                health or safety.
                            </i>
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            In these cases, we never share your information unless you give us
                            written permission:
                        </p>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3 mb-3">
                            <li className="list-style">Marketing purposes</li>
                            <li className="list-style">Sale of your information</li>
                            <li className="list-style">
                                Psychotherapy notes, unless otherwise required by law
                            </li>
                        </ul>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            In the case of fundraising:
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text ml-0 mb-0">
                            We may contact you for fundraising efforts, but you can "opt out" or
                            "unsubscribe" and we will not contact you again with this type of
                            communication.
                        </p>

                        <h2 className="PrivacyPolicyTermsandConditions-heading fs-20">
                            Our Uses and Disclosures
                        </h2>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            How do we typically use or share your health information?{" "}
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We typically use or share your health information in the following ways.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Treat you
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            We can use your health information and share it with other professionals
                            who are treating you.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            Example: A doctor treating you for a health condition asks us about your
                            behavioral health conditions that may affect treatment.
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Run our organization
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We can use and share your health information to run our practice,
                            improve your care, and contact you when necessary. <br /> Example: We
                            use health information about you to manage your treatment and services.{" "}
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Bill for your services
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            We can use and share your health information to bill and get payment
                            from health plans or other entities.{" "}
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            Example: We give information about you to your health insurance plan so
                            it will pay for your services.{" "}
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            How else can we use or share your health information?
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            We are allowed or required to share your information in other ways –
                            usually in ways that contribute to the public good, such as public
                            health and research. We must meet many conditions in the law before we
                            can share your information for these purposes.
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            For more information see:
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            <a
                                className="PrivacyPolicyTermsandConditions-support-link"
                                href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/">
                                www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html
                            </a>
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Help with public health and safety issues
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            We can share health information about you for certain situations such
                            as:{" "}
                        </p>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3 mb-3">
                            <li className="list-style">Preventing disease</li>
                            <li className="list-style">Helping with product recalls</li>
                            <li className="list-style">
                                Reporting adverse reactions to medications
                            </li>
                            <li className="list-style">
                                Reporting suspected child abuse or neglect, and under certain
                                circumstances, abuse, neglect or domestic violence involving adults
                            </li>
                            <li className="list-style">
                                Preventing or reducing a serious threat to anyone’s health or safety
                            </li>
                        </ul>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Do research
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We can use your information for health research, in aggregate (your
                            personal information will not be separately identifiable).
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Comply with the law
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We will share information about you if state or federal laws require it,
                            including with the Department of Health and Human Services if it wants
                            to see that we’re complying with federal privacy law.
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Work with a medical examiner or funeral director
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We can share health information with a coroner, medical examiner, or
                            funeral director when an individual dies.
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Address workers’ compensation, law enforcement, and other government
                            requests
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            We can use or share health information about you:
                        </p>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3 mb-3">
                            <li className="list-style">For workers’ compensation claims</li>
                            <li className="list-style">
                                For law enforcement purposes or with a law enforcement official
                            </li>
                            <li className="list-style">
                                With health oversight agencies for activities authorized by law
                            </li>
                            <li className="list-style">
                                For special government functions such as military, national
                                security, and presidential protective services
                            </li>
                        </ul>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Respond to lawsuits and legal actions
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We can share health information about you in response to a court or
                            administrative order, or in response to a subpoena.
                        </p>

                        <h2 className="PrivacyPolicyTermsandConditions-heading ">
                            Our Responsibilities
                        </h2>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3">
                            <li className="list-style">
                                We are required by law to maintain the privacy and security of your
                                protected health information.{" "}
                            </li>
                            <li className="list-style">
                                We will let you know promptly if a breach occurs that may have
                                compromised the privacy or security of your information.
                            </li>
                            <li className="list-style">
                                We must follow the duties and privacy practices described in this
                                Notice and give you a copy of it.
                            </li>
                            <li className="list-style">
                                We will not use or share your protected health information other
                                than as described here unless you tell us we can in writing. If you
                                tell us we can, you may change your mind at any time. Let us know in
                                writing if you change your mind.
                            </li>
                        </ul>
                        <p className="PrivacyPolicyTermsandConditions-text mb-0">
                            For more information see:
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            <a
                                className="PrivacyPolicyTermsandConditions-support-link"
                                href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html">
                                www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html.
                            </a>
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Changes to the Terms of this Notice
                        </p>
                        <p className="PrivacyPolicyTermsandConditions-text">
                            We can change the terms of this notice, and the changes will apply to
                            all information we have about you. The new notice will be available upon
                            request, in our office, and within this Application.
                        </p>

                        <p className="PrivacyPolicyTermsandConditions-text fw-bold mb-0">
                            Other Instructions for Notice
                        </p>
                        <ul className="PrivacyPolicyTermsandConditions-text ml-3 mb-3">
                            <li className="list-style">
                                The effective date of this Notice is 07/15/2019
                            </li>
                            <li className="list-style">
                                For questions about this Notice, please contact:
                                <br />
                                &nbsp; Attn: Privacy & Compliance Officer
                                <br />
                                &nbsp; aptihealth, inc.
                                <br />
                                &nbsp; 1785 U.S. Route 9
                                <br />
                                &nbsp; Clifton Park, NY, 12065
                                <br />
                                &nbsp; (888) 454-3827
                                <br />
                                &nbsp;{" "}
                                <a
                                    className="PrivacyPolicyTermsandConditions-support-link"
                                    href="mailto: support@aptihealth.com?subject=Web Support Request">
                                    support@aptihealth.com
                                </a>
                            </li>
                            <li className="list-style">
                                aptihealth is conducting a demonstration study, referred to as
                                Vanguard Behavioral Solutions (VBS, or "Study"), to examine the
                                effectiveness of behavioral health integration with primary care
                                practices. aptihealth introduces technology that offers PCPs,
                                patients, and behavioral specialists a new collaborative approach to
                                care.{" "}
                            </li>
                            <li className="list-style">
                                As part of this Study, behavioral health care providers have come
                                together as an OHCA (organized health care arrangement), which is a
                                clinically integrated group in which patients receive health care
                                from more than one health care provider. aptihealth provides certain
                                services to providers within the OHCA and is a member of the OHCA.{" "}
                            </li>
                            <li className="list-style">
                                An OHCA allows legally separate covered entities and their business
                                associates to use and disclose protected health information for the
                                joint operations of the arrangement. This HIPAA Notice of Privacy
                                Practices applies to any licensed professional or practice utilizing
                                the aptihealth engagement platform, which are members of an
                                organized health care arrangement referred throughout this Notice as
                                "OHCA members" and "we".{" "}
                            </li>
                            <li className="list-style">
                                OHCA Members are required by law to maintain the privacy of
                                protected health information, give you notice of our legal duties
                                and privacy practices requiring health information about you, and
                                follow the terms of our Notice that is currently in effect.
                            </li>
                        </ul>
                    </div>
                </CardSecondary>
            </div>
        </Fragment>
    );
};
export default withRouter(PrivacyPolicy);
