// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    fetchEventTypeOptions,
    fetchPatient,
    fetchPatients,
    initializeCalendar,
    initializeOnBehalfOfProviderPatient,
    initializeProviderAsAdmin,
    initializeProviderAsSelf,
    refetchSchedule,
    updateSelectedDate,
} from "../../../redux/actions/calendar";
import Calendar from "../../../components/Calendar/Calendar";
import {
    timeSlotArray,
    transformTimeSlotArray,
} from "../../../components/Calendar/CalendarAppointmentPicker/calendarAppointmentPickerUtils";
import { isUserAdmin, isUserPeerOrCM } from "../../../redux/actions/auth";
import moment from "moment/moment";
import { filterCalendarEvents } from "../../../utils/calendar/util";
import { getQueryParams } from "../../../utils/filters";

export const SchedulePageV2 = (props) => {
    const {
        events,
        provider,
        isInitialized,
        selectedScheduleDates,
        unconfirmedEvent,
        viewType,
        calendarFilters,
    } = props.calendar;
    const providerId = props.match.params.providerId ? props.match.params.providerId : null;
    const patientId = getQueryParams("patientId", props.location.search);

    useEffect(() => {
        if (isUserAdmin() && providerId) {
            props.initializeProviderAsAdmin(providerId);
        } else if (isUserPeerOrCM() && providerId && patientId) {
            props.initializeOnBehalfOfProviderPatient(providerId, patientId);
        } else if (!isUserPeerOrCM() && props.profile) {
            props.initializeProviderAsSelf(props.profile);
        }
    }, [props.profile, providerId]);

    useEffect(() => {
        if (isUserPeerOrCM() && patientId) {
            const patient = props.calendar?.patientMap[patientId] || { patient_id: patientId };
            props.fetchEventTypeOptions(provider, patient);
        } else {
            props.fetchEventTypeOptions(provider);
            props.fetchPatients({ queryParams: { providerId: providerId } });
        }
    }, [provider?.providerId, provider?.providerType]);

    useEffect(() => {
        if (!isInitialized) {
            props.initializeCalendar();
            // ensures that todays date is initially selected
            // also triggers side effects like getting the current schedule
            props.updateSelectedDate(moment().local());
        } else {
            props.refetchSchedule();
        }
    }, []);

    const filteredEvents = filterCalendarEvents(events, calendarFilters);

    return (
        <section className="SchedulePage pt-3 pt-lg-5">
            <div style={{ "max-width": 1440, margin: "auto" }}>
                <Calendar
                    workingHours={props.calendar.provider.localAvailability}
                    patientDropdownOptions={props.calendar.patientDropdownOptions}
                    timeSlots={transformTimeSlotArray(timeSlotArray)}
                    schedule={filteredEvents}
                    unconfirmedEvent={unconfirmedEvent}
                    daysToDisplay={selectedScheduleDates}
                />
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    fetchPatients,
    fetchPatient,
    fetchEventTypeOptions,
    initializeCalendar,
    updateSelectedDate,
    refetchSchedule,
    initializeProviderAsSelf,
    initializeProviderAsAdmin,
    initializeOnBehalfOfProviderPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePageV2);
