import { Form } from "formik";
import { isAuthorized } from "../../../redux/actions/auth";
import { admin_cse } from "../../../config/roles";
import _ from "lodash";
import { toSentenceCase } from "../../../utils/filters";
import Button from "../../UI/Button";
import React, { useState } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import { api } from "../../../APIRequests";
import { CustomForm } from "../../../component-library/CustomForm";
import { useProviderInactivationOptions } from "../../../utils/hooks/useProviderInactivationOptions";
import {
    PROVIDER_STATUS_OPTIONS,
    PROVIDER_STATUS_DETAILS_SCHEMA,
    PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID,
    PROVIDER_INACTIVATION_REASON_OTHER_TEXT_CHARACTER_LIMIT,
} from "./ProviderStatusDetailsSchema";
import { FormSelect } from "../../../component-library/FormFields/FormSelect/FormSelect";
import { FormTextInput } from "../../../component-library/FormFields/FormTextInput/FormTextInput";
import { CharacterCounter } from "../../../component-library/CharacterCounter/CharacterCounter";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader";
import { localTimestampFromStandardTimestamp } from "../../../utils/momentUtils";

export const ProviderStatusDetails = ({ refetchProvider, providerDetails }) => {
    const [editProviderStatus, setEditProviderStatus] = useState(false);
    const { selectableOptions: providerInactivationSelectableOptions } =
        useProviderInactivationOptions();
    const dispatch = useDispatch();

    const onSubmit = async (formData) => {
        const data = {
            provider_id: _.get(providerDetails, "username"),
            status: formData.status,
        };

        if (formData.status == "INACTIVE") {
            data.inactivation_reason_text_id = formData.inactivation_reason_text_id;
        }
        if (
            formData.status == "INACTIVE" &&
            formData.inactivation_reason_text_id === PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID
        ) {
            data.inactivation_reason_other_text = formData.inactivation_reason_other_text;
        }

        dispatch(showLoader(true));
        await api.admin.change_provider_status({
            data,
            options: { params: { showLoader: false } },
        });
        await refetchProvider({
            options: { params: { showLoader: false } },
        });
        dispatch(hideLoader());

        setEditProviderStatus(false);
    };

    const renderStatusForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="ProviderStatusDetails--form_container">
                        <FormSelect
                            title="Provider Status"
                            name="status"
                            options={PROVIDER_STATUS_OPTIONS}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            onChangeCallBack={async (_name, value) => {
                                if (
                                    // if the value we are on is active
                                    // skip the callback
                                    formikProps.values.status === "ACTIVE" ||
                                    // if the value we are on is inactive
                                    // and the new value is not active
                                    // skip the callback
                                    value !== "ACTIVE"
                                ) {
                                    return;
                                }

                                // reset fields
                                await formikProps.setFieldValue("inactivation_reason_text_id", "");
                                await formikProps.setFieldValue(
                                    "inactivation_reason_other_text",
                                    "",
                                );
                            }}
                        />

                        {formikProps.values.status === "INACTIVE" && (
                            <>
                                <FormSelect
                                    title="Inactivation Reason"
                                    name="inactivation_reason_text_id"
                                    options={providerInactivationSelectableOptions}
                                    formikProps={formikProps}
                                    onChangeCallBack={async (_name, value) => {
                                        if (
                                            // if the value we are on is not the other text
                                            // skip the callback
                                            formikProps.values.inactivation_reason_text_id !==
                                                PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID ||
                                            // if the value we are on is the other text
                                            // and the new value is the other text
                                            // skip the callback
                                            value === PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID
                                        ) {
                                            return;
                                        }

                                        // reset other text
                                        await formikProps.setFieldValue(
                                            "inactivation_reason_other_text",
                                            "",
                                        );
                                    }}
                                    triggerErrorOnChange
                                />

                                {formikProps.values?.inactivation_reason_text_id ==
                                    PROVIDER_INACTIVATION_REASON_OTHER_TEXT_ID && (
                                    <div className="ProviderStatusDetails--inactivation_reason_other_text_container">
                                        <FormTextInput
                                            title="Other (Specify...)"
                                            name="inactivation_reason_other_text"
                                            formikProps={formikProps}
                                            triggerErrorOnChange
                                        />
                                        <CharacterCounter
                                            className="ProviderStatusDetails--inactivation_reason_other_character_counter"
                                            length={
                                                formikProps.values.inactivation_reason_other_text
                                                    .length
                                            }
                                            characterLimit={
                                                PROVIDER_INACTIVATION_REASON_OTHER_TEXT_CHARACTER_LIMIT
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button
                        type="button"
                        // clears the formik state by unmounting it
                        onClick={() => setEditProviderStatus(!editProviderStatus)}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        // disable the submit button if all visible errors are not resolved
                        disabled={Boolean(
                            new Set(Object.keys(formikProps.errors)).intersection(
                                new Set(Object.keys(formikProps.touched)),
                            ).size,
                        )}
                        className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    if (!providerDetails) {
        return <></>;
    }

    return (
        <div data-public={"true"}>
            <CardSecondary className="mx-auto container Practice__Details p-2">
                <div className="d-flex justify-content-between pt-4">
                    <CardHeading
                        className="Heading Heading--card fw-bold fs-18 m-0 px-3 px-lg-4"
                        text="Status"
                    />
                    {isAuthorized(admin_cse) && (
                        <Button
                            onClick={() => setEditProviderStatus(!editProviderStatus)}
                            className="ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3">
                            Edit
                        </Button>
                    )}
                </div>
                {isAuthorized(admin_cse) && <div className={"ml-4 mt-2 mb-4"}></div>}
                <hr className="m-0 d-none d-md-block" />
                <div className="no-gutters mt-4 mb-3 col-12 px-3 px-lg-4">
                    <p className={"fs-14 ProviderStatusDetails--note_text"}>
                        Setting a provider's status to inactive will sign the provider out from all
                        devices, remove working hours and payers from their account, and cancel all
                        appointments.
                    </p>
                </div>
                {!editProviderStatus && (
                    <div className="no-gutters my-4 col-12 px-3 px-lg-4 ProviderStatusDetails--read_only_container">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Provider Status" className="fs-14 mx-0" />
                            <p className="fs-14 mx-0">{toSentenceCase(providerDetails.status)}</p>
                        </div>
                        {providerDetails.status === "INACTIVE" && (
                            <div>
                                <CardHeading
                                    text="Provider Inactivation Reason"
                                    className="fs-14 mx-0"
                                />
                                <p className="fs-14 mx-0 text-break">
                                    {providerDetails.inactivation_reason_text}
                                </p>
                            </div>
                        )}
                        {providerDetails.status_updated_by_name &&
                            providerDetails.status_update_timestamp && (
                                <div className="ProviderStatusDetails--inactivation_meta_data_container">
                                    <p className="ProviderStatusDetails--inactivation_meta_data_text">
                                        Last Updated by {providerDetails.status_updated_by_name}
                                    </p>
                                    <p className="ProviderStatusDetails--inactivation_meta_data_text">
                                        {localTimestampFromStandardTimestamp(
                                            providerDetails.status_update_timestamp,
                                            "M/D/YYYY",
                                        )}
                                    </p>
                                </div>
                            )}
                    </div>
                )}
                {editProviderStatus && (
                    <div className="no-gutters my-4 col-12 px-3 px-lg-4">
                        <CustomForm
                            initialValues={{
                                status: providerDetails.status ?? "",
                                inactivation_reason_text_id:
                                    providerDetails.inactivation_reason_text_id ?? "",
                                inactivation_reason_other_text:
                                    providerDetails.inactivation_reason_other_text ?? "",
                            }}
                            validationSchema={PROVIDER_STATUS_DETAILS_SCHEMA}
                            onSubmit={onSubmit}
                            render={renderStatusForm}
                        />
                    </div>
                )}
            </CardSecondary>
        </div>
    );
};
