// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import Label from "../../../components/UI/Label";
import { Select, TextArea } from "../../../components/UI/StyledInput";
import DatePicker from "../../../components/UI/DatePicker";
import Button from "../../../components/UI/Button";
import moment from "moment";
import { connect } from "react-redux";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    allottedTimeFactory,
    AWAY,
    CRISIS_EVAL_90839,
    CRISIS_EVAL_EXTENDED_90840,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    FAMILY_THERAPY_WO_PATIENT_90846,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME,
    timeSlotArray,
} from "./constants";
import { isAuthorized, isUserAdmin } from "../../../redux/actions/auth";
import { api } from "../../../APIRequests";
import { withRouter } from "react-router-dom";
import { providerRoute } from "../../../config/roles";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { eventTypeDisplayNameFactory } from "./ActiveScheduleDetails";
import ReoccurrenceDetails, { transformFrequencyDaysForServer } from "./ReoccurrenceDetails";
import _ from "lodash";

class ScheduleEvent extends Component {
    DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS = [
        {
            value: DIAGNOSTIC_INTERVIEW_90792,
            display: `Diagnostic Interview (90792) (${allottedTimeFactory(
                DIAGNOSTIC_INTERVIEW_90792,
            )} minutes)`,
        },
        {
            value: MEDICATION_MGMT_99212,
            display: `Medication Management (99212) (${allottedTimeFactory(
                MEDICATION_MGMT_99212,
            )} minutes)`,
        },
        {
            value: MEDICATION_MGMT_99213,
            display: `Medication Management (99213) (${allottedTimeFactory(
                MEDICATION_MGMT_99213,
            )} minutes)`,
        },
        {
            value: MEDICATION_MGMT_99214,
            display: `Medication Management (99214) (${allottedTimeFactory(
                MEDICATION_MGMT_99214,
            )} minutes)`,
        },
        {
            value: CRISIS_EVAL_90839,
            display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                CRISIS_EVAL_90839,
            )} minutes)`,
        },
        {
            value: ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
            display: `${eventTypeDisplayNameFactory(
                ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
            )} (${allottedTimeFactory(
                ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
            )} minutes)`,
        },
    ];

    DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS = [
        {
            value: DIAGNOSTIC_INTERVIEW_90791,
            display: `Diagnostic Interview (90791) (${allottedTimeFactory(
                DIAGNOSTIC_INTERVIEW_90791,
            )} minutes)`,
        },
        {
            value: _30_MIN_INDIVIDUAL_90832,
            display: `30 Minute Individual (90832) (${allottedTimeFactory(
                _30_MIN_INDIVIDUAL_90832,
            )} minutes)`,
        },
        {
            value: _45_MIN_INDIVIDUAL_90834,
            display: `45 Minute Individual (90834) (${allottedTimeFactory(
                _45_MIN_INDIVIDUAL_90834,
            )} minutes)`,
        },
        {
            value: _60_MIN_INDIVIDUAL_90837,
            display: `60 Minute Individual (90837) (${allottedTimeFactory(
                _60_MIN_INDIVIDUAL_90837,
            )} minutes)`,
        },
        {
            value: FAMILY_THERAPY_90847,
            display: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
                FAMILY_THERAPY_90847,
            )} minutes)`,
        },
        {
            value: FAMILY_THERAPY_WO_PATIENT_90846,
            display: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
                FAMILY_THERAPY_WO_PATIENT_90846,
            )} minutes)`,
        },
        {
            value: CRISIS_EVAL_90839,
            display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                CRISIS_EVAL_90839,
            )} minutes)`,
        },
        {
            value: CRISIS_EVAL_EXTENDED_90840,
            display: `Crisis Evaluation Extended (90840) (${allottedTimeFactory(
                CRISIS_EVAL_EXTENDED_90840,
            )} minutes)`,
        },
        {
            value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
            display: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
                OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
            )} minutes)`,
        },
        {
            value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
            display: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
                OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
            )} minutes)`,
        },
    ];

    getAllottedTime(scheduleType, isChild) {
        if (scheduleType === DIAGNOSTIC_INTERVIEW_90791 && isChild) {
            return TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME;
        }
        return allottedTimeFactory(scheduleType);
    }

    getBehavioralScheduleOptions = () => {
        if (this.state && this.state.isChild) {
            const options = _.cloneDeep(this.DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS);
            const index = options.findIndex((obj) => obj.value === DIAGNOSTIC_INTERVIEW_90791);
            options[
                index
            ].display = `Diagnostic Interview (90791) (${TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME} minutes)`;
            return options;
        }
        return this.DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS;
    };

    getAdminScheduleOptions = () => {
        let options = [];
        if (this.state.providerType === "PRESCRIBE") {
            options = this.DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS;
        } else {
            options = this.getBehavioralScheduleOptions();
        }
        return options.filter((option) => option.value !== AWAY);
    };

    getDefaultScheduleTypeOptions() {
        const isUserBehavioral = isAuthorized("provider:behavioral");
        const isUserPrescriber = isAuthorized("provider:prescribe");

        if (isUserPrescriber) {
            return this.DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS;
        } else if (isUserBehavioral) {
            return this.getBehavioralScheduleOptions();
        } else if (isUserAdmin()) {
            return this.getAdminScheduleOptions();
        }
        return [{ value: AWAY, display: "Away" }];
    }

    constructor(props) {
        super(props);
        const isUserBehavioral = isAuthorized("provider:behavioral");
        const isUserPrescriber = isAuthorized("provider:prescribe");
        this.state = {
            scheduleType: isUserBehavioral
                ? _30_MIN_INDIVIDUAL_90832
                : isUserPrescriber
                ? DIAGNOSTIC_INTERVIEW_90792
                : AWAY,
            datePickerConf: null,
            showFromDatePicker: false,
            showToDatePicker: false,
            showRepeatDatePicker: false,
            start_date: props.selectedDate,
            end_date: props.selectedDate,
            timeSlotStart: props.startTimeSlot,
            timeSlotEnd: props.endTimeSlot,
            existingConflicts: props.existingConflicts,
            repeatCriteria: "weekly",
            repeat_date: moment.utc().local(),
            startTimeSlotOptions: transformTimeSlotArray(timeSlotArray),
            endTimeSlotOptions: this.trimEndTimeSlotOptions("09:00"),
            isRepeatChecked: false,
            isVideoAuthorized: isUserBehavioral || isUserPrescriber || isUserAdmin(),
            allotted_time: "30",
            error: null,
            scheduleTypeOptions: [],
            awayDurations: [
                { value: "15", label: "15 min", active: false },
                { value: "30", label: "30 min", active: true },
                { value: "60", label: "1 hour", active: false },
            ],
            searchText: this.props.callDetails ? this.props.callDetails.patient_name : "",
            seriesData: {
                frequencyDays: [this.getTargetedDay(this.props.selectedDate)],
                frequencyWeeks: 0,
                isReoccurring: false,
                numberOfOccurrences: 1,
            },
            isChild: false,
            providerId: this.props.match?.params?.providerId,
        };
        this.state["scheduleType"] = props.details
            ? props.details.eventType
            : this.state["scheduleType"];
    }

    repeatCriteriaOptions = [
        { value: "weekly", display: "Weekly" },
        { value: "monthly", display: "Monthly" },
    ];

    getTargetedDay(date) {
        return date ? moment(date, "YYYY-MM-DD").day() : moment().day();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedDate.format("YYYY-MM-DD") !==
            this.props.selectedDate.format("YYYY-MM-DD")
        ) {
            let newState = {};
            newState["startTimeSlotOptions"] = transformTimeSlotArray(timeSlotArray).filter(
                (option) => this.filterTimeOptionsWithConflicts(option, this.state.scheduleType),
            );
            this.setState(newState);
        }

        if (this.props.newFormat) {
            if (prevProps.existingConflicts !== this.props.existingConflicts) {
                let newState = {};
                newState["startTimeSlotOptions"] = transformTimeSlotArray(timeSlotArray).filter(
                    (option) =>
                        this.filterTimeOptionsWithConflicts(option, this.state.scheduleType),
                );

                this.setState(newState);
            }
        } else {
            if (prevState.existingConflicts !== this.state.existingConflicts) {
                let newState = {};
                newState["startTimeSlotOptions"] = transformTimeSlotArray(timeSlotArray).filter(
                    (option) =>
                        this.filterTimeOptionsWithConflicts(option, this.state.scheduleType),
                );

                this.setState(newState);
            }
        }
        if (
            prevState.isChild !== this.state.isChild ||
            prevState.scheduleType !== this.state.scheduleType
        ) {
            this.setState({
                allotted_time: this.getAllottedTime(this.state.scheduleType, this.state.isChild),
            });
        }

        if (
            prevState.timeSlotStart !== this.props.startTimeSlot ||
            prevState.timeSlotEnd !== this.props.endTimeSlot
        ) {
            this.setState({
                timeSlotStart: this.props.startTimeSlot,
                timeSlotEnd: this.props.endTimeSlot,
            });
        }
    }

    componentDidMount = async () => {
        let newState = {};
        if (this.state.isVideoAuthorized) {
            newState["patientList"] = await this.fetchPatients();
            newState["selectedGuest"] = this.props.patientId ? this.props.patientId : "";
        }
        newState["startTimeSlotOptions"] = [...this.state.startTimeSlotOptions].filter((option) =>
            this.filterTimeOptionsWithConflicts(option, this.state.scheduleType),
        );

        if (isUserAdmin()) {
            const queryParams = { providerId: this.state.providerId };
            const provider = await api.provider.fetch_provider_profile({ queryParams });

            newState.providerType = provider?.user?.provider_type;

            if (newState.providerType === "PRESCRIBE") {
                newState.scheduleType = DIAGNOSTIC_INTERVIEW_90792;
            } else {
                newState.scheduleType = _30_MIN_INDIVIDUAL_90832;
            }
        }

        this.setState(newState);
    };

    filterTimeOptionsWithConflicts = (option, scheduleType) =>
        !this.padConflictsWithAppointmentAllottedTime(scheduleType).flat().includes(option.value);

    padConflictsWithAppointmentAllottedTime(scheduleType) {
        return this.props.existingConflicts.map((conflict) => {
            const startingTime = moment(conflict[0], "HH:mm");
            const allottedTime = this.getAllottedTime(scheduleType, this.state.isChild);
            let startingTimePadding = moment(conflict[0], "HH:mm").subtract(
                allottedTime - 15,
                "minutes",
            );
            while (startingTimePadding < startingTime) {
                conflict.push(startingTimePadding.format("HH:mm"));
                startingTimePadding.add(15, "minutes");
            }
            return conflict;
        });
    }

    fetchPatients = async () => {
        let patientList = [];
        if (this.state.patientList && !this.state.searchText) {
            patientList = this.state.patientList;
        } else if (this.state.patientList && this.state.searchText) {
            patientList = this.state.patientList.filter((option) => {
                const nameParts = option.label.split(" ");
                let firstName = nameParts[0].toLowerCase();
                let lastName = nameParts.length > 1 ? nameParts[1].toLowerCase() : "";
                let fullName = `${firstName} ${lastName}`;
                let primaryFilter = this.state.searchText.replace(
                    new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]", "g"),
                    "\\$&",
                );
                return (
                    fullName.match(new RegExp("^" + primaryFilter.toLowerCase())) ||
                    firstName.match(new RegExp("^" + primaryFilter.toLowerCase())) ||
                    lastName.match(new RegExp("^" + primaryFilter.toLowerCase()))
                );
            });
        } else {
            const queryParams = {};
            if (isUserAdmin()) {
                queryParams.providerId = this.state.providerId;
            }

            let response = await api.provider.fetch_patient_list({ queryParams });
            if (response && response.user) {
                patientList = response.user
                    .filter((patient) => patient.patient_status !== "INACTIVE")
                    .map((patient) => {
                        return {
                            value: patient.patient_id,
                            label: patient.first_name + " " + patient.last_name,
                            group: "Patient",
                        };
                    });
            }

            this.setState({ patientList: patientList });
        }
        return patientList;
    };

    updateSeriesData = (seriesData) => {
        this.setState({ seriesData });
        if (seriesData && seriesData.frequencyDays && seriesData.frequencyDays.length > 0) {
            const firstSelectedDay = Array.from(seriesData.frequencyDays)[0];
            const selectedDate = moment(this.props.selectedDate);
            let newDate;
            if (selectedDate.isAfter(moment(), "week")) {
                newDate = selectedDate.day(firstSelectedDay);
            } else {
                newDate = moment().day(firstSelectedDay);
            }
            this.setState({ start_date: newDate, end_date: newDate });
            this.props.selectedDateHandler(newDate);
        }
    };

    /**
     * Updates the end time slot options in state.
     * Once the start time slot is selected, then he slots prior to this slot
     * must be dropped from end time slot options.
     *
     * @param {string} slotValue Value of the time slot in string. Eg '09:00'
     * @returns {Array.<{ value: string, display: string}>} returns the trimed and transformed array of objects of form `{ value:'09:00', display:'09:00'}`
     */
    trimEndTimeSlotOptions = (slotValue) => {
        let index = timeSlotArray.findIndex((el) => el === slotValue);
        let trimmedSlotArray = timeSlotArray.slice(index);
        return transformTimeSlotArray(trimmedSlotArray);
    };

    scheduleTypeChangeHandler = (e) => {
        const scheduleType = e.target.value;
        const startTimeSlotOptions = transformTimeSlotArray(timeSlotArray).filter((option) =>
            this.filterTimeOptionsWithConflicts(option, e.target.value),
        );
        const startTimesFilterCheck = startTimeSlotOptions.map((option) => option.value);
        this.setState({
            scheduleType,
            startTimeSlotOptions,
            timeSlotStart: startTimesFilterCheck.includes(this.state.timeSlotStart)
                ? this.state.timeSlotStart
                : null,
        });
    };

    datePickerHandler = (type) => {
        this.props.setDatePickerConf(type);
    };

    setStartDate = (dateString) => {
        const startDate = moment.utc(dateString, "YYYY-MM-DD");

        if (this.props.setSelectedDate) {
            this.props.setSelectedDate(startDate);
        }

        const seriesData = _.cloneDeep(this.state.seriesData);
        seriesData["frequencyDays"] = [this.getTargetedDay(dateString)];

        this.setState({
            start_date: startDate,
            seriesData,
        });

        this.props.selectedDateHandler(startDate);

        this.props.getSpecificDateSchedule(dateString);
        this.props.setDatePickerConf(null);
    };

    setEndDate = (dateString) => {
        this.setState({
            end_date: moment.utc(dateString, "YYYY-MM-DD"),
        });

        if (this.props.setSelectedDate) {
            this.props.setSelectedDate(moment.utc(dateString, "YYYY-MM-DD"));
        }

        this.props.getSpecificDateSchedule(dateString);
        this.props.setDatePickerConf(null);
    };

    onDateSelect = (dateString) => {
        if (this.props.datePickerConf) {
            switch (this.props.datePickerConf) {
                case "from":
                    this.setStartDate(dateString);
                    break;
                case "to":
                    this.setEndDate(dateString);
                    break;
                default:
                    return;
            }
        }
    };
    dismissHandler = (e) => {
        if (
            e.target.className === "ScheduleEvent__DatePicker-wpr" ||
            e.target.className !== "PrScheduleAvailability__DatePicker-wpr"
        ) {
            this.props.setDatePickerConf(null);
        }
    };

    setStartTimeSlot = (e) => {
        let target = e.target;
        let value = target.value;
        this.props.updateStartTimeSlot(value);
        this.setState({
            timeSlotStart: value,
            timeSlotEnd:
                value /** updating the timeSlotEnd also to show slot equal to `timeSlotStart` */,
            endTimeSlotOptions:
                this.trimEndTimeSlotOptions(value) /** updating the end time slots options */,
        });
    };

    /**
     * Add new slot handler which performs some tasks prior final api call handler
     */
    createNewSchedule = () => {
        let type = this.state.scheduleType;

        if (!this.state.timeSlotStart) {
            this.setState({ error: "Time is required" });
            return;
        } else if (
            this.state.seriesData &&
            this.state.seriesData.isReoccurring &&
            (this.state.seriesData.frequencyDays.length === 0 ||
                this.state.seriesData.frequencyWeeks === null)
        ) {
            this.setState({
                error: "Reoccurrence details required",
            });
            return;
        } else if (type !== AWAY && !this.state.selectedGuest) {
            this.setState({
                error: "Patient is required",
            });
            return;
        } else {
            this.setState({
                error: null,
            });
        }

        if (type === AWAY) {
            this.createAwaySchedule();
        } else {
            this.createVideoConsultationSchedule();
        }
    };

    /**
     * Add update away slot handler
     */
    updateAwaySchedule = () => {
        /** Local date and time */
        let LocalDateandTimeCombined =
            this.state.start_date.format("YYYY-MM-DD") + " " + this.props.startTimeSlot;
        /** UTC date and time */
        let UTCDateandTimeCombined = moment(LocalDateandTimeCombined, "YYYY-MM-DD HH:mm")
            .utc()
            .format("YYYY-MM-DD HH:mm");

        let data = {
            date: UTCDateandTimeCombined.split(" ")[0],
            slot: this.props.slot.split(" ")[1],
            new_slot: UTCDateandTimeCombined.split(" ")[1],
        };
        api.provider
            .update_away_schedule({ data })
            .then((response) => {
                this.props.history.push(providerRoute + "/schedule");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * Will be called when `this.state.scheduleType` == `away`
     */
    createAwaySchedule = () => {
        /** Creating time slot array for request body */
        let timeSlotsForRequest = this.props.startTimeSlot;
        let DateTimeCombined =
            this.state.start_date.format("YYYY-MM-DD") + " " + timeSlotsForRequest;
        let UTCDateTime = moment(DateTimeCombined, "YYYY-MM-DD HH:mm")
            .utc()
            .format("YYYY-MM-DD HH:mm");
        let SplittedUTCDateTime = {
            date: UTCDateTime.split(" ")[0],
            slot: UTCDateTime.split(" ")[1],
        };
        const seriesData = _.cloneDeep(this.state.seriesData);
        seriesData["frequencyDays"] = transformFrequencyDaysForServer(seriesData);

        /** this request body is for only "away" time slot feature */
        let requestBody = {
            date: SplittedUTCDateTime.date,
            slot: SplittedUTCDateTime.slot,
            allotted_time: parseInt(this.state.allotted_time),
            seriesData,
        };
        this.props.onNewScheduleCreate(requestBody, AWAY);
    };

    /**
     * Will be called when `this.state.scheduleType` == `video_consultation`
     */
    createVideoConsultationSchedule = () => {
        let dateString =
            this.state.start_date.format("YYYY-MM-DD") + " " + this.state.timeSlotStart;
        let local_date_time = moment(dateString, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
        const seriesData = _.cloneDeep(this.state.seriesData);
        seriesData["frequencyDays"] = transformFrequencyDaysForServer(seriesData);

        let requestBody = {
            local_date_time,
            allotted_time: this.getAllottedTime(this.state.scheduleType, this.state.isChild),
            event_type: this.state.scheduleType,
            seriesData,
        };
        if (isUserAdmin()) {
            requestBody.providerId = this.state.providerId;
        }

        this.props.onNewScheduleCreate(
            requestBody,
            this.state.scheduleType,
            this.state.selectedGuest,
        );
    };

    /**
     * Prepares the time slots array ranging from the `timeSlotStart` to `timeSlotEnd`
     *
     * @returns {Array.<string>} returns the time slots range selected
     */
    prepareTimeSlotsForRequest = () => {
        let indexStart = timeSlotArray.findIndex((el) => el === this.props.startTimeSlot);
        let indexEnd = timeSlotArray.findIndex((el) => el === this.props.startTimeSlot);
        let tsArrary = timeSlotArray.slice(indexStart, indexEnd + 1);
        return tsArrary.map((slot) => {
            return moment(slot, "HH:mm").utc().format("HH:mm");
        });
    };

    repeatCheckBoxHandler = (e) => {
        let checked = e.target.checked;
        this.setState(function (ps) {
            return {
                isRepeatChecked: checked,
            };
        });
    };

    getPatientDetails = async (patientId) => {
        const urlParams = { patientId: patientId };
        const options = { params: { showLoader: true } };
        const response = await api.shared.fetch_patient_details({ urlParams, options });
        return response.user;
    };

    setGuest = async (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        if (isAuthorized("provider:behavioral")) {
            const data = { patient_username: selectedOption["value"] };
            const patientDetails = await this.getPatientDetails(selectedOption["value"]);
            const patientAgeInYears = moment().diff(
                moment(patientDetails.dob, "MM/DD/YYYY"),
                "years",
            );
            const isChild = patientAgeInYears < 18;
            this.setState({
                isChild,
                scheduleTypeOptions: isAuthorized("provider:prescribe")
                    ? this.DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS
                    : isAuthorized("provider:behavioral")
                    ? this.DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS
                    : [{ value: AWAY, display: "Away" }],
            });
        }

        this.setState({
            selectedGuest: selectedOption["value"],
            searchText: selectedOption["label"],
        });
    };

    onInputChange = (query, { action }) => {
        if (action !== "set-value" && action !== "menu-close" && action !== "input-blur") {
            this.setState({ searchText: query });
            return query;
        }
        return this.state.searchText;
    };

    allottedTimeChangeHandler = (value) => {
        let awayDurations = [...this.state.awayDurations];
        for (let duration of awayDurations) {
            duration.active = duration.value === value;
        }
        this.setState({
            allotted_time: value,
            awayDurations,
        });
    };

    render() {
        const { dismiss, position, edit } = this.props;

        /**
         * sets position to center if `position` is `null`
         */
        const styles = {
            top: position ? `${position.top}` : "50%",
            left: position ? `${position.left}` : "50%",
            bottom: position ? `${position.bottom}` : "auto",
            transform: !position && "translate(-50%,-50%)",
        };
        const scheduleTypeInput = (
            <div className="FormGroup">
                <Label>Select Appointment Type</Label>
                <Select
                    name="schedule_type"
                    changeHandler={this.scheduleTypeChangeHandler}
                    value={this.state.scheduleType}
                    options={this.getDefaultScheduleTypeOptions()}
                />
            </div>
        );

        const startDateInput = (
            <div className="FormGroup position-relative">
                <button
                    className={`ScheduleEvent__select-btn ${edit ? "scheduleEditing" : ""}${
                        this.state.datePickerConf === "from"
                            ? "ScheduleEvent__select-btn--active"
                            : ""
                    }`}
                    onClick={() => this.datePickerHandler("from")}>
                    {this.state.start_date.format("MMMM DD, YYYY")}
                </button>
            </div>
        );

        const startSlotInput = (
            <div className="FormGroup">
                <Select
                    name="start_slot"
                    changeHandler={this.setStartTimeSlot}
                    value={this.props.startTimeSlot}
                    options={this.state.startTimeSlotOptions}
                />
            </div>
        );

        const inviteGuestsInput = (
            <div className="FormGroup">
                <AsyncSelect
                    id={"appointment-search-box"}
                    placeholder="Search"
                    inputValue={this.state.searchText}
                    value={this.state.searchText}
                    loadOptions={this.fetchPatients}
                    onChange={this.setGuest}
                    onInputChange={this.onInputChange}
                    onSelectResetsInput={false}
                    onBlurResetsInput={false}
                    isSearchable={true}
                    isClearable={true}
                    defaultOptions
                />
            </div>
        );

        const repeatCriteria = (
            <div className="FormGroup">
                <Select
                    disabled={!this.state.isRepeatChecked}
                    name="repeat_criteria"
                    value={this.state.repeatCriteria}
                    options={this.repeatCriteriaOptions}
                />
            </div>
        );

        const repeatDate = (
            <div className="FormGroup position-relative">
                <button
                    disabled={!this.state.isRepeatChecked}
                    className={`ScheduleEvent__select-btn ${
                        this.state.showRepeatDatePicker ? "ScheduleEvent__select-btn--active" : ""
                    }`}
                    onClick={() => this.datePickerHandler("repeat")}>
                    {this.state.repeat_date.format("MMMM DD, YYYY")}
                </button>
                <div className="ScheduleEvent__from-datepicker-input position-absolute">
                    {this.state.showRepeatDatePicker && (
                        <DatePicker onDaySelect={this.setEndDate} />
                    )}
                </div>
            </div>
        );

        const description = (
            <div className="FormGroup">
                <Label>Description</Label>
                <TextArea name="description" rows="3" />
            </div>
        );

        const awayDurationControls = (
            <div className={"row pb-4"}>
                <div className={"col-12"}>
                    <Label>Duration:</Label>
                </div>
                {this.state.awayDurations.map((duration, index) => {
                    let classes = "Btn Btn-sm";
                    if (duration.active) {
                        classes += " Btn--sec";
                    } else {
                        classes += " Btn--otl-sec";
                    }
                    return (
                        <div key={duration.label + index} className={"col-3"}>
                            <Button
                                className={classes}
                                style={{ padding: 0, minWidth: 80, marginRight: 10 }}
                                onClick={() => this.allottedTimeChangeHandler(duration.value)}>
                                {duration.label}
                            </Button>
                        </div>
                    );
                })}
            </div>
        );

        const videoConsultationControls = (
            <Fragment>
                <div className="col-12" style={{ paddingBottom: 15 }} data-private={"true"}>
                    <div>
                        <Label>Invite Patient</Label>
                    </div>
                    {this.state.patientList && inviteGuestsInput}
                </div>
                {/*Temporarily hiding repeat functionality*/}
                {/*<div className="col-12 mb-2">*/}
                {/*    <CheckBox secondary onCheck={this.repeatCheckBoxHandler} />*/}
                {/*</div>*/}
                {/*<div className="col-6 pr-2">*/}
                {/*    {repeatCriteria}*/}
                {/*</div>*/}
                {/*<div className="col-6 pl-2">*/}
                {/*    {repeatDate}*/}
                {/*</div>*/}
                {/*<div className="col-12">*/}
                {/*    {description}*/}
                {/*</div>*/}
            </Fragment>
        );

        let datePickerContext = null;

        if (this.props.datePickerConf) {
            if (this.props.datePickerConf === "from") {
                datePickerContext = this.state.start_date;
            } else if (this.props.datePickerConf === "to") {
                datePickerContext = this.state.end_date;
            }
        }

        let buttonsStyle = "justify-content-end";

        if (this.props.newFormat) {
            buttonsStyle = "justify-content-center";
        }

        return (
            <div
                style={position ? styles : null}
                className={
                    !this.props.edit
                        ? this.props.newFormat
                            ? "ScheduleEvent__Flat pt-4"
                            : "ScheduleEvent px-3"
                        : "EditEvent"
                }>
                {this.props.datePickerConf && (
                    <div className="ScheduleEvent__DatePicker-wpr" onClick={this.dismissHandler}>
                        <div className="ScheduleEvent__DatePicker-inner">
                            <DatePicker
                                externalCtx={datePickerContext}
                                onDaySelect={this.onDateSelect}
                            />
                        </div>
                    </div>
                )}
                {!this.props.edit && !this.props.newFormat && (
                    <button
                        onClick={dismiss}
                        className="ScheduleEvent__header-close-btn d-block mt-3 ml-auto"
                        type="button"
                    />
                )}
                <section>
                    <div className="row no-gutters">
                        <div className="col-12">{scheduleTypeInput}</div>
                        {this.state.scheduleType === "away" && awayDurationControls}
                        <div className="col-6 pr-2">
                            <div>
                                <Label>Date</Label>
                            </div>
                            {startDateInput}
                        </div>
                        <div className="col-6 pl-2">
                            <div>
                                <Label>Time</Label>
                            </div>
                            {startSlotInput}
                        </div>
                        <ReoccurrenceDetails
                            updateState={this.updateSeriesData}
                            seriesData={this.state.seriesData}></ReoccurrenceDetails>
                        {this.state.scheduleType !== "away" && videoConsultationControls}
                    </div>
                    {this.state.error && (
                        <p className={"text-center schedule-error"}>{this.state.error}</p>
                    )}
                    <div className={`d-flex ${buttonsStyle} mb-3 pb-1`}>
                        <Button onClick={dismiss} className="Btn Btn--otl-pri mr-3 Btn-sm">
                            Cancel
                        </Button>
                        {this.props.edit ? (
                            <Button
                                onClick={this.createNewSchedule}
                                className="Btn Btn--pri Btn-sm">
                                Update
                            </Button>
                        ) : (
                            <Button
                                onClick={this.createNewSchedule}
                                className="Btn Btn--pri Btn-sm">
                                Add
                            </Button>
                        )}
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};
export default connect(mapStateToProps)(withRouter(ScheduleEvent));

/**
 *  Transforms time slot array for `Select` element options
 *
 * @param {Array.<string>} tsArray Time slot array of the form `['09:00','09:30']`
 * @returns {Array.<{value: string,display: string}>} returns the array of Objects idesl for `Select` options
 */
export const transformTimeSlotArray = (tsArray) => {
    return tsArray.map((ts) => {
        let LocalTime = moment(ts, "HH:mm").format("hh:mm a");
        return { value: ts, display: LocalTime };
    });
};
