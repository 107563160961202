// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import { Form } from "formik";
import Recaptcha from "react-recaptcha";
import * as yup from "yup";
import { showAlertWithAction, showAlertWithCustomHTML } from "../../../../redux/actions/alerts";

import "./styles.scss";
import { api } from "../../../../APIRequests";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import { CustomForm } from "../../../../component-library/CustomForm";
import { Button, ButtonTypes } from "../../../../component-library";
import { FormTextInput } from "../../../../component-library/FormFields/FormTextInput/FormTextInput";
import { FormSelect } from "../../../../component-library/FormFields/FormSelect/FormSelect";
import { FormFieldError } from "../../../../component-library/FormFields/FormFieldError/FormFieldError";
import { OPERATIONAL_OVERRIDE_RECAPTCHA } from "../../../../constants/featureFlags";

const HCPInfo = ({
    update_state_and_get_next_node,
    currentStepName,
    // redux
    configs,
}) => {
    let reCaptchaRef = React.createRef();

    const [mounted, setMounted] = useState(false);

    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [defaultPracticeId, setDefaultPracticeId] = useState(false);
    const publicFlags = useSelector((state) => state.flags.publicFlags);
    const publicFlagsHaveLoaded = useSelector((state) => state.flags.publicFlagsHaveLoaded);

    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 500);
    }, []);

    useEffect(() => {
        (async () => {
            const default_practice_id = await api.public
                .get_config({ queryParams: { feature_name: "HCP_DEFAULT_PRACTICE" } })
                .then((result) => result.practice_id);
            const practices = await api.public.get_all_hcp_practices({
                params: { showLoader: true },
            });
            const filteredPractices = practices.filter((p) => p.id !== default_practice_id);
            const selectOptions = filteredPractices.map((practice) => {
                return {
                    value: practice.id,
                    label: practice.name,
                };
            });
            selectOptions.sort((a, b) => a.label.localeCompare(b.label));
            selectOptions.push({ label: "Other", value: default_practice_id });
            setDefaultPracticeId(default_practice_id);
            setOrganizationOptions(selectOptions);
        })();
    }, []);

    const renderForm = (formikProps) => {
        if (
            publicFlagsHaveLoaded &&
            publicFlags?.[OPERATIONAL_OVERRIDE_RECAPTCHA] &&
            !formikProps.values.recaptcha
        ) {
            formikProps.setFieldValue("recaptcha", true);
        }

        return (
            <Form className="HCPWorkflowShared-form">
                <div className="HCPWorkflowShared-form_fields">
                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Your First Name"
                        name="first_name"
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />

                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Your Last Name"
                        name="last_name"
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />

                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Email"
                        name="email"
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />

                    <FormTextInput
                        className="HCPWorkflowShared-form_field"
                        title="Confirm Email"
                        name="email_validation"
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />

                    <FormSelect
                        className="HCPWorkflowShared-form_field"
                        title="Your Organization"
                        placeholder="Search Organization"
                        name="organization"
                        options={organizationOptions}
                        formikProps={formikProps}
                        triggerErrorOnChange
                        testId={"HCPOrganizationDropdown"}
                    />

                    <FormSelect
                        className="HCPWorkflowShared-form_field"
                        title="Your Role"
                        placeholder="Search Role"
                        name="role"
                        options={rolesOptions}
                        formikProps={formikProps}
                        triggerErrorOnChange
                    />
                    {formikProps.values.organization === defaultPracticeId && (
                        <FormTextInput
                            className="HCPWorkflowShared-form_field"
                            title="Your Organization Name"
                            placeholder="Your Organization Name"
                            name="organization_name"
                            formikProps={formikProps}
                            triggerErrorOnChange
                        />
                    )}
                </div>

                <div className={"mt-4 fs-15 txt-gry"}>
                    <span id={"self-signup-consent"}>
                        By clicking “Agree and Continue”, you are authorizing that the entity
                        represented in Your Organization is one you are officially employed by. Any
                        individual who is submitting this without a prior reason and authorized
                        affiliation is subject to the full extent of the law.
                    </span>
                </div>

                <div className={"my-4 text-center w-100"}>
                    <div>
                        {mounted && (
                            <Recaptcha
                                className="mx-auto recaptcha"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={reCaptchaRef}
                                verifyCallback={async () => {
                                    await formikProps.setFieldValue("recaptcha", true);
                                    await formikProps.setFieldTouched("recaptcha", true);
                                }}
                            />
                        )}
                    </div>
                    <div className={"text-center my-2"}>
                        {formikProps.touched?.recaptcha && formikProps.errors.recaptcha && (
                            <FormFieldError>{formikProps.errors.recaptcha}</FormFieldError>
                        )}
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        // button is disabled if the form has not been touched yet
                        // or if there are errors
                        disabled={!formikProps.dirty || Object.keys(formikProps.errors).length}
                        type="submit"
                        className="Btn Btn--pri self-signup-btn">
                        Agree and Continue
                    </Button>
                </div>
            </Form>
        );
    };

    const submitHandler = async (formData, formikProps) => {
        // fill in the correct organization name
        // if the free text was not selected
        if (formData.organization !== defaultPracticeId) {
            const organizationOption = organizationOptions.find(
                (item) => item.value === formData.organization,
            );

            formData.organization_name = organizationOption?.label ?? "";
            // reset organization name field so that if the update fails switching to
            // the other option will not cause the field to be prefilled
            formikProps.setFieldValue("organization_name", "");
        }

        const clonedFormData = _.cloneDeep(formData);

        delete clonedFormData.recaptcha;
        delete clonedFormData["email_validation"];

        const state = { hcp_info: clonedFormData, practice_id: clonedFormData["organization"] };

        await update_state_and_get_next_node(state, currentStepName);
    };

    return (
        <div className={"container hcp-mobile-container px-sm-3 py-sm-5"}>
            <CardSecondary className="HCPWorkflowShared-card">
                <div className="HCPWorkflowShared-header_container">
                    <h1 className="HCPWorkflowShared-title">Let’s get started.</h1>
                    <p className="HCPWorkflowShared-sub_title">
                        Tell us a bit about yourself so we know who’s making the referral.
                    </p>
                </div>
                <CustomForm
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        email: "",
                        email_validation: "",
                        organization: "",
                        role: "",
                        organization_name: "",
                        recaptcha: false,
                    }}
                    validationSchema={getHCPValidation(defaultPracticeId)}
                    onSubmit={submitHandler}
                    render={renderForm}
                />
            </CardSecondary>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, { showAlertWithCustomHTML, showAlertWithAction })(HCPInfo);

const getHCPValidation = (defaultPracticeId) =>
    yup.object().shape({
        first_name: yup
            .string()
            .required("Your First Name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        last_name: yup
            .string()
            .required("Your Last Name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        email_validation: yup
            .string()
            .email("Enter a valid email address")
            .required("Confirm Email is required")
            .test(
                "email-match",
                "Email address does not match, please check spelling",
                function (value) {
                    if (!value) {
                        return false;
                    }
                    return this.parent.email === value;
                },
            ),
        organization: yup.string().required("Your Organization is required"),
        role: yup.string().required("Your Role is required"),
        organization_name: yup.string().when("organization", {
            is: defaultPracticeId,
            then: yup
                .string()
                .required("Organization Name is required")
                .test("organization_name", "Organization Name Validation", (value) => {
                    return Boolean(value);
                }),
        }),
        recaptcha: yup.boolean().oneOf([true], "reCAPTCHA is required"),
    });

const rolesOptions = [
    { value: "Administrator", label: "Administrator" },
    { value: "aptihealth Care Navigator", label: "aptihealth Care Navigator" },
    {
        value: "aptihealth Patient Engagement Specialist",
        label: "aptihealth Patient Engagement Specialist",
    },
    { value: "Care Manager", label: "Care Manager" },
    { value: "Case Manager", label: "Case Manager" },
    { value: "Crisis Team Member", label: "Crisis Team Member" },
    { value: "Discharge Coordinator", label: "Discharge Coordinator" },
    { value: "Medical Assistant", label: "Medical Assistant" },
    { value: "Medical Provider (MD, DO, NP, PA)", label: "Medical Provider (MD, DO, NP, PA)" },
    { value: "Nurse (RN, LPN)", label: "Nurse (RN, LPN)" },
    { value: "Psychiatrist", label: "Psychiatrist" },
    { value: "Psychologist", label: "Psychologist" },
    { value: "Social Worker", label: "Social Worker" },
    { value: "Other", label: "Other" },
];
