// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import PatientProfileInsurance from "../../../components/Patient/Profile/PatientProfileInsurance";
import { api } from "../../../APIRequests";
import { connect } from "react-redux";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import axios from "axios";
import ProfileLoader from "../../../components/Common/Loaders/ProfileLoader";
import {
    getInsuranceImagePresignedUrl,
    INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE,
    INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE,
    INSURANCE_IMAGE_FILE_SECONDARY_BACK_SUB_TYPE,
    INSURANCE_IMAGE_FILE_SECONDARY_FRONT_SUB_TYPE,
    PATIENT_INSURANCE_IMAGE_FILE_TYPE,
    REFERRAL_INSURANCE_IMAGE_FILE_TYPE,
} from "../../../utils/fileUtil";

class PatientInsurance extends Component {
    state = {
        patientProfile: null,
        patientAvatar: null,
        insuranceImageUrls: {
            pfUrl: null,
            pbUrl: null,
            sfUrl: null,
            sbUrl: null,
        },
        insuranceImageTypes: {
            pfType: null,
            pbType: null,
            sfType: null,
            sbType: null,
        },
        insuranceImageStatuses: {
            pfStatus: null,
            pbStatus: null,
            sfStatus: null,
            sbStatus: null,
        },
    };

    componentDidMount() {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["logoSection"] = { title: "Profile" };
        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);
        this.getProfile();
    }
    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration({});
    }

    getProfile = async () => {
        try {
            const options = { params: { showLoader: false } };
            let response = await api.patient.fetch_profile({ options });
            this.setState({
                patientProfile: response && response.user,
            });
            loadPatientInsuranceImagesUrlToState(response.user, this);
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        return (
            <div className="container px-3">
                <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">Insurance</h1>
                {this.state.patientProfile ? (
                    <PatientProfileInsurance
                        profileData={this.state.patientProfile}
                        insuranceImageUrls={this.state.insuranceImageUrls}
                        insuranceImageTypes={this.state.insuranceImageTypes}
                        insuranceImageStatuses={this.state.insuranceImageStatuses}
                        reloadImg={(user) => loadPatientInsuranceImagesUrlToState(user, this)}
                    />
                ) : (
                    <ProfileLoader />
                )}
            </div>
        );
    }
}

export default connect(null, { setDynamicRouteConfiguration })(PatientInsurance);

export const loadPatientInsuranceImagesUrlToState = (patient, that) => {
    loadInsuranceImagesUrlToState(
        patient.username,
        patient,
        PATIENT_INSURANCE_IMAGE_FILE_TYPE,
        that,
    );
};

export const loadReferralInsuranceImagesUrlToState = (referral, that) => {
    loadInsuranceImagesUrlToState(
        referral.referralCode,
        referral,
        REFERRAL_INSURANCE_IMAGE_FILE_TYPE,
        that,
    );
};

const loadInsuranceImagesUrlToState = (entityId, entity, fileType, that) => {
    fetchInsuranceImages(entityId, entity, fileType).then(
        axios.spread((pfUrl, pbUrl, sfUrl, sbUrl) =>
            spreadImages(pfUrl, pbUrl, sfUrl, sbUrl, that),
        ),
    );
};

const fetchInsuranceImages = (entityId, entity, fileType) => {
    let prIns = entity.primary_insurance;
    let secIns = entity.secondary_insurance;

    let pf = prIns.card_front; //primary front
    let pb = prIns.card_back; // primary back
    let sf = secIns.card_front; // secondary front
    let sb = secIns.card_back; // secondary back

    return axios.all([
        getImage(entityId, pf, fileType, INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE),
        getImage(entityId, pb, fileType, INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE),
        getImage(entityId, sf, fileType, INSURANCE_IMAGE_FILE_SECONDARY_FRONT_SUB_TYPE),
        getImage(entityId, sb, fileType, INSURANCE_IMAGE_FILE_SECONDARY_BACK_SUB_TYPE),
    ]);
};

const spreadImages = (pfUrl, pbUrl, sfUrl, sbUrl, that) => {
    that.setState({
        insuranceImageUrls: {
            pfUrl: pfUrl.message,
            pbUrl: pbUrl.message,
            sfUrl: sfUrl.message,
            sbUrl: sbUrl.message,
        },
    });
    that.setState({
        insuranceImageTypes: {
            pfType: pfUrl.content_type,
            pbType: pbUrl.content_type,
            sfType: sfUrl.content_type,
            sbType: sbUrl.content_type,
        },
    });
    that.setState({
        insuranceImageStatuses: {
            pfStatus: pfUrl.status,
            pbStatus: pbUrl.status,
            sfStatus: sfUrl.status,
            sbStatus: sbUrl.status,
        },
    });
};

/**
 * This function is intended to be passed to axios.all() call
 */
const getImage = (username, fileKey, fileType, fileSubType) => {
    if (!fileKey || fileKey.trim() === "" || fileKey === "None") {
        /**
         *  return `new Promise` with resolve value as `null`
         *  This is to support `axios.all` as return value should be a `Promise`
         */
        return new Promise((resolve) => {
            resolve({ message: null });
        });
    }
    return getInsuranceImagePresignedUrl(username, fileType, fileSubType);
};
