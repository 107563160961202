// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import {
    BASIC_SCHEMA,
    INITIAL_VALUES,
    MED_MGMT_OPTIONS,
    PATIENT_BASIC_SCHEMA,
    PATIENT_PCP_SCHEMA,
    PRI_INSURANCE_SCHEMA,
    SEC_INSURANCE_SCHEMA,
} from "./schema/newPatientFormSchema";
import { PATIENT_VALIDATION_SCHEMA, VALIDATION_SCHEMA } from "./schema/newPatientValidation";
import { FieldArray, Form } from "formik";
import FormikInput from "../../../components/UI/FormikInput";
import Button from "../../../components/UI/Button";
import { CardPrimary } from "../../../components/UI/Card";
import { CardHeading } from "../../../components/UI/Headings";
import Label from "../../../components/UI/Label";
import { uploadAnonymousInsuranceImage } from "./providerAPI";
import { api } from "../../../APIRequests";
import _, { cloneDeep as _cloneDeep } from "lodash";
import { connect } from "react-redux";
import { addNewPatient } from "../../../redux/actions/provider";
import { isUserAdmin, isUserPCP } from "../../../redux/actions/auth";
import FormikInputFormat from "../../../components/UI/formikMobileInput";
import { getQueryParams } from "../../../utils/filters";
import { trasformDOBsToValidStrings } from "../../../components/Patient/Profile/InsuranceInfo";
import moment from "moment";
import { default as ReactSelect } from "react-select";
import { ProviderReferralHOC, UploadFileModalHOC } from "../../../component-library";

import UploadFile, { UploadFileList } from "../../../components/Provider/PatientView/UploadFile";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import { CustomForm } from "../../../component-library/CustomForm";
import { uploadAssociatedFiles, UserFileTypes } from "../../../utils/fileUtil";
import { addToast, toastMessageTypes } from "../../../redux/actions/toaster";
import { generateUUID } from "../../../utils/EncryptionUtil";

class Patient extends Component {
    constructor(props) {
        super(props);
        this.setValues = null;
        this.state = {
            showForm: false,
            pri_insurance_schema: null,
            sec_insurance_schema: null,
            secInsAdded: false,
            frontImgPreview: null,
            backImgPreview: null,
            showInsuranceCard: false,
            insCardType: null,
            priInsFront: null,
            priInsFrontFileName: null,
            priInsBack: null,
            priInsBackFileName: null,
            secInsFront: null,
            secInsBack: null,
            insEditPri: false,
            insEditSec: false,
            urgent_patient: false,
            uuid: generateUUID(),
            patientId: this.props.match.params.patientId,
            patient_pcp_schema: null,
            practice_members: null,
            screeningId: getQueryParams("screeningId", props.location.search),
            associatedFiles: [],
            a5AssociatedFiles: [],
            isPracticeSPMI: false,
            initialFormValues: _cloneDeep(INITIAL_VALUES),
            isLoaded: false,
            selectedAssessmentConfig: "skip_all",
            assessmentConfig: {
                skip_all: false,
            },
        };
    }

    componentDidMount = async () => {
        /*
         * get dropdown list options for insurance fields from api
         */
        const options = { params: { showLoader: false } };
        api.patient
            .fetch_insurance_list({ options })
            .then((cleanResponse) => {
                let carriersOptions = cleanResponse.carriers.map((carriers) => {
                    return { value: carriers, display: carriers };
                });
                let statesOptions = cleanResponse.state.map((states) => {
                    return { value: states, display: states };
                });
                // modify original schema with received options list defined and set them in state
                let pri_insurance_schema = this.mapSchemaOptions(
                    PRI_INSURANCE_SCHEMA,
                    carriersOptions,
                    statesOptions,
                );
                let sec_insurance_schema = this.mapSchemaOptions(
                    SEC_INSURANCE_SCHEMA,
                    carriersOptions,
                    statesOptions,
                );

                this.setState({
                    carriersOptions,
                    pri_insurance_schema: pri_insurance_schema,
                    sec_insurance_schema: sec_insurance_schema,
                });
            })
            .catch((err) => {
                console.log(err);
            });

        if (this.props.profile) {
            await this.loadSecondaryDetails();
        }

        if (this.state.screeningId) {
            this.setState({
                assessmentConfig: {
                    default_assessment: "A30",
                },
            });
        }
    };

    loadSecondaryDetails = async () => {
        if (isUserPCP()) {
            if (this.props.profile.practice_id) {
                const practice = await api.shared.fetch_practice({
                    urlParams: { practiceId: this.props.profile.practice_id },
                });
                if (_.has(practice, "config") && _.has(practice.config, "assessment_config")) {
                    this.setState({
                        assessmentConfig: practice.config.assessment_config,
                        isPracticeSPMI: practice.is_spmi,
                        initialFormValues: {
                            ...this.state.initialFormValues,
                            is_spmi: practice.is_spmi,
                        },
                    });

                    if (this.state.screeningId) {
                        this.setState({
                            assessmentConfig: {
                                default_assessment: "A5",
                            },
                        });
                    }
                }

                if (practice.is_spmi) {
                    this.setState({
                        assessmentConfig: {
                            skip_all: true,
                            skip_a5_for_referral: true,
                        },
                        isPracticeSPMI: true,
                        initialFormValues: { ...this.state.initialFormValues, is_spmi: true },
                    });
                }
            }
            api.provider.fetch_practice_members().then((response) => {
                let memberOptions = response
                    .filter((member) => member.provider_type === "PCP")
                    .map((member) => {
                        return { value: member.username, display: member.name };
                    });

                let schema = PATIENT_PCP_SCHEMA.map((el) => {
                    if (el.name === "pcp_id") {
                        el.elementConfig.options = memberOptions;
                    }
                    return el;
                });

                this.setState({
                    practice_members: response,
                    patient_pcp_schema: schema,
                });
            });
        }

        if (this.state.patientId) {
            this.setPatientDetails();
        } else if (this.state.screeningId) {
            await this.setScreenerDetails();
        }
        this.setState({ isLoaded: true });
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.profile && this.props.profile) {
            await this.loadSecondaryDetails();

            if (this.state.screeningId) {
                this.setState({
                    assessmentConfig: {
                        default_assessment: "A30",
                    },
                });
            }
        }
    }

    async setScreenerDetails() {
        try {
            const queryParams = { screening_id: this.state.screeningId };
            const response = await api.shared.fetch_a5_screening_details({ queryParams });
            if (response) {
                const patientDetails = _cloneDeep(INITIAL_VALUES);
                patientDetails["first_name"] = response["first_name"] ? response["first_name"] : "";
                patientDetails["last_name"] = response["last_name"] ? response["last_name"] : "";
                patientDetails["dob"] = response["dob"] ? response["dob"] : "";
                patientDetails["gender"] = response["gender"] ? response["gender"] : "";
                patientDetails["mobile"] = response["mobile"] ? response["mobile"] : "";
                patientDetails["email"] = response["email"] ? response["email"] : "";
                patientDetails["email_validation"] = response["email"] ? response["email"] : "";
                if (response["address"]) {
                    patientDetails["address"]["street"] = response["address"]["street"]
                        ? response["address"]["street"]
                        : "";
                    patientDetails["address"]["city"] = response["address"]["city"]
                        ? response["address"]["city"]
                        : "";
                    patientDetails["address"]["state"] = response["address"]["state"]
                        ? response["address"]["state"]
                        : "";
                    patientDetails["address"]["zip_code"] = response["address"]["zip_code"]
                        ? response["address"]["zip_code"]
                        : "";
                }
                patientDetails["pcp_id"] = response["pcp_id"] ? response["pcp_id"] : "";
                let a5AssociatedFiles = response["associated_files"]
                    ? response["associated_files"]
                    : [];
                this.setState({ patientDetails: patientDetails, a5AssociatedFiles });
            }
        } catch (e) {
            console.log(e);
        }
    }

    setPatientDetails = () => {
        let urlParams = { patientId: this.state.patientId };
        api.shared
            .fetch_patient_details({ urlParams })
            .then((response) => {
                let details = { ...response.user };
                details.email_validation = details.email;
                details.emergency_name = details.emergency_contact_person;
                details.emergency_contact_no = details.emergency_contact_number;
                details.email_validation = details.email;
                details["90_day_care_plan"] = details.ninety_day_care;
                if (isUserAdmin() || !this.state.patientId) {
                    details["dob"] = details.hasOwnProperty("dob")
                        ? details["dob"].replace(/\//g, "")
                        : "";
                }
                details = trasformDOBsToValidStrings(details);

                details.insurance = [
                    {
                        type: "primary_insurance",
                        form: details.primary_insurance,
                    },
                ];

                if (details.secondary_insurance.carrier) {
                    details.insurance.push({
                        type: "secondary_insurance",
                        form: details.secondary_insurance,
                    });
                }

                try {
                    this.setState({
                        patientDetails: details,
                        secInsAdded: !["", "None", null].includes(
                            details.secondary_insurance.member_id,
                        ),
                        insEditPri: details.primary_insurance.card_front !== "",
                        insEditSec: !["", "None", null].includes(
                            details.secondary_insurance.card_front,
                        ),
                    });
                } catch (e) {
                    console.log(e);
                }
                if (details.primary_insurance.card_front) {
                    this.getImg("priInsFront", details.primary_insurance.card_front);
                }
                if (details.primary_insurance.card_back) {
                    this.getImg("priInsBack", details.primary_insurance.card_back);
                }
                if (details.primary_insurance.card_front) {
                    this.getImg("secInsFront", details.primary_insurance.card_front);
                }
                if (details.primary_insurance.card_back) {
                    this.getImg("secInsBack", details.primary_insurance.card_back);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getImg = async (prop, key) => {
        try {
            let urlParams = { patientId: this.state.patientId };
            let options = { params: { showLoader: false } };
            let data = { key: key };
            let cleanResponse = await api.shared.fetch_patient_file({ data, urlParams, options });
            if (cleanResponse && cleanResponse.message) {
                let newState = { ...this.state };
                newState[prop] = { preview: cleanResponse.message };
                this.setState(newState);
            }
        } catch (err) {
            console.log(err);
        }
    };

    mapSchemaOptions = (insuranceSchema, carriersOptions, statesOptions) => {
        return insuranceSchema.map((el) => {
            if (el.name === "insurance[0].form.state" || el.name === "insurance[1].form.state") {
                el.elementConfig.options = statesOptions;
            } else if (
                el.name === "insurance[0].form.carrier" ||
                el.name === "insurance[1].form.carrier"
            ) {
                el.elementConfig.options = carriersOptions;
            }
            return el;
        });
    };

    alteredFormData = (data) => {
        let alteredData = { ...data };

        // DOBnumber stores all date in MMDDYYYY format without /
        let DOBnumber = {
            dob: alteredData.dob,
        };
        if (alteredData.insurance[0].form.policy_dob) {
            DOBnumber = {
                ...DOBnumber,
                primaryDOB: alteredData.insurance[0].form.policy_dob,
            };
        }
        if (alteredData.insurance[1]) {
            DOBnumber = {
                ...DOBnumber,
                secondaryDOB: alteredData.insurance[1].form.policy_dob,
            };
        }

        // alter DOBnumber object in MM/DD/YYYY format
        DOBnumber = {
            ...DOBnumber,
            dob: `${DOBnumber.dob.substring(0, 2)}/${DOBnumber.dob.substring(
                2,
                4,
            )}/${DOBnumber.dob.substring(4, 8)}`,
        };

        if (DOBnumber.primaryDOB && !DOBnumber.primaryDOB.includes("/")) {
            DOBnumber = {
                ...DOBnumber,
                primaryDOB: `${DOBnumber.primaryDOB.substring(
                    0,
                    2,
                )}/${DOBnumber.primaryDOB.substring(2, 4)}/${DOBnumber.primaryDOB.substring(4, 8)}`,
            };
        }
        if (DOBnumber.secondaryDOB && !DOBnumber.secondaryDOB.includes("/")) {
            DOBnumber = {
                ...DOBnumber,
                secondaryDOB: `${DOBnumber.secondaryDOB.substring(
                    0,
                    2,
                )}/${DOBnumber.secondaryDOB.substring(2, 4)}/${DOBnumber.secondaryDOB.substring(
                    4,
                    8,
                )}`,
            };
        }
        return DOBnumber;
    };

    applyInsPrefix = (s3Location) => {
        if (
            s3Location &&
            s3Location !== "None" &&
            !s3Location.includes("backend/insurance_cards/")
        ) {
            return "backend/insurance_cards/" + s3Location;
        } else {
            return s3Location;
        }
    };

    submitHandler = async (formData, actions) => {
        if (!isUserAdmin() && this.state.patientId) {
            formData.dob = `${formData.dob.substring(0, 2)}${formData.dob.substring(
                3,
                5,
            )}${formData.dob.substring(6, 10)}`;
        }

        let newDOBData = this.alteredFormData(formData);

        let newFormData = { ...formData };
        newFormData.dob = newDOBData.dob;
        newFormData.insurance[0].form.policy_dob = newDOBData.primaryDOB;
        newFormData.insurance[1] &&
            (newFormData.insurance[1].form.policy_dob = newDOBData.secondaryDOB);

        actions.setSubmitting(false);
        //modifying form as per the request body
        let formDataCloned = _cloneDeep(newFormData);

        formDataCloned["primary_insurance"] = formDataCloned["insurance"][0].form;

        if (
            formDataCloned["primary_insurance"].policy_holder_question !== "no" &&
            !CATCH_ALL_INSURANCE_OPTIONS.includes(formDataCloned["primary_insurance"].carrier)
        ) {
            formDataCloned["primary_insurance"][
                "policy_holder_name"
            ] = `${formDataCloned["first_name"]} ${formDataCloned["last_name"]}`;
            formDataCloned["primary_insurance"]["policy_relationship"] = "self";
        }

        formDataCloned["primary_insurance"]["card_front"] = this.applyInsPrefix(
            formDataCloned["primary_insurance"]["card_front"],
        );
        formDataCloned["primary_insurance"]["card_back"] = this.applyInsPrefix(
            formDataCloned["primary_insurance"]["card_back"],
        );

        if (formDataCloned["insurance"][1] && formDataCloned["insurance"].length > 1) {
            formDataCloned["secondary_insurance"] = formDataCloned["insurance"][1].form;
            formDataCloned["secondary_insurance"]["card_front"] = this.applyInsPrefix(
                formDataCloned["secondary_insurance"]["card_front"],
            );
            formDataCloned["secondary_insurance"]["card_back"] = this.applyInsPrefix(
                formDataCloned["secondary_insurance"]["card_back"],
            );
        } else {
            formDataCloned["secondary_insurance"] = {
                state: "",
                carrier: "",
                card_front: "",
                card_back: "",
                member_id: "",
            };
        }
        delete formDataCloned.insurance;

        if (this.state.practice_members) {
            // the dropdown saves it by name so have to do this to send the backend actual name
            formDataCloned["pcp_name"] = this.state.practice_members.find(
                (member) => member.username === formDataCloned["pcp_id"],
            ).name;
        }
        if (this.state.screeningId) {
            formDataCloned["a5_screening_id"] = this.state.screeningId;
        }
        if (!this.state.patientId) {
            formDataCloned["urgent_patient"] = this.state.urgent_patient;
            formDataCloned["side_load_config"] = {
                patient: {
                    assessment_config: this.state.assessmentConfig,
                },
            };

            if (formDataCloned.medication_management_services) {
                formDataCloned.medication_management_services =
                    formDataCloned.medication_management_services.map((option) => option.value);
            }

            formDataCloned["is_spmi"] = this.state.isPracticeSPMI;
            const isIntakeSchedulingEnabled = _.get(
                this.props.profile,
                "intake_scheduling_enabled",
            );

            if (formDataCloned["is_spmi"] || isIntakeSchedulingEnabled) {
                formDataCloned["group_type"] = "patient_auto_signup";
            }

            this.props.addNewPatient(formDataCloned, async (response) => {
                const patientId = _.get(response, "patient_id", null);
                let redirect = "/app/patient";

                if (patientId != null) {
                    if (isIntakeSchedulingEnabled) {
                        redirect = `${redirect}/${patientId}/profile/v2?openScheduleIntakeAppointment=true`;
                    }
                    if (this.state.associatedFiles.length > 0) {
                        await this.processAssociatedFiles(response.referral_code);
                    }
                }

                this.props.history.push(redirect);
                actions.setSubmitting(false);
            });
        } else {
            this.cleanUpFormDataPreSubmission(formDataCloned);
            let urlParams = { patientId: this.state.patientId };
            let data = { ...formDataCloned };
            if (data.hasOwnProperty("assessment_status")) {
                delete data["assessment_status"];
            }
            api.provider
                .update_patient_details({ data, urlParams })
                .then((response) => {
                    this.props.history.go(-1);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    processAssociatedFiles = async (referralCode) => {
        const refFiles = [];
        const associatedFiles = this.state.associatedFiles ? this.state.associatedFiles : [];

        try {
            const processedFiles = await uploadAssociatedFiles(
                associatedFiles,
                "referral",
                referralCode,
                UserFileTypes.ASSOCIATED_FILE_REFERRAL,
            );
            for (let file of processedFiles) {
                refFiles.push({
                    key: file["file_key"],
                    type: "MISC",
                    timestamp: `${moment.utc().valueOf()}`,
                });
            }
        } catch (err) {
            console.log(err);
            this.props.addToast({
                message: err.message,
                messageType: toastMessageTypes.error_v2,
            });
        }

        await api.shared.update_referral({
            data: {
                referralCode: referralCode,
                associated_files: refFiles.concat(this.state.a5AssociatedFiles),
            },
        });
    };

    cleanUpFormDataPreSubmission(formDataCloned) {
        delete formDataCloned.emergency_name;
        delete formDataCloned.emergency_contact_no;
        delete formDataCloned.ninety_day_care;
        this.deleteEmptyFields(formDataCloned);
        this.noneEmptyFields(formDataCloned.primary_insurance);
        this.noneEmptyFields(formDataCloned.secondary_insurance);
    }

    deleteEmptyFields = (obj) => {
        for (let propName in obj) {
            if (!obj[propName]) {
                delete obj[propName];
            }
        }
    };

    noneEmptyFields = (obj) => {
        for (let propName in obj) {
            if (!obj[propName]) {
                obj[propName] = "None";
            }
        }
    };

    showInsuranceUploadPopUp = (type) => {
        let frPreview = null;
        let bckPreview = null;
        if (type === "pri") {
            frPreview = !this.state.priInsFront ? null : { ...this.state.priInsFront };
            bckPreview = !this.state.priInsBack ? null : { ...this.state.priInsBack };
        } else {
            frPreview = !this.state.secInsFront ? null : { ...this.state.secInsFront };
            bckPreview = !this.state.secInsBack ? null : { ...this.state.secInsBack };
        }
        this.setState({
            showInsuranceCard: true,
            insCardType: type,
            frontImgPreview: frPreview,
            backImgPreview: bckPreview,
        });
    };

    hideInsuranceUploadPopUp = (type) => {
        let editPri = this.state.insEditPri;
        let editSec = this.state.insEditSec;
        if (!editPri && type == "pri") {
            this.setState({
                frontImgPreview: null,
                backImgPreview: null,
                priInsFront: null,
                priInsBack: null,
            });
        } else if (!editSec && type == "sec") {
            this.setState({
                frontImgPreview: null,
                backImgPreview: null,
                secInsFront: null,
                secInsBack: null,
            });
        }
        this.setState({
            showInsuranceCard: false,
            insCardType: null,
        });
    };

    frInpHandler = (e, type) => {
        let value = null;
        if (type == "pri") {
            value = "pf"; //primary front
        } else {
            value = "sf"; //secondary front
        }
        let fileName = "";
        let selectedFile = e.target.files[0];
        if (type == "pri") {
            fileName = "primary_front_" + this.state.uuid;
        } else {
            fileName = "secondary_front_" + this.state.uuid;
        }
        let fileType = selectedFile.type;
        uploadAnonymousInsuranceImage(
            { file: selectedFile, fileName, fileType },
            value,
            (value, data) => {
                this.setState({
                    priInsFrontFileName: fileName,
                });
                if (value == "pf") {
                    this.setState({
                        frontImgPreview: data,
                        priInsFront: data,
                    });
                } else if (value == "sf") {
                    this.setState({
                        frontImgPreview: data,
                        secInsFront: data,
                    });
                }
            },
        );
    };

    bckInpHandler = (e, type) => {
        let value = null;
        if (type == "pri") {
            value = 2; //primary back
        } else {
            value = 4; //secondary back
        }
        let fileName = "";
        let selectedFile = e.target.files[0];
        if (type == "pri") {
            fileName = "primary_back_" + this.state.uuid;
        } else {
            fileName = "secondary_back_" + this.state.uuid;
        }
        let fileType = selectedFile.type;
        uploadAnonymousInsuranceImage(
            { file: selectedFile, fileName, fileType },
            value,
            (value, data) => {
                this.setState({
                    priInsBackFileName: fileName,
                });
                if (value == 2) {
                    this.setState({
                        backImgPreview: data,
                        priInsBack: data,
                    });
                } else if (value == 4) {
                    this.setState({
                        backImgPreview: data,
                        secInsBack: data,
                    });
                }
            },
        );
    };

    onInsuranceDone = (insType) => {
        if (
            (insType == "pri" && this.state.insEditPri) ||
            (insType == "sec" && this.state.insEditSec)
        ) {
            if (!this.state.frontImgPreview && !this.state.backImgPreview) {
                if (insType == "pri") {
                    this.setState({
                        priInsFront: null,
                        priInsBack: null,
                    });
                } else {
                    this.setState({
                        secInsFront: null,
                        secInsBack: null,
                    });
                }
                this.setState((ps) => {
                    return {
                        showInsuranceCard: false,
                        insCardType: null,
                        insEditPri: insType == "pri" ? false : ps.insEditPri,
                        insEditSec: insType == "sec" ? false : ps.insEditSec,
                    };
                });
                return;
            }
        }
        if (insType == "pri") {
            this.setValues("insurance[0].form.card_front", this.state.priInsFront?.url);
            this.setValues("insurance[0].form.card_back", this.state.priInsBack?.url);
        } else {
            this.setValues("insurance[1].form.card_front", this.state.secInsFront?.url);
            this.setValues("insurance[1].form.card_back", this.state.secInsBack?.url);
        }

        this.setState((ps) => {
            return {
                frontImgPreview: null,
                backImgPreview: null,
                showInsuranceCard: false,
                insCardType: null,
                insEditPri: insType == "pri" ? true : ps.insEditPri,
                insEditSec: insType == "sec" ? true : ps.insEditSec,
            };
        });
    };

    onFileInputChange = (target) => {
        let files = [...this.state.associatedFiles];
        if (target && target.files.length > 0) {
            for (let file of target.files) {
                files.push(file);
            }
            this.setState({ associatedFiles: files });
        }
    };

    onFileFileDelete = (index) => {
        let changedFiles = [...this.state.associatedFiles];
        changedFiles.splice(index, 1);
        this.setState({
            associatedFiles: changedFiles,
        });
    };

    renderForm = (formikProps) => {
        let schema = PATIENT_BASIC_SCHEMA;

        if (isUserAdmin() || !this.state.patientId) {
            schema = BASIC_SCHEMA;
        }

        this.setValues = formikProps.setFieldValue;

        const fileNames = [this.state.priInsFrontFileName, this.state.priInsBackFileName].filter(
            (fileName) => fileName !== null,
        );

        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="row">
                            {schema.map((formEl) => {
                                if (this.state.patientId && formEl.name === "email_validation") {
                                    return;
                                }

                                if (formEl.elementType == "segment") {
                                    return (
                                        <h6 key={formEl.displayName} className="col-12">
                                            <b>{formEl.displayName}</b>
                                        </h6>
                                    );
                                } else if (formEl.elementType == "readOnly") {
                                    return (
                                        <div key={formEl.name} className={"col-12 col-lg-6"}>
                                            <FormikInput
                                                disabled
                                                inputBorderDisabled="border-0 px-0"
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={formEl.name}
                                            className={
                                                formEl.elementType == "textarea"
                                                    ? "col-12"
                                                    : formEl.name == "dob" ||
                                                      formEl.name == "gender"
                                                    ? "col-6"
                                                    : "col-12 col-lg-6"
                                            }>
                                            {(formEl.name === "email" ||
                                                formEl.name === "email_validation") &&
                                            this.state.patientDetails ? (
                                                <FormikInput
                                                    disabled
                                                    inputBorderDisabled="border-0 px-0"
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name === "mobile" ? (
                                                <FormikInputFormat
                                                    value={formikProps && formikProps.values.mobile}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "mobile",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("mobile");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name === "emergency_contact_no" ? (
                                                <FormikInputFormat
                                                    value={
                                                        formikProps &&
                                                        formikProps.values.emergency_contact_number
                                                    }
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "emergency_contact_no",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched(
                                                            "emergency_contact_no",
                                                        );
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : formEl.name === "dob" ? (
                                                <FormikInputFormat
                                                    value={formikProps && formikProps.values.dob}
                                                    onChange={async (val) => {
                                                        await formikProps.setFieldValue(
                                                            "dob",
                                                            val.value,
                                                        );
                                                        formikProps.setFieldTouched("dob");
                                                    }}
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            ) : (
                                                <FormikInput
                                                    formEl={formEl}
                                                    errors={formikProps.errors}
                                                    touched={formikProps.touched}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            })}
                            <div className="col-12 mt-3 mb-4">
                                <hr />
                            </div>
                            {this.state.pri_insurance_schema && (
                                <>
                                    <ProviderReferralHOC
                                        className="ProviderReferralHOC"
                                        isPrimaryInsurance
                                        formikProps={formikProps}
                                        firstName={formikProps.values.first_name}
                                        lastName={formikProps.values.last_name}
                                        //   files={fileNames}
                                        insuranceCompanyOptions={this.state.carriersOptions}
                                        onBrowseButtonClicked={() =>
                                            this.showInsuranceUploadPopUp("pri")
                                        }
                                    />
                                    <div className="col-12 my-1 my-lg-1">
                                        <hr />
                                    </div>
                                </>
                            )}
                            {this.state.pri_insurance_schema && (
                                // Field array is used to add secondary insurance.
                                <FieldArray
                                    name="insurance" // name = "insurance must be same as define in initialValues schema of formik"
                                    render={(arrayHelper) => {
                                        return (
                                            <Fragment>
                                                {/*  Pick PCP section */}
                                                {this.state.patient_pcp_schema &&
                                                    this.state.patient_pcp_schema.map(
                                                        (formEl, i) => {
                                                            if (formEl.elementType == "segment") {
                                                                // form section heading
                                                                return (
                                                                    <h6
                                                                        key={formEl.displayName}
                                                                        className="col-12">
                                                                        <b>{formEl.displayName}</b>
                                                                    </h6>
                                                                );
                                                            } else {
                                                                // Normal input fields
                                                                return (
                                                                    <div
                                                                        key={formEl.name}
                                                                        className={
                                                                            formEl.elementType ==
                                                                            "textarea"
                                                                                ? "col-12"
                                                                                : "col-12 col-lg-6"
                                                                        }>
                                                                        <FormikInput
                                                                            formEl={formEl}
                                                                            {...formikProps}
                                                                            touched={
                                                                                formikProps.touched
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        },
                                                    )}

                                                <div className="col-12 mt-lg-2 mb-lg-3 mt-1 mb-3">
                                                    <hr />
                                                </div>

                                                {!this.state.isPracticeSPMI &&
                                                    (!this.state.patientDetails ||
                                                        this.state.screeningId) && (
                                                        <div
                                                            className={"col-12 col-lg-6 mt-1 mb-4"}>
                                                            <div className={"fw-bold w-100"}>
                                                                Medication Management Services
                                                            </div>
                                                            <div className={"w-100"}>
                                                                <ReactSelect
                                                                    isMulti
                                                                    className={
                                                                        "basic-multi-select w-100"
                                                                    }
                                                                    classNamePrefix="select"
                                                                    options={Object.entries(
                                                                        MED_MGMT_OPTIONS,
                                                                    )
                                                                        .map(([key, value]) => {
                                                                            return {
                                                                                value: key,
                                                                                label: value.label,
                                                                                order: value.order,
                                                                            };
                                                                        })
                                                                        .sort(
                                                                            (a, b) =>
                                                                                a.order - b.order,
                                                                        )}
                                                                    onChange={(selectedOptions) =>
                                                                        formikProps.setFieldValue(
                                                                            "medication_management_services",
                                                                            selectedOptions,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                {!this.state.patientId && (
                                                    <div className="col-12 col-lg-12">
                                                        <Label>Associated Files</Label>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <Button
                                                                type="button"
                                                                className="Btn Btn--pri Btn-sm"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        uploadAssociatedFile: true,
                                                                    })
                                                                }>
                                                                Upload
                                                            </Button>
                                                        </div>
                                                        <UploadFileList
                                                            files={this.state.associatedFiles}
                                                            onFileRemove={this.onFileFileDelete}
                                                            listStyle={"mb-3"}
                                                        />
                                                        {/*<div className="col-12 my-3"><hr /></div>*/}
                                                    </div>
                                                )}

                                                {(!this.state.patientDetails ||
                                                    this.state.screeningId) && (
                                                    <>
                                                        <div className="col-12 d-flex pl-4 ml-1 fs-15">
                                                            <b>*Please securely email</b>
                                                            <a
                                                                href="mailto:urgentreferral@aptihealth.com"
                                                                className={"mx-1 link"}
                                                                target="_blank"
                                                                rel="noreferrer">
                                                                urgentreferral@aptihealth.com{" "}
                                                            </a>
                                                            <b>
                                                                {" "}
                                                                to briefly communicate details about
                                                                a complex or urgent referral.
                                                            </b>
                                                        </div>
                                                    </>
                                                )}

                                                {this.state.uploadAssociatedFile && (
                                                    <UploadFile
                                                        hideModal={() =>
                                                            this.setState({
                                                                uploadAssociatedFile: false,
                                                            })
                                                        }
                                                        files={this.state.associatedFiles}
                                                        onInputChange={this.onFileInputChange}
                                                        handleDrop={this.onFileInputChange}
                                                        onFileRemove={this.onFileFileDelete}
                                                    />
                                                )}
                                            </Fragment>
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end mt-5">
                    <Button
                        type="button"
                        onClick={() => this.props.history.go(-1)}
                        className="Btn Btn--otl-pri Btn-sm mr-3">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        {!this.state.patientId ? "Add Member" : "Update"}
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        let validationSchema = PATIENT_VALIDATION_SCHEMA;

        if (isUserAdmin() || !this.state.patientId) {
            validationSchema = VALIDATION_SCHEMA();
        }
        return (
            <div>
                {this.state.showInsuranceCard && (
                    <UploadFileModalHOC
                        showInsuranceCard={this.state.showInsuranceCard}
                        insuranceHide={this.hideInsuranceUploadPopUp}
                        onFrontInputChange={this.frInpHandler}
                        onBackInputChange={this.bckInpHandler}
                        done={this.onInsuranceDone}
                    />
                )}
                <CardPrimary className="mx-auto">
                    <CardHeading
                        text={!this.state.patientId ? "Add Member" : "Edit Member Details"}
                        className="fs-18 pt-2 pb-3 d-none d-lg-block"
                    />
                    <hr className="d-none d-lg-block" />
                    <div className="SignUp--step3">
                        {this.state.isLoaded && (
                            <>
                                {!this.state.patientDetails && (
                                    <CustomForm
                                        initialValues={this.state.initialFormValues}
                                        validationSchema={validationSchema}
                                        onSubmit={this.submitHandler}
                                        render={this.renderForm}
                                    />
                                )}
                                {this.state.patientDetails && (
                                    <CustomForm
                                        initialValues={this.state.patientDetails}
                                        validationSchema={validationSchema}
                                        onSubmit={this.submitHandler}
                                        render={this.renderForm}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </CardPrimary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { addNewPatient, addToast })(Patient);
