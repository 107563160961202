import React from "react";
import { Form } from "formik";
import FormikInput from "../../../../UI/FormikInput";
import Button from "../../../../UI/Button";
import * as yup from "yup";
import { mergeObjectOnlyExistingFields } from "../../../../../utils/objectUtils";
import { CustomForm } from "../../../../../component-library";
import {
    PREFERRED_NAME_SCHEMA_PATIENT,
    PREFERRED_PRONOUNS_SCHEMA,
} from "../../../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import { COMPLETE_PROFILE_STEPS } from "./index";

export const CompletePreferredName = (props) => {
    const { submitHandler, profile, closeModal, setCurrentCompleteProfileStep } = props;

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <div className={"col-12"}>
                            <FormikInput
                                key={PREFERRED_NAME_SCHEMA_PATIENT.name}
                                {...formikInputProps}
                                formEl={PREFERRED_NAME_SCHEMA_PATIENT}
                            />
                        </div>
                        <div className={"col-12"}>
                            <FormikInput
                                key={PREFERRED_PRONOUNS_SCHEMA.name}
                                {...formikInputProps}
                                formEl={PREFERRED_PRONOUNS_SCHEMA}
                            />
                        </div>
                    </div>
                    <div className="px-0 row">
                        <div className="d-flex justify-content-center w-100">
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri self-signup-btn m-auto"
                                onClick={() =>
                                    setCurrentCompleteProfileStep(
                                        COMPLETE_PROFILE_STEPS.EMERGENCY_CONTACT,
                                    )
                                }>
                                Back
                            </Button>
                            <Button type="submit" className="Btn Btn--pri self-signup-btn m-auto">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <CustomForm
                initialValues={mergeObjectOnlyExistingFields(ADDRESS_INITIAL_VALUES, profile)}
                validationSchema={preferredNameSchema}
                onSubmit={(formData, actions) => {
                    submitHandler(formData, actions);
                    closeModal();
                }}
                render={renderForm}
            />
        </>
    );
};

const ADDRESS_INITIAL_VALUES = {
    username: "",
    preferred_name: "",
    preferred_pronouns: "",
};

const preferredNameSchema = yup.object().shape({
    preferred_name: yup
        .string()
        .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed")
        .nullable(),
    preferred_pronouns: yup.string().nullable(),
});
