// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import InstantCall from "../InstantCall";
import ScheduleWidget from "./Widget/ScheduleWidget";
import moment from "moment-timezone";
import NextSessionWidget from "./Widget/NextSessionWidget";
import TasksWidget from "./Widget/TasksWidget";
import MessagesWidget from "./Widget/MessagesWidget";
import CareTeamWidget from "./Widget/CareTeamWidget";
import FamilyMembersWidget from "./Widget/FamilyMembersWidget";
import { DIAGNOSTIC_INTERVIEW_90791 } from "../../Provider/ScheduleAvailability/constants";
import { api } from "../../../APIRequests";
import NextStepWidget from "./Widget/NextStepWidget";
import { connect, useSelector } from "react-redux";
import {
    disableLoginPopUps,
    fetchActivityList,
    updateScheduleList,
} from "../../../redux/actions/patient";
import { getUsernameFromToken, updateReduxProfile } from "../../../redux/actions/auth";
import AccomplishmentsWidget, {
    buildPatientAccomplishmentMilestones,
    buildPatientAccomplishmentSessions,
} from "./Widget/AccomplishmentsWidget";
import { redirectOnSignIn } from "../../../utils/redirectOnSignIn";
import { Portal } from "../../Common/Modal/Portal";
import _ from "lodash";
import { DIReminderWidget } from "./Widget/DIReminderWidget";
import { showAlertWithActionAndCustomButton } from "../../../redux/actions/alerts";
import { bindActionCreators } from "redux";
import HCPAutoCancelDialog from "../../../containers/Dashboard/Patient/HCPAutoCancelDialog";
import { InactiveModal } from "./InactiveModal";
import { WaitListModal } from "./WaitListModal";
import { PatientPaymentBanner } from "../../../component-library";

const isInactive = (profile) => {
    return profile && profile.status === "INACTIVE";
};

const isWaitListed = (profile) => {
    return profile?.is_wait_listed === true;
};

const hasAcknowledgedHCPDI = (profile) => {
    return _.get(profile, "acknowledgements.hcp_di_reminder", false);
};

export const shouldShowCompleteScreeningTask = (profile) => {
    const { tasks } = profile;
    const screeningTask = tasks?.find((element) => element.task_type === "A5_ASSESSMENT");
    return screeningTask !== undefined && profile.status !== "INACTIVE";
};

export const shouldUpdateInsurance = (profile) => {
    const insurance_status = _.get(profile, "chc_info.status");
    const show_insurance_task = ["declined", "no_match"];
    return show_insurance_task.includes(insurance_status);
};

const shouldShowHCPDIModal = (profile) => {
    return (
        profile &&
        profile.group_type === "hcp_program" &&
        profile.schedule_video_call &&
        !hasAcknowledgedHCPDI(profile) &&
        !isInactive(profile)
    );
};

const shouldShowInactiveModal = (profile) => {
    return isInactive(profile);
};

const shouldShowWaitListModal = (profile) => {
    return isWaitListed(profile);
};

const shouldShowFamilyMembersWidget = (profile) => {
    return !!(
        _.get(profile, "enriched_associated_wards", []).length ||
        _.get(profile, "enriched_associated_guardians", []).length
    );
};

const MemberHome = (props) => {
    const {
        dispatch,
        profile,
        scheduleList,
        totalUnreadMessages,
        daysUntilPasswordReset,
        allowInactivePopUp,
        allowWaitListPopUp,
    } = props;
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);
    const showPaywall = useSelector((state) => state.patientCardInfo?.showPaywall);

    const [sendBirdContacts, setSendBirdContacts] = useState(null);
    const [sendBirdClient, setSendBirdClient] = useState(null);
    const [accomplishments, setAccomplishments] = useState(null);
    const [showAutoCancelledSession, setShowAutoCancelledSession] = useState(false);
    const [autoCancelledSession, setAutoCancelledSession] = useState(null);

    const processSendBirdChannel = (user, channel) => {
        channel.otherMembers = channel.members.filter((member) => member.userId !== user.user_id);

        channel.otherMembersMap = channel.otherMembers.reduce((map, member) => {
            map[member.userId] = member;
            map[member.userId]["unreadMessageCount"] = channel.unreadMessageCount;
            map[member.userId]["lastMessage"] = channel.lastMessage;
            return map;
        }, {});
        return channel;
    };

    const processSendBirdMembers = (map, channel) => {
        Object.entries(channel.otherMembersMap).map(([userId, member]) => {
            map[userId] = member;
            map[userId]["unreadMessageCount"] = member.unreadMessageCount;
            map[userId]["lastMessage"] = channel.lastMessage;
            map[userId]["group"] = member.metaData.group;
            // If a member is assigned a prescriber, but the prescriber has not messaged the member previously,
            // we should not display the prescriber name here
            map[userId]["shouldShow"] =
                channel.lastMessage || member.metaData.group !== "provider:prescribe";
        });
        return map;
    };

    const getActivities = async (type) => {
        const options = { params: { showLoader: false } };
        const urlParams = { type };
        return await fetchActivityList({ options, urlParams, dispatch });
    };

    function fetchSendBirdData() {
        try {
            (async () => {
                const options = { params: { showLoader: false } };
                let user = await api.messaging.fetch_user({ options });
                let client = api.messaging.create_client();
                setSendBirdClient(client);
                await api.messaging.connect_to_sendbird(client, user.user_id, user.access_token);
                const { channels } = await api.messaging.get_paginated_channels(client);
                const contacts = channels
                    .map((channel) => processSendBirdChannel(user, channel))
                    .reduce(processSendBirdMembers, {});
                if (contacts && Object.keys(contacts).length > 0) {
                    setSendBirdContacts(contacts);
                } else {
                    setSendBirdContacts({});
                }
            })();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        redirectOnSignIn(props.history);
        props.updateScheduleList();
    }, []);

    useEffect(() => {
        if (profile && profile.care_team_members) {
            fetchSendBirdData();
        }
        if (profile) {
            const fetchActivities = async () => {
                const completedSessions = await getActivities("schedule");
                setAccomplishments({
                    memberSessionActivities: buildPatientAccomplishmentSessions(completedSessions),
                    memberMilestoneActivities: buildPatientAccomplishmentMilestones(profile),
                });

                if (
                    profile.group_type === "hcp_program" &&
                    !("hcp_auto_cancellation" in profile.acknowledgements)
                ) {
                    const cancelledSessions = await getActivities("cancelled");
                    const autoCancelledSessionArray = cancelledSessions.filter(
                        (session) => session.key_content.is_auto_cancelled,
                    );
                    const autoCancelledSession =
                        autoCancelledSessionArray.length && autoCancelledSessionArray[0];
                    if (autoCancelledSession) {
                        setShowAutoCancelledSession(true);
                        setAutoCancelledSession(autoCancelledSession);
                    }
                }
            };
            fetchActivities();
        }

        return (_) => {
            if (sendBirdClient) {
                sendBirdClient.removeAllChannelHandlers();
                sendBirdClient.disconnect();
            }
        };
    }, [profile, totalUnreadMessages]);

    useEffect(() => {
        if (!(daysUntilPasswordReset == null)) {
            if (daysUntilPasswordReset <= 7) {
                props.showAlertWithActionAndCustomButton(
                    `For security best practices and your protection aptihealth requires you to change your password every 90 days. Your password is set to expire in ${daysUntilPasswordReset} days.`,
                    () => {
                        props.history.push("/app/change-password");
                    },
                    "Change Password",
                );
            }
        }
    }, [daysUntilPasswordReset]);

    const acknowledgedHCPDI = async () => {
        try {
            const acknowledgement = {
                date: moment.utc().toISOString(),
                client: "web",
                acknowledged_by: getUsernameFromToken(),
                timezone: moment.tz.guess(),
            };

            const data = {
                acknowledgement_name: "hcp_di_reminder",
                acknowledgement: acknowledgement,
            };

            const options = { params: { showLoader: false } };

            // TODO: replace in 5457
            // const updatedProfile = await api.patient.append_acknowledgement({ data, options });
            await api.patient.append_acknowledgement({ data, options });

            // profile is fetched here to correctly grab the tasks from the task service,
            // this will need to be replaced with a call to the task service in https://aptihealth.atlassian.net/browse/AT1-4676
            // created https://aptihealth.atlassian.net/browse/AT1-5457 to account for this
            const result = await api.auth.fetch_profile({ options: { showLoader: true } });

            // TODO: replace in 5457
            // props.updateReduxProfile(updatedProfile);
            props.updateReduxProfile(result.user);
        } catch (e) {
            console.log(e);
        }
    };

    const acknowledgeHCPAutoCancellation = async () => {
        try {
            const acknowledgement = {
                activity_id: autoCancelledSession.entity_id,
                date: moment.utc().toISOString(),
                client: "web",
                acknowledged_by: getUsernameFromToken(),
                timezone: moment.tz.guess(),
            };

            const data = {
                acknowledgement_name: "hcp_auto_cancellation",
                acknowledgement: acknowledgement,
            };

            const options = { params: { showLoader: false } };

            // TODO: replace in 5464
            // const updatedProfile = await api.patient.append_acknowledgement({ data, options });
            await api.patient.append_acknowledgement({ data, options });

            // profile is fetched here to correctly grab the tasks from the task service,
            // this will need to be replaced with a call to the task service in https://aptihealth.atlassian.net/browse/AT1-4676
            // created https://aptihealth.atlassian.net/browse/AT1-5464 to account for this
            const result = await api.auth.fetch_profile({ options: { showLoader: true } });

            // TODO: replace in 5464
            // props.updateReduxProfile(updatedProfile);
            props.updateReduxProfile(result.user);
        } catch (e) {
            console.log(e);
        }
    };

    const autoCancelledSessionDateTime =
        autoCancelledSession &&
        moment
            .utc(
                `${autoCancelledSession.key_content.call_date} ${autoCancelledSession.key_content.call_time}`,
            )
            .tz(moment.tz.guess());

    const shouldRenderInactiveLoginModal =
        profile && shouldShowInactiveModal(profile) && allowInactivePopUp;

    const shouldRenderWaitListLoginModal =
        profile &&
        !shouldRenderInactiveLoginModal &&
        shouldShowWaitListModal(profile) &&
        allowWaitListPopUp;

    const firstName = profile.preferred_name || profile.first_name;

    return (
        <div className="container px-3" data-public="true">
            {shouldRenderInactiveLoginModal && (
                <Portal
                    isBottomCloseActive={false}
                    isOpenOnRender={true}
                    className={"take-assessment-modal"}
                    headerRender={<h5 className="fw-bold border-bottom-0"></h5>}
                    onModalClose={() => props.disableLoginPopUps()}>
                    <InactiveModal />
                </Portal>
            )}

            {shouldRenderWaitListLoginModal && (
                <Portal
                    isBottomCloseActive={false}
                    isOpenOnRender={true}
                    className={"take-assessment-modal"}
                    headerRender={<h5 className="fw-bold border-bottom-0"></h5>}
                    onModalClose={() => props.disableLoginPopUps()}>
                    <WaitListModal />
                </Portal>
            )}

            {profile && scheduleList && scheduleList.length && shouldShowHCPDIModal(profile) ? (
                <Portal
                    isBottomCloseActive={false}
                    isOpenOnRender={true}
                    className={"take-assessment-modal"}
                    headerClass={"portal-grey-background"}
                    headerRender={
                        <div
                            className={
                                "d-flex flex-column align-items-center w-100 justify-content-center mt-2"
                            }>
                            <h5
                                style={{ color: "#0E3C51" }}
                                className="fw-bold border-bottom-0 text-align-center fs-lg-20 fs-14">
                                Welcome to aptihealth!
                            </h5>
                            <h5
                                style={{ color: "#0E3C51" }}
                                className="fw-bold border-bottom-0 text-align-center fs-lg-20 fs-14">
                                Your first session is coming up.
                            </h5>
                        </div>
                    }
                    onModalClose={acknowledgedHCPDI}>
                    <DIReminderWidget onSubmit={acknowledgedHCPDI} call={scheduleList[0]} />
                </Portal>
            ) : null}

            {profile &&
                !isInactive(profile) &&
                showAutoCancelledSession &&
                autoCancelledSession && (
                    <Portal
                        isBottomCloseActive={false}
                        isOpenOnRender={true}
                        className={"HCPAutoCancelDialog__modal"}
                        onModalClose={acknowledgeHCPAutoCancellation}
                        headerRender={
                            <h2 className="HCPAutoCancelDialog__title">Welcome to aptihealth!</h2>
                        }>
                        <HCPAutoCancelDialog
                            scheduleHandler={() => {
                                acknowledgeHCPAutoCancellation();
                                props.history.push("/app/schedule/assign?mode=new");
                            }}
                        />
                    </Portal>
                )}

            {scheduleList && <InstantCall className={"NDCPG__container"} calls={scheduleList} />}

            {showPaywall && <PatientPaymentBanner />}

            <h1 className="mt-0 mb-3 pt-4 pb-1 order-lg-2 order-1 fs-32 txt-gry">
                <b data-private={"true"}>{firstName ? `Welcome, ${firstName}!` : ""}</b>
            </h1>

            {profile && !isInactive(profile) && (
                <div className="row">
                    <NextStepWidget {...props} />
                </div>
            )}

            {profile &&
                (profile.schedule_video_call_date_time ||
                    profile.ninety_day_care !== "not_assigned") && (
                    <div className="row">
                        <div className={"col-lg-4 col-12"}>
                            <NextSessionWidget scheduleList={scheduleList} />
                        </div>
                        <div className={"col-lg-4 col-12"}>
                            <TasksWidget {...props} />
                        </div>
                        <div className={"col-lg-4 d-md-block d-none"}>
                            <MessagesWidget {...props} sendBirdContacts={sendBirdContacts} />
                        </div>
                    </div>
                )}

            <div className="row mb-2">
                <div className={"col-lg-6 col-sm-12 col-md-6"}>
                    <ScheduleWidget {...props} />
                </div>
                <div className={"col-lg-6 col-sm-12 col-md-6"}>
                    <div className="fs-22 txt-gry fw-bold pb-2">Accomplishments</div>
                    <div className="pl-1">
                        <AccomplishmentsWidget {...accomplishments} />
                    </div>
                </div>
            </div>

            {profile && shouldShowFamilyMembersWidget(profile) && (
                <div>
                    <FamilyMembersWidget {...props} />
                </div>
            )}

            <div className="row pb-md-5">
                <div className={"col-12 pb-md-5 pb-0"}>
                    <CareTeamWidget {...props} sendBirdContacts={sendBirdContacts} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        daysUntilPasswordReset: state.auth.daysUntilPasswordReset,
        scheduleList: state.patient.scheduleList,
        showEmailVerified: state.auth.showEmailVerified,
        allowInactivePopUp: state.patient.allowInactivePopUp,
        allowWaitListPopUp: state.patient.allowWaitListPopUp,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                updateScheduleList,
                updateReduxProfile,
                showAlertWithActionAndCustomButton,
                fetchActivityList,
                disableLoginPopUps,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberHome));

export const buildCallTitle = (providerRole, event_type) => {
    let sessionType;
    if (event_type === DIAGNOSTIC_INTERVIEW_90791) {
        sessionType = "Therapy Consultation";
    } else if (providerRole === "PRESCRIBE") {
        sessionType = "Medication Management Session";
    } else {
        sessionType = "Therapy Session";
    }
    return `${sessionType}`;
};

export const buildCallDate = (timestamp) => {
    const localTimestamp = moment.utc(timestamp, "YYYY-MM-DD HH:mm").local();
    const dayOfWeek = localTimestamp.isSame(moment(), "day")
        ? "Today"
        : localTimestamp.format("dddd");
    const startDateTime = localTimestamp.format("MMMM D");
    return `${dayOfWeek}, ${startDateTime}`;
};

export const buildCallTimeInfo = (timestamp, allottedTime) => {
    const localTimestamp = moment.utc(timestamp, "YYYY-MM-DD HH:mm").local();
    const startTime = localTimestamp.format("h:mm a");
    const endTime = localTimestamp.add(allottedTime, "minutes").format("h:mm a");
    return `${startTime} - ${endTime}`;
};
