import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";

let privateInterval = undefined;
let publicInterval = undefined;

const flagsLoaded = (flags) => {
    return {
        type: actionTypes.FLAGS_LOADED,
        ...flags,
    };
};

const flagIntervalReset = (flags) => {
    return {
        type: actionTypes.CLEAR_FLAG_INTERVALS,
        flags: { ...flags },
    };
};

export const loadFlags = () => async (dispatch) => {
    getFeatureFlagsPub()
        .then((transformedConfigs) => {
            dispatch(flagsLoaded({ publicFlags: transformedConfigs }));
        })
        .catch((e) => {
            console.log(e);
            const defaultFlags = PUBLIC_FEATURE_FLAG_DEFAULTS;
            console.log("Error retrieving feature flags, using defaults", defaultFlags);
            dispatch(flagsLoaded({ publicFlags: defaultFlags }));
        });

    publicInterval = setInterval(async () => {
        if (document.hidden) {
            console.log("Document is currently hidden. Waiting to fetch public feature flags.");
            return;
        }
        getFeatureFlagsPub().then((transformedConfigs) => {
            dispatch(flagsLoaded({ publicFlags: transformedConfigs }));
        });
    }, 300000);
};

export const loadTargetedFlags = (target) => async (dispatch) => {
    getFeatureFlagsPrivate(target)
        .then((transformedConfigs) => {
            dispatch(flagsLoaded({ privateFlags: transformedConfigs }));
        })
        .catch((e) => {
            console.log(e);
            const defaultFlags = PRIVATE_FEATURE_FLAG_DEFAULTS;
            console.log("Error retrieving feature flags, using defaults", defaultFlags);
            dispatch(flagsLoaded({ privateFlags: defaultFlags }));
        });

    privateInterval = setInterval(async () => {
        if (document.hidden) {
            console.log("Document is currently hidden. Waiting to fetch private feature flags.");
            return;
        }
        getFeatureFlagsPrivate(target).then((transformedConfigs) => {
            dispatch(flagsLoaded({ privateFlags: transformedConfigs }));
        });
    }, 300000);
};

export const clearFlagInterval = () => async (dispatch) => {
    clearInterval(privateInterval);
    dispatch(flagIntervalReset());
};

const getFeatureFlagsPub = async () => {
    const data = { flag_set: "WEB_PUBLIC_FLAG_NAMES" };
    const options = { params: { showLoader: false } };
    return api.featureFlag.get_feature_flag_set({ data, options });
};

const getFeatureFlagsPrivate = async (target) => {
    const data = { flag_set: "WEB_PRIVATE_FLAG_NAMES" };
    const options = { params: { showLoader: false } };
    return api.featureFlag.get_all_targetted_feature_flags({ data, options });
};

/**
 *
 * !!! Remember to update defaults when adding or removing feature flags or updating
 * WEB_PRIVATE_FLAG_NAMES and WEB_PUBLIC_FLAG_NAMES.
 *
 */

const PUBLIC_FEATURE_FLAG_DEFAULTS = {
    OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG: true,
    OPERATIONAL_OVERRIDE_RECAPTCHA: false,
};

const PRIVATE_FEATURE_FLAG_DEFAULTS = {
    UPDATED_CALENDAR_NPS: true,
    OPERATIONAL_RESTRICT_PATIENT_SCHEDULING: true,
    OPERATIONAL_MANUAL_PAYMENT_PROCESSING: false,
};
