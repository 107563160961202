export const UPDATED_CALENDAR_NPS_FLAG = "UPDATED_CALENDAR_NPS";
export const OPERATIONAL_RESTRICT_PATIENT_SCHEDULING = "OPERATIONAL_RESTRICT_PATIENT_SCHEDULING";
export const OPERATIONAL_MANUAL_PAYMENT_PROCESSING = "OPERATIONAL_MANUAL_PAYMENT_PROCESSING";
export const OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG =
    "OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG";

export const OPERATIONAL_OVERRIDE_RECAPTCHA = "OPERATIONAL_OVERRIDE_RECAPTCHA";

/**
 *
 * !!! Remember to update WEB_PRIVATE_FLAG_NAMES and WEB_PUBLIC_FLAG_NAMES when adding or removing.
 *
 */
