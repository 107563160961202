import React, { useState } from "react";
import _ from "lodash";

import { MemberProfileInsurance } from "../../MemberProfileInsurance";
import { transformDOBsToServerStrings } from "../../../components/Patient/Profile/InsuranceInfo";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../constants/insurnaceIconMap";
import { InsuranceAcknowledgementPopupHOC } from "../InsuranceAcknowledgementPopupHOC";
import {
    CARRIER_TO_INSURANCE_POLICY_NAME,
    createAcknowledgement,
} from "../../../utils/userAgreementUtil";
import { isUserPatient } from "../../../redux/actions/auth";
import { getCarrierMetadata } from "../../../components/Common/InsuranceEligibility/carrierMetadata";

export const POLICY_HOLDER_OPTIONS = [
    { value: "Self", label: "Self" },
    { value: "Spouse", label: "Spouse" },
    { value: "Child", label: "Child" },
    { value: "Other", label: "Other" },
];

export const ProviderMemberProfileInsuranceHOC = ({
    className,
    isPrimaryInsurance,
    isEmpty,
    isEditing,
    onEditButtonClick,
    onAddButtonClick,

    // -- Empty Props
    emptyTitle,
    emptyDetails,

    // -- Edit Props
    editTitle,
    onCancelButtonClick,
    onSubmitButtonClick,

    //NEW STUFF
    firstName,
    lastName,
    insurance,

    // Formik props
    formikProps,

    // Select options
    insuranceCompaniesOptions,
    stateOfInsurancePlanOptions,
    policyHolderOptions,

    // Images
    files,
    onBrowseButtonClicked,
    onFileDelete,

    // -- Completed Props
    completedTitle,

    insuranceCompany,
    policyHolder,
    memberId,
    groupId,
    stateOfInsurancePlan,
    policyHolderName,
    policyHolderDOB,

    insuranceCardImgFrontSrc,
    insuranceCardImgFrontStatus,
    insuranceCardImgBackSrc,
    insuranceCardImgBackStatus,
    onInsuranceCardImgFrontClick,
    onInsuranceCardImgBackClick,

    // Policy Acknowledgements
    addAcknowledgement,

    ...props
}) => {
    const { handleChange, handleBlur, handleFocus, values, errors, touched } = formikProps;
    const { memberIdPlaceholder, memberIdTip, memberIdTooltipContents, groupIdTooltipContents } =
        getCarrierMetadata(_.get(values, "carrier"));
    const [noInsurance, setNoInsurance] = useState(
        CATCH_ALL_INSURANCE_OPTIONS.includes(_.get(values, "carrier")),
    );
    const [showAcknowledgementPopup, setShowAcknowledgementPopup] = useState(false);

    const mapDropDownValue = (value) => ({
        label: value,
        value: value,
    });

    const mappedCompletedValues = {
        insuranceCompany: _.get(values, "carrier"),
        policyHolder: _.get(values, "policy_relationship"),
        memberId: _.get(values, "member_id"),
        groupId: _.get(values, "group_id"),
        policyHolderDOB: _.get(values, "policy_dob"),
        stateOfInsurance: _.get(values, "state"),

        policyHolderName: _.get(values, "policy_holder_name"),
        insuranceCardImgFrontSrc: _.get(values, "card_front"),
        insuranceCardImgBackSrc: _.get(values, "card_back"),
    };

    const mappedEditValues = {
        insuranceCompany: mapDropDownValue(_.get(values, "carrier")),
        policyHolder: mapDropDownValue(_.get(values, "policy_relationship")),
        stateOfInsurance: mapDropDownValue(_.get(values, "state")),
        memberId: _.get(values, "member_id"),
        groupId: _.get(values, "group_id"),
        policyHolderDOB: _.get(values, "policy_dob"),

        policyHolderName: _.get(values, "policy_holder_name"),
        insuranceCardImgFrontSrc: _.get(values, "card_front"),
        insuranceCardImgBackSrc: _.get(values, "card_back"),
    };

    const mappedNames = {
        insuranceCompany: "carrier",
        policyHolder: "policy_relationship",
        policyHolderName: "policy_holder_name",
        memberId: "member_id",
        groupId: "group_id",
        insuranceFront: "card_front",
        insuranceBack: "card_back",
        policyHolderDOB: "policy_dob",
        stateOfInsurance: "state",
    };

    const mappedTouched = {
        insuranceCompany: _.get(touched, "carrier") || _.get(touched, "insuranceCompany"),
        policyHolder: _.get(touched, "policy_relationship") || _.get(touched, "policyHolder"),
        memberId: _.get(touched, "member_id") || _.get(touched, "memberId"),
        groupId: _.get(touched, "group_id") || _.get(touched, "groupId"),
        policyHolderDOB: _.get(touched, "policy_dob") || _.get(touched, "policyHolderDOB"),
        stateOfInsurance: _.get(touched, "state") || _.get(touched, "stateOfInsurance"),
        policyHolderName:
            _.get(touched, "policy_holder_name") || _.get(touched, "policyHolderName"),
    };

    const mappedErrors = {
        insuranceCompany: _.get(errors, "carrier"),
        policyHolder: _.get(errors, "policy_relationship"),
        memberId: _.get(errors, "member_id"),
        groupId: _.get(errors, "group_id"),
        policyHolderDOB: _.get(errors, "policy_dob"),
        stateOfInsurance: _.get(errors, "state"),
        policyHolderName: _.get(errors, "policy_holder_name"),
    };

    const mappedChangeHandler = (e) => {
        let name, value;
        if (!_.get(e, "target")) {
            name = "policyHolderDOB";
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        const mappedName = mappedNames[name];
        let mappedValue = value;

        switch (name) {
            case "insuranceCompany":
                if (CATCH_ALL_INSURANCE_OPTIONS.includes(value)) {
                    resetInsurance();
                    setNoInsurance(true);
                } else {
                    setNoInsurance(false);
                }
                break;
        }

        handleChange({ ...e, target: { ...e.target, name: mappedName, value: mappedValue } });
    };

    const resetInsurance = () => {
        for (const name in mappedNames) {
            if (name === "insuranceCompany") {
                continue;
            }
            formikProps.setFieldValue(mappedNames[name], "");
        }
    };

    const isInsuranceEmpty = (insurance) => {
        //No Value
        if (!insurance) {
            return true;
        }
        //Empty Object
        if (insurance === {}) {
            return true;
        }

        if (isMemberIdEmpty(insurance) && isCarrierEmpty(insurance)) {
            return true;
        }

        //Object with null or empty strings but "None" in string of image fields
        return false;
    };

    const isMemberIdEmpty = (insurance) => {
        //Object with all null values
        if (!_.get(insurance, "member_id")) {
            return true;
        }
        //Object with all empty string values
        if (_.get(insurance, "member_id") === "") {
            return true;
        }
        //Object with all a string value of None (Legacy)
        if (_.get(insurance, "member_id") === "None") {
            return true;
        }
    };

    const isCarrierEmpty = (insurance) => {
        //Object with all null values
        if (!_.get(insurance, "carrier")) {
            return true;
        }
        //Object with all empty string values
        if (_.get(insurance, "carrier") === "") {
            return true;
        }
        //Object with all a string value of None (Legacy)
        if (_.get(insurance, "carrier") === "None") {
            return true;
        }
    };

    const sanitizeFileNames = (fileName) => {
        if (fileName && fileName.includes("backend/insurance_cards/")) {
            return fileName.replace("backend/insurance_cards/", "");
        }

        return fileName;
    };

    const getFiles = () => {
        const files = [];
        let frontImg = mappedCompletedValues.insuranceCardImgFrontSrc;
        let backImg = mappedCompletedValues.insuranceCardImgBackSrc;

        if (frontImg && frontImg.includes("None")) {
            frontImg = null;
        }

        if (backImg && backImg.includes("None")) {
            backImg = null;
        }

        if (frontImg) {
            files.push(sanitizeFileNames(frontImg));
        }

        if (backImg) {
            files.push(sanitizeFileNames(backImg));
        }

        return files;
    };

    if (mappedCompletedValues.policyHolder) {
        mappedCompletedValues.policyHolder =
            mappedCompletedValues.policyHolder[0].toUpperCase() +
            mappedCompletedValues.policyHolder.slice(1);
    }

    const submitAndCloseAcknowledgement = async () => {
        const policyName = CARRIER_TO_INSURANCE_POLICY_NAME[mappedCompletedValues.insuranceCompany];
        const acknowledgement = await createAcknowledgement();
        setShowAcknowledgementPopup(false);
        addAcknowledgement(policyName, acknowledgement);
        onSubmitButtonClick();
    };

    const submitOrOpenAcknowledgement = () => {
        if (noInsurance && !showAcknowledgementPopup && isUserPatient()) {
            setShowAcknowledgementPopup(true);
        } else if (noInsurance && !isUserPatient()) {
            const policyName =
                CARRIER_TO_INSURANCE_POLICY_NAME[mappedCompletedValues.insuranceCompany];
            addAcknowledgement(policyName, null);
            return onSubmitButtonClick();
        } else {
            return onSubmitButtonClick();
        }
    };

    return (
        <>
            <MemberProfileInsurance
                className={className}
                isEmpty={isInsuranceEmpty(insurance)} //
                isEditing={isEditing} //
                onEditButtonClick={onEditButtonClick} //
                onAddButtonClick={onAddButtonClick} //
                // -- Empty Props
                emptyTitle={emptyTitle} //
                emptyDetails={emptyDetails} //
                // -- Edit Props
                editTitle={editTitle} //
                onCancelButtonClick={onCancelButtonClick} //
                onSubmitButtonClick={submitOrOpenAcknowledgement}
                // Formik props
                values={mappedEditValues}
                errors={mappedErrors}
                touched={mappedTouched}
                handleChange={mappedChangeHandler}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                insuranceCompaniesOptions={insuranceCompaniesOptions}
                stateOfInsurancePlanOptions={stateOfInsurancePlanOptions}
                policyHolderOptions={POLICY_HOLDER_OPTIONS}
                files={getFiles()}
                onBrowseButtonClicked={onBrowseButtonClicked}
                onFileDelete={onFileDelete}
                // -- Completed Props
                completedTitle={completedTitle}
                insuranceCompany={mappedCompletedValues.insuranceCompany}
                policyHolder={mappedCompletedValues.policyHolder}
                memberId={mappedCompletedValues.memberId}
                groupId={mappedCompletedValues.groupId}
                stateOfInsurancePlan={mappedCompletedValues.stateOfInsurance}
                policyHolderName={mappedCompletedValues.policyHolderName}
                policyHolderDOB={transformDOBsToServerStrings(
                    mappedCompletedValues.policyHolderDOB,
                )}
                insuranceCardImgFrontSrc={insuranceCardImgFrontSrc}
                insuranceCardImgFrontStatus={insuranceCardImgFrontStatus}
                insuranceCardImgBackSrc={insuranceCardImgBackSrc}
                insuranceCardImgBackStatus={insuranceCardImgBackStatus}
                onInsuranceCardImgFrontClick={onInsuranceCardImgFrontClick}
                onInsuranceCardImgBackClick={onInsuranceCardImgBackClick}
                memberIdPlaceholder={memberIdPlaceholder}
                memberIdTip={memberIdTip}
                memberIdTooltipContents={memberIdTooltipContents}
                groupIdTooltipContents={groupIdTooltipContents}
                noInsurance={noInsurance}
                {...props}
            />
            {showAcknowledgementPopup && (
                <InsuranceAcknowledgementPopupHOC
                    gaEventPrefix={"member-settings"}
                    acknowledgementType={mappedCompletedValues.insuranceCompany}
                    disabled={!showAcknowledgementPopup}
                    primaryOnClick={submitAndCloseAcknowledgement}
                    secondaryOnClick={() => setShowAcknowledgementPopup(false)}
                />
            )}
        </>
    );
};
