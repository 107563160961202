import { Button, ButtonSizes, ButtonTypes, CustomForm, Modal } from "../../../../component-library";
import { CloseIcon } from "../../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { FormTextArea } from "../../../../component-library/FormFields/FormTextArea/FormTextArea";
import { api } from "../../../../APIRequests";
import { hideLoader, showLoader } from "../../../../redux/actions/loader";
import { useDispatch } from "react-redux";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";
import CloseOnOutsideClick from "../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { XSS_REGEX } from "../../../../constants/security";
import { CharacterCounter } from "../../../../component-library/CharacterCounter/CharacterCounter";
import { isAuthorized } from "../../../../redux/actions/auth";
import {
    admin_cse,
    provider_behavioral,
    provider_case_manager,
    provider_peer,
    provider_prescribe,
} from "../../../../config/roles";
import React, { useState } from "react";
import * as yup from "yup";
import { Form } from "formik";

export const ReportAcuity = (props) => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const onModalSubmission = async (reportReason) => {
        dispatch(showLoader(true));
        const response = await api.patient.report_acuity({
            data: {
                patient_id: props.patientId,
                report_reason: reportReason,
            },
            params: { showLoader: true },
        });
        dispatch(hideLoader());
        closeModal();

        dispatch(
            addToast({
                message: response?.message || "Acuity reported successfully",
                messageType: toastMessageTypes.success_v2,
                dismissTimeout: 1000,
            }),
        );
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const isAuthorizedToReportAcuity = () => {
        return (
            isAuthorized(admin_cse) ||
            isAuthorized(provider_prescribe) ||
            isAuthorized(provider_behavioral) ||
            isAuthorized(provider_peer) ||
            isAuthorized(provider_case_manager)
        );
    };

    return (
        <>
            {isAuthorizedToReportAcuity() && (
                <span className={"ReportAcuity--report-link"} onClick={() => setShowModal(true)}>
                    Report Acuity
                </span>
            )}
            {showModal && (
                <ReportAcuityModal
                    show={showModal}
                    onClose={closeModal}
                    onModalSubmission={onModalSubmission}
                />
            )}
        </>
    );
};

const ReportAcuityModal = (props) => {
    return (
        <Modal show={props.show} cardClassName={"ReportAcuity--modal-dialog"}>
            <CloseOnOutsideClick setShowComponent={props.onClose}>
                <CloseIcon
                    onClick={props.onClose}
                    className={"ReportAcuity--modal-close-icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <ReportAcuityForm
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                />
            </CloseOnOutsideClick>
        </Modal>
    );
};

const ReportAcuityForm = (props) => {
    const maxCharacters = 4000;
    const characterCountExceededError = `Limit text to ${maxCharacters} characters`;

    const reportReasonSchema = yup.object().shape({
        reportReason: yup
            .string()
            .required("Report reason is required")
            .max(maxCharacters, characterCountExceededError)
            .test("special characters", "Special characters are not allowed", (value) => {
                return !XSS_REGEX.test(value);
            }),
    });

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className={"ReportAcuity--modal-form"}>
                    <div className={"FormField-header_container"}>
                        <p className={"ReportAcuity--modal-subtitle"}>
                            If this acuity score doesn’t seem right, you can flag it for review.
                            While the score won’t change in the platform, your feedback will help us
                            improve how acuity is determined in the future.
                        </p>
                    </div>

                    <div>
                        <p className={"ReportAcuity--input-description"}>
                            Explain why you believe this score is incorrect, including specific
                            clinical reasons.
                        </p>
                        <FormTextArea
                            inputClassName={"ReportAcuity--modal-free-text-input"}
                            name={"reportReason"}
                            formikProps={formikProps}
                            triggerErrorOnChange
                            placeholder={"Specify..."}
                        />
                        {formikProps.errors?.reportReason === characterCountExceededError && (
                            <CharacterCounter
                                className={"ReportAcuity--input-character-counter"}
                                length={formikProps.values?.reportReason?.length}
                                characterLimit={maxCharacters}
                            />
                        )}
                    </div>

                    <div className={"ReportAcuity-button-wrapper"}>
                        <Button
                            type={"submit"}
                            buttonSize={ButtonSizes.medium}
                            buttonType={ButtonTypes.primaryV2}>
                            Report Acuity Score
                        </Button>
                        <Button
                            type={"button"}
                            onClick={props.onClose}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.medium}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className={"ReportAcuity--form-container"}>
            <h1 className={"ReportAcuity--modal-title"}>Report Acuity Score</h1>
            <CustomForm
                initialValues={{ reportReason: "" }}
                validationSchema={reportReasonSchema}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    await props.onModalSubmission(formData.reportReason);
                }}
            />
        </div>
    );
};
