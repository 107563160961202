import CardSecondary from "../../../../UI/Card/CardSecondary";
import images from "../../../../../utils/images";
import { formatMobileNumber, toTitleCase } from "../../../../../utils/filters";
import { calculateAge } from "../../../../../utils/momentUtils";
import MemberProfilePatientDiagnosis from "../../../../Provider/PatientView/MemberProfile/MemberProfilePatientDiagnosis";
import { ExternalLinkIcon } from "../../../../../component-library";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { withRouter } from "react-router-dom";
import React from "react";

const patientInfoWidget = (props) => {
    const { patientDetails, isLarger, patientDiagnosis } = props;

    return (
        <div className="GuidedVideoCall__patient_info d-lg-block d-md-block d-block">
            <CardSecondary
                className="pt-3 px-3 mt-3 ml-auto mr-auto w-100 color-black "
                style={{ borderRadius: 10 }}>
                <div className={`row ${!isLarger && "flex-nowrap"}`}>
                    <div className={`${isLarger ? "col-lg-3" : "col-lg-auto"}  col-12 p-2`}>
                        <div className="row">
                            <div className={`${isLarger ? "col-3 mx-1" : "mx-3"}`}>
                                <img
                                    src={
                                        patientDetails.avatar
                                            ? patientDetails.avatar
                                            : images("./common/avatar.png")
                                    }
                                    width="30"
                                    style={{ marginLeft: 10, marginTop: 5 }}
                                    alt="Avatar"
                                />
                            </div>
                            <div className={`${isLarger ? "col-8" : "mr-5"}`}>
                                <div className="fw-bold fs-16">
                                    {patientDetails.first_name + " " + patientDetails.last_name}
                                    {patientDetails.preferred_name
                                        ? ` (${patientDetails.preferred_name})`
                                        : ""}
                                </div>
                                <div>
                                    {patientDetails.preferred_pronouns
                                        ? ` ${patientDetails.preferred_pronouns}`
                                        : ""}
                                </div>
                                <div>
                                    {patientDetails.mobile &&
                                        formatMobileNumber(patientDetails.mobile)}
                                </div>
                                <div>
                                    <div className="fs-16">
                                        Day {patientDetails.number_of_days_in_care} of care
                                    </div>
                                </div>
                                {!patientDetails.preferred_name && (
                                    <div
                                        onClick={() => {
                                            window.open(`/app/patient/${patientDetails.username}/profile/v2?selectedTab=Medical`, "_blank");
                                        }}>
                                        <span className="add-preferred-name-link">
                                            Add preferred name
                                        </span>
                                        <ExternalLinkIcon
                                            className="add-preferred-name-icon"
                                            iconType={ICON_TYPES.OUTLINE}
                                            iconSize={ICON_SIZES.SMALL}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 p-2 mb-2 left-border">
                        <div className="txt-sec fw-bold fs-14">Date of Birth</div>
                        <div>
                            {patientDetails.dob} ({calculateAge(patientDetails.dob)})
                        </div>
                    </div>
                    <div
                        className="col-lg-1 col-12 p-2 mb-2 left-border"
                        style={{ "min-width": "fit-content" }}>
                        <div className="txt-sec fw-bold fs-14">Gender</div>
                        <div>
                            {patientDetails.gender
                                ? toTitleCase(patientDetails.gender)
                                : "Not Specified"}
                        </div>
                    </div>

                    {isLarger && (
                        <div className="col-lg-2 col-12 p-2 mb-2 left-border">
                            <div className="txt-sec fw-bold fs-14">Insurance ID</div>
                            <div>
                                {`${patientDetails.primary_insurance.carrier} ${patientDetails.primary_insurance.member_id}`}
                            </div>
                        </div>
                    )}

                    <div
                        className={`${
                            isLarger ? "col-lg-4 col-12" : "w-100"
                        } left-border p-2 mb-2`}>
                        <div className="row">
                            <div className="col-lg-2 col-12 txt-sec fw-bold fs-14">Diagnosis</div>

                            <div className="col-lg-10 col-12 fs-16 pr-0 mr-0 patient-header-diagnosis">
                                <MemberProfilePatientDiagnosis
                                    patientDiagnosis={patientDiagnosis}
                                    result="list"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CardSecondary>
        </div>
    );
};

export const PatientInfoWidget = React.memo(withRouter(patientInfoWidget));
