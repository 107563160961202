// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as yup from "yup";
import { dobValidationSchemaFactory, formatDate } from "../../../../utils/yupSchemaUtils";

yup.addMethod(yup.date, "format", formatDate); // we are extending data schema by adding our custom method
const dateValidationSchema = dobValidationSchemaFactory("MMDDYYYY");
const patientDateValidationSchema = dobValidationSchemaFactory("MM/DD/YYYY");

export const VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    preferred_name: yup
        .string()
        .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed")
        .nullable(),
    dob: dateValidationSchema.required("Date of Birth is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    email: yup.string().email("Enter a valid email address").required("Required"),
    // emergency_contact_number: yup.string().matches(/^\d{10}$/, 'Contact Number must be a valid US number').required('Emergency Mobile no. is required')
});

export const PATIENT_VALIDATION_SCHEMA = yup.object().shape({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    preferred_name: yup
        .string()
        .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed")
        .nullable(),
    dob: patientDateValidationSchema.required("Date of Birth is required"),
    mobile: yup
        .string()
        .matches(/^\d{10}$/, "Contact Number must be a valid US number")
        .required("Mobile no. is required"),
    email: yup.string().email("Enter a valid email address").required("Required"),
    // emergency_contact_number: yup.string().matches(/^\d{10}$/, 'Contact Number must be a valid US number').required('Emergency Mobile no. is required')
});
