import React from "react";
import * as yup from "yup";
import { Form } from "formik";
import FormikInput from "../../../../UI/FormikInput";
import Button from "../../../../UI/Button";
import { mergeObjectOnlyExistingFields } from "../../../../../utils/objectUtils";
import { CustomForm } from "../../../../../component-library/CustomForm";
import { COMPLETE_PROFILE_STEPS } from "./index";

export const CompleteProfilePhysician = (props) => {
    const { setCurrentCompleteProfileStep, submitHandler, profile } = props;

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="px-0 row pb-5">
                        <>
                            <div className={"col-12"}>
                                <FormikInput
                                    key="pcp_name"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "pcp_name",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Primary Care Physician",
                                            placeholder: "Enter Primary Care Physician",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12"}>
                                <FormikInput
                                    key="pcp_location"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "pcp_location",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "text",
                                            label: "Primary Care Location",
                                            placeholder: "Enter Primary Care Location",
                                        },
                                    }}
                                />
                            </div>

                            <div className={"col-12"}>
                                <FormikInput
                                    key="no_pcp"
                                    {...formikInputProps}
                                    formEl={{
                                        name: "no_pcp",
                                        elementType: "input",
                                        elementConfig: {
                                            type: "checkbox",
                                            displayName: "I do not have a primary care provider.",
                                            classNames: "fw-bold",
                                            checked: formikProps.values["no_pcp"],
                                        },
                                        onclick: async () => {
                                            await formikProps.setFieldValue(
                                                "no_pcp",
                                                !formikProps.values["no_pcp"],
                                            );
                                            formikProps.setFieldTouched("no_pcp");
                                        },
                                    }}
                                />
                            </div>
                        </>
                    </div>
                    <div className="px-0 row">
                        <div className="d-flex justify-content-center w-100">
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri self-signup-btn m-auto"
                                onClick={() =>
                                    setCurrentCompleteProfileStep(
                                        COMPLETE_PROFILE_STEPS.EMERGENCY_CONTACT,
                                    )
                                }>
                                Back
                            </Button>
                            <Button type="submit" className="Btn Btn--pri self-signup-btn m-auto">
                                Save & Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <CustomForm
                initialValues={mergeObjectOnlyExistingFields(PHYSICIAN_INITIAL_VALUES, profile)}
                validationSchema={primaryCarePhysicianSchema}
                onSubmit={(formData, actions) => {
                    submitHandler(formData, actions);
                    setCurrentCompleteProfileStep(COMPLETE_PROFILE_STEPS.PREFERRED_NAME);
                }}
                render={renderForm}
            />
        </>
    );
};

const PHYSICIAN_INITIAL_VALUES = {
    username: "",
    pcp_name: "",
    pcp_location: "",
    no_pcp: false,
};

const primaryCarePhysicianSchema = yup.object().shape({
    pcp_name: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z-\s]+$/, "Numerals and special characters are not allowed"),
    pcp_location: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z-\s']+$/, "Numerals and special characters are not allowed"),
    no_pcp: yup.boolean(),
});
