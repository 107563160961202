// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import _ from "lodash";
import "../styles.scss";
import { withRouter } from "react-router-dom";
import images from "../../../../utils/images";
import NPSSurvey from "../../NPSSurvey";
import { shouldShowCompleteScreeningTask, shouldUpdateInsurance } from "../index";
import CompleteProfileWidget from "./CompleteProfileWidget";
import { api } from "../../../../APIRequests";
import { A5_V2_ASSESSMENT_TYPE } from "../../../../constants/assessments";

/**
 * Simple helper function to test if the key exists and it is not empty string
 * @param {object} obj - A potentially nested object
 * @param {string} path - A string to query down to your desired value. Think dot access like in jq.
 * @returns
 */
const notNullNotEmpty = (obj, path) => {
    const value = _.get(obj, path);
    return !(_.isNil(value) || _.isEmpty(value));
};

const addressCompleted = (profile) => {
    return (
        notNullNotEmpty(profile, "address.city") &&
        notNullNotEmpty(profile, "address.state") &&
        notNullNotEmpty(profile, "address.street") &&
        notNullNotEmpty(profile, "address.zip_code")
    );
};

const emergencyContactCompleted = (profile) => {
    return (
        notNullNotEmpty(profile, "emergency_contact_number") &&
        notNullNotEmpty(profile, "emergency_contact_person")
    );
};

const hasPrimaryCareProvider = (profile) => {
    return (
        // Either pcp details are filled in, or you selected no_pcp
        (notNullNotEmpty(profile, "pcp_name") && notNullNotEmpty(profile, "pcp_location")) ||
        _.get(profile, "no_pcp")
    );
};

const hasPreferredNameAndPronouns = (profile) => {
    return profile.preferred_name != null && profile.preferred_pronouns != null;
};

const hasCompletedProfile = (profile) => {
    return (
        addressCompleted(profile) &&
        emergencyContactCompleted(profile) &&
        hasPrimaryCareProvider(profile) &&
        hasPreferredNameAndPronouns(profile)
    );
};

const TasksWidget = (props) => {
    const { profile } = props;
    const [taskCount, setTaskCount] = useState(0);
    const [tasks, setTasks] = useState(null);
    const [showYourTasksPopUp, setShowYourTasksPopUp] = useState(false);
    const [showNPSSurvey, setShowNPSSurvey] = useState(false);

    const AWAITING_REVIEW = "awaiting_review";
    const COMPLETE = "COMPLETE";

    const buildTaskListConfig = (profile) => [
        {
            name: "Complete Screening",
            shouldShow: shouldShowCompleteScreeningTask(profile),
            action: async () => {
                const data = {
                    assessment_type: A5_V2_ASSESSMENT_TYPE,
                    allow_multiple: false,
                    is_assessment_required: false,
                };
                await api.patient.assign_assessment({ data });
                props.history.push(`/app/assessment?assessmentType=${A5_V2_ASSESSMENT_TYPE}`);
            },
        },
        {
            name: "Change Password",
            shouldShow: profile.days_until_reset <= 7,
            icon: "./icons/common/task/change-password-task.svg",
            action: () => {
                props.history.push("/app/change-password");
            },
        },
        {
            name: "Update Insurance",
            shouldShow: shouldUpdateInsurance(profile),
            icon: "./icons/common/task/Insurance.svg",
            action: () => {
                props.history.push("/app/patient-insurance");
            },
        },
        {
            name: "Complete Therapist Survey",
            shouldShow:
                profile.initial_bhs_satisfaction_survey_status !== COMPLETE &&
                profile.initial_video_consult,
            action: () => {
                props.history.push("/app/satisfaction-survey?mode=bhs");
            },
        },
        {
            name: "Complete Prescriber Survey",
            shouldShow:
                profile.initial_prescriber_satisfaction_survey_status !== COMPLETE &&
                profile.initial_prescriber_call,
            action: () => {
                props.history.push("/app/satisfaction-survey?mode=prescriber");
            },
        },
        {
            name: "Complete Survey",
            shouldShow: profile.nps_survey_enabled,
            action: () => setShowNPSSurvey(!showNPSSurvey),
        },
        {
            customElement: <CompleteProfileWidget profile={profile} />,
            shouldShow: !hasCompletedProfile(profile),
        },
    ];

    useEffect(() => {
        if (profile) {
            const newTasks = buildTaskListConfig(profile).filter((task) => task.shouldShow);
            setTaskCount(newTasks.length);
            setTasks(newTasks);
        }
    }, [profile]);

    const renderNoTasks = () => {
        return (
            <div className={"text-center mt-lg-4 mt-md-2 mt-0 mx-4"}>
                <div className="fs-16 fw-bold">No New Tasks</div>
                <div className="fs-14 lh-18 pb-md-0 pb-3">
                    Tasks requested by your aptihealth care team will appear here.
                </div>
            </div>
        );
    };

    const renderTasks = (tasks, isViewAll = false, isModal = false) => {
        return (
            <>
                {tasks.slice(0, !isViewAll ? 2 : tasks.length).map((task, taskIndex) => {
                    return (
                        <div key={`your-tasks-widget-${task.name}-${taskIndex}`} className={"py-2"}>
                            <div
                                className={"d-flex cursor"}
                                role={"button"}
                                onClick={task.action ? task.action : null}>
                                <div className={"pr-lg-3 pr-2"}>
                                    <div className={"task-icon-background"}>
                                        <div className={"task-icon-container"}>
                                            {task.icon && (
                                                <img src={images(task.icon)} alt={taskIndex} />
                                            )}
                                            {!task.icon && (
                                                <img
                                                    src={images(
                                                        isModal
                                                            ? "./icons/common/task/task-sec-white.svg"
                                                            : "./icons/common/task/task-white-sec.svg",
                                                    )}
                                                    alt={taskIndex}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="fs-16 fw-bold mt-2 widget-item-txt-wrapper">
                                    <div className="fs-16 fw-bold widget-item-txt">{task.name}</div>
                                    <div>{task.customElement}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderTasksPopUp = (tasks, closeHandler) => {
        return (
            <div className="popup-outer">
                <div className="popup-inner p-4">
                    <div className={"d-flex justify-content-between"}>
                        <div className="fw-bold text-left fs-22 pb-2 m-0">My Tasks</div>
                        <div className="close-popup-button" onClick={closeHandler}>
                            <img src={images("./icons/cross-white-sm.svg")} alt={"x"} />
                        </div>
                    </div>
                    <div className={"widget-border"} style={{ margin: 0 }} />
                    {renderTasks(tasks, true, true)}
                </div>
            </div>
        );
    };

    const renderNPSPopUp = (closeHandler) => {
        return (
            <div className="popup-outer" style={{ zIndex: 1001 }}>
                <div className="popup-inner p-4">
                    <div className={"d-flex justify-content-between"}>
                        <div className="fw-bold text-left fs-22 pb-2 m-0">Survey</div>
                        <div className="close-popup-button" onClick={closeHandler}>
                            <img src={images("./icons/cross-white-sm.svg")} alt={"x"} />
                        </div>
                    </div>
                    <div className={"widget-border"} style={{ margin: 0 }} />
                    <NPSSurvey />
                </div>
            </div>
        );
    };

    return (
        <>
            {showYourTasksPopUp &&
                renderTasksPopUp(tasks, () => setShowYourTasksPopUp(!showYourTasksPopUp))}
            {showNPSSurvey && renderNPSPopUp(() => setShowNPSSurvey(!showNPSSurvey))}
            <div
                className={
                    "dashboard-widget-card dashboard-widget-card-sm dashboard-widget-card-sec px-lg-3 px-md-2 px-3 py-2 mb-3 " +
                    "text-white"
                }>
                <div className={"fs-lg-22 fs-20 fw-bold mb-1 d-inline-flex"}>
                    Your Tasks{" "}
                    {taskCount > 0 && (
                        <span className={"fs-14 item-count item-count-sec"}>{taskCount}</span>
                    )}
                </div>
                {taskCount === 0 && renderNoTasks()}
                {taskCount !== 0 && renderTasks(tasks, window.innerWidth <= 768)}
                {taskCount > 2 && window.innerWidth > 768 && (
                    <div
                        className={"widget-view-all d-flex fw-bold fs-14 cursor Btn-xs-2"}
                        role={"button"}
                        onClick={() => setShowYourTasksPopUp(!showYourTasksPopUp)}>
                        <img src={images("./icons/common/view-all-white.svg")} alt={"+"} />
                        <div className={"mt-1 ml-1"}>View All</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(TasksWidget);
