import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { PATIENT_CANCELLATION_TYPES, patientStatusType } from "../../../utils/calendar/event";
import {
    CalendarEventBadge,
    CalendarEventBody,
    CalendarEventTime,
    CalendarEventTitle,
} from "./CalendarEvent";
import "./styles.scss";

const PatientSession = ({
    position,
    patientName,
    patientPreferredName,
    startTime,
    endTime,
    status,
    details,
    availability,
}) => {
    const cancelledClass =
        status?.text === PATIENT_CANCELLATION_TYPES.patientNoShow.text ? "no-show" : "cancelled";

    return (
        <CalendarEventBody
            className={
                status?.isCancelled
                    ? `PatientSession-${cancelledClass}__body`
                    : "PatientSession__body"
            }
            position={position}
            hasBadge={status?.isCancelled}
            // passing in ad hoc availability if any allows it to be displayed in the
            // appointment side drawer when selected so available slots are not completely hidden
            details={[details, ...availability]}
            testId={`PatientSession_${startTime?.format("YYYY-MM-DD_HHmm")}_${endTime?.format(
                "YYYY-MM-DD_HHmm",
            )}`}>
            <CalendarEventTitle
                className={
                    status?.isCancelled
                        ? `PatientSession-${cancelledClass}__title`
                        : "PatientSession__title"
                }
                title={`${patientName} ${patientPreferredName ? `(${patientPreferredName})` : ""}`}
            />
            {/* if the status is cancelled display a banner partially over top of the current time */}
            {status?.isCancelled && (
                <CalendarEventBadge
                    className={`PatientSession-${cancelledClass}__badge`}
                    text={status.text}
                />
            )}
            <CalendarEventTime
                className={status?.isCancelled && "CalendarEvent__time-with-badge"}
                startTime={startTime}
                endTime={endTime}
                strikeTimes={status?.isCancelled}
            />
        </CalendarEventBody>
    );
};

PatientSession.propTypes = {
    position: PropTypes.object,
    patientName: PropTypes.string,
    patientPreferredName: PropTypes.string,
    startTime: PropTypes.instanceOf(moment),
    endTime: PropTypes.instanceOf(moment),
    status: patientStatusType,
    details: PropTypes.object,
    availability: PropTypes.arrayOf(PropTypes.object),
};

export { PatientSession };
