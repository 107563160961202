// Copyright aptihealth, inc. 2021 All Rights Reserved

import * as actionTypes from "../actions/actionTypes";

export const isConfigsLoaded = (configs) => {
    return (
        configs["POST_SESSION_SURVEY"] !== undefined &&
        configs["UNINSURED_PRICES"] !== undefined &&
        configs["ENABLED_CARRIER_PAYWALL"] !== undefined
    );
};

export const initialConfigsState = {
    POST_SESSION_SURVEY: undefined,
    UNINSURED_PRICES: undefined,
    ENABLED_CARRIER_PAYWALL: undefined,
};

const configsLoadedHandler = (state, action) => {
    return {
        ...state,
        ...action.configs,
    };
};

const reducer = (state = initialConfigsState, action) => {
    switch (action.type) {
        case actionTypes.CONFIGS_LOADED:
            return configsLoadedHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
