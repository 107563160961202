// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { deviceDetect } from "react-device-detect";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import { Footer } from "../../../component-library/Footer";
import ChangePassword from "../../../components/Authentication/ChangePassword";
import AVCheck from "../../../components/Common/AVCheck";
import MessagesView from "../../../components/Common/MessagesView";
import PrivacyPolicy from "../../../components/Common/PrivacyPolicy";
import SystemCheck from "../../../components/Common/SystemCheck";
import TutorialScreens from "../../../components/Common/TutorialScreen";
import Toolbar from "../../../components/Navigation/Toolbar";
import ActionSection from "../../../components/Navigation/Toolbar/ActionSection";
import MemberHome from "../../../components/Patient/MemberHome";
import Survey from "../../../components/Patient/NinetyDayCareProgressGuide/SurveyWrapper/Survey";
import { baseRoute } from "../../../config/roles";
import Layout from "../../../hoc/Layout/Layout";
import { getPatientProfile, profileFetchSuccess } from "../../../redux/actions/auth";
import { getAssessmentStatus, updateScheduleList } from "../../../redux/actions/patient";
import { fetchPatientPaywall } from "../../../redux/actions/patientCardInfo";
import store from "../../../redux/store";
import VideoClient from "../../VideoClient";
import SettingsPage from "../Patient/Settings";
import Assessment from "./Assessment";
import GAD7 from "./GAD7";
import PHQ9 from "./PHQ9";
import PatientInsurance from "./PatientInsurance";
import PatientNotifications from "./PatientNotifications";
import Profile from "./PatientProfile";
import ScheduleRouter from "./ScheduleRouter";
import "./styles.scss";

import _ from "lodash";
import MobileAddPayment from "../../../components/Common/MobileAddPayment";
import PaymentPaywall from "../../../components/Common/PaymentPaywall";
import PolicyAcknowledgement from "../../../components/Common/PolicyAcknowledgement";
import TeensAndKidsCareProgram from "../../../components/Patient/TeensAndKidsCareProgram";
import Spinner from "../../../components/UI/Spinner";
import mapLabelToComponent from "../common/mapLabelToComponent";
import PaymentsPage from "./PaymentsPage/PaymentsPage";
import PostSessionSurveyWrapper from "./PostSessionSurveyWrapper";
import AppBanner from "./AppBanner";
import LogInTasks from "../../../components/Common/LogInTasks";

class Patient extends Component {
    deviceInfo = deviceDetect();

    ROUTE_SECTION_OPTIONS = [
        {
            label: "Home",
            icon: "NavMenu__home-icon",
            mobileIcon: "member_home.svg",
            route: this.props.url + "/home",
            iconComponent: mapLabelToComponent("Home"),
            activeIconComponent: mapLabelToComponent("Home", true),
        },
        {
            label: "Schedule",
            icon: "NavMenu__schedule-icon",
            mobileIcon: "member_schedule.svg",
            route: this.props.url + "/schedule",
            iconComponent: mapLabelToComponent("Schedule"),
            activeIconComponent: mapLabelToComponent("Schedule", true),
        },
        {
            label: "Messages",
            icon: "NavMenu__messages-icon",
            mobileIcon: "member_messages.svg",
            route: this.props.url + "/messages",
            iconComponent: mapLabelToComponent("Messages"),
            activeIconComponent: mapLabelToComponent("Messages", true),
        },
    ];

    ACTION_SECTION_OPTIONS = [];

    navbarConfiguration = {
        routeSection: {
            options: this.ROUTE_SECTION_OPTIONS,
        },
        customRenderSection: [
            {
                isComponent: true,
                render: ActionSection,
                componentProps: {
                    config: {
                        options: this.ACTION_SECTION_OPTIONS,
                    },
                },
            },
        ],
        selfSection: {},
    };
    state = {
        showSideDrawyer: false,
        scheduleList: null,
        totalUnreadMessages: 0,
        incompleteAcknowledgements: null,
        acknowledgements: null,
        logInTasks: [],
        showPaywall: false,
        showMobileAppBanner: false,
    };

    async componentDidMount() {
        const { getPatientProfile, getAssessmentStatus, fetchPatientPaywall } = this.props;
        await Promise.allSettled([getPatientProfile(), fetchPatientPaywall()]);

        if (
            this.props.profile &&
            (this.props.profile.hasOwnProperty("acknowledgements") ||
                this.props.profile.tasks.length)
        ) {
            this.processProfileAcknowledgements(this.props.profile);
        }

        getAssessmentStatus();
        this.updateUnreadCount();
        this.checkForMobileApp();

        if (process.env.REACT_APP_ENABLE_MESSAGE_NOTIFICATIONS_UPDATE === "true") {
            let time = process.env.REACT_APP_ENABLE_MESSAGE_NOTIFICATIONS_UPDATE_REFRESH_TIME;
            this.notification = setInterval(() => {
                if (document.hidden) {
                    console.log(
                        "Document is currently hidden. Waiting to fetch unread message count.",
                    );
                    return;
                }
                this.updateUnreadCount();
            }, parseInt(time));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !prevProps.profile &&
            this.props.profile &&
            (this.props.profile.hasOwnProperty("acknowledgements") ||
                this.props.profile.tasks.length)
        ) {
            this.processProfileAcknowledgements(this.props.profile);
        }

        if (!prevProps.patientCardInfo.showPaywall && this.props.patientCardInfo.showPaywall) {
            this.setState({
                ...this.state,
                showPaywall: true,
            });
        }

        if (this.isAcknowledgementNeeded(prevProps, prevState)) {
            this.props.history.push(
                `/app/acknowledgments/${Object.keys(this.state.incompleteAcknowledgements)[0]}`,
            );
        } else if (this.isLogInTasksNeeded(prevProps, prevState)) {
            this.props.history.push(`/app/log-in-tasks/${this.state.logInTasks[0]?.task_type}`);
        } else if (this.isPaywallNeeded()) {
            this.props.history.push("/app/payment-check");
        }
    }

    isPaywallNeeded = () => {
        return (
            !this.state.incompleteAcknowledgements &&
            !this.state.logInTasks.length &&
            this.state.showPaywall &&
            window.location.pathname !== "/app/payment-check"
        );
    };

    isLogInTasksNeeded = (prevProps, prevState) => {
        if (!prevState.logInTasks.length && this.state.logInTasks.length) {
            return true;
        }

        if (
            prevState.logInTasks.length &&
            this.state.logInTasks.length &&
            !_.isEqual(prevState.logInTasks.length, this.state.logInTasks.length)
        ) {
            return true;
        }

        // Account for finishing acknowledgments
        if (
            prevState.incompleteAcknowledgements !== undefined &&
            this.state.incompleteAcknowledgements === undefined &&
            this.state.logInTasks.length
        ) {
            return true;
        }
    };

    processProfileAcknowledgements(profile) {
        const acknowledgements = profile.acknowledgements;
        const newState = {};
        const incompleteAcknowledgements = this.getIncompleteAcknowledgements(acknowledgements);
        const incompleteLogInTasks = this.getIncompleteLogInTasks(profile);

        if (!_.isNull(incompleteAcknowledgements)) {
            newState["incompleteAcknowledgements"] = incompleteAcknowledgements;
            newState["acknowledgements"] = acknowledgements;
        }

        if (incompleteLogInTasks.length) {
            newState["logInTasks"] = incompleteLogInTasks;
        }

        if (!_.isEmpty(newState)) {
            this.setState(newState);
        }
    }

    isAcknowledgementNeeded = (prevProps, prevState) => {
        if (!prevState.incompleteAcknowledgements && this.state.incompleteAcknowledgements) {
            return true;
        }

        if (
            prevState.incompleteAcknowledgements &&
            this.state.incompleteAcknowledgements &&
            !_.isEqual(prevState.incompleteAcknowledgements, this.state.incompleteAcknowledgements)
        ) {
            return true;
        }
    };

    getIncompleteAcknowledgements(acknowledgements) {
        if (_.isNull(acknowledgements) && _.isEmpty(acknowledgements)) {
            return;
        }

        const incompleteAcknowledgements = Object.entries(acknowledgements)
            .filter(([, ackVal]) => ackVal === null)
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        if (_.isEmpty(incompleteAcknowledgements)) {
            return;
        }

        return incompleteAcknowledgements;
    }

    acknowledged = (currentAcknowledgement, acknowledgementData) => {
        let incompleteAcknowledgements = {
            ...this.state.incompleteAcknowledgements,
        };
        delete incompleteAcknowledgements[currentAcknowledgement];
        incompleteAcknowledgements = this.getIncompleteAcknowledgements(incompleteAcknowledgements);

        const acknowledgements = { ...this.state.acknowledgements };
        acknowledgements[currentAcknowledgement] = acknowledgementData;

        store.dispatch(
            profileFetchSuccess({
                ...this.props.profile,
                acknowledgements,
            }),
        );

        this.setState({ incompleteAcknowledgements, acknowledgements });
        this.canPushToHome();
    };

    onTaskCompleted = (task) => {
        this.removeLogInTask(task);
    };

    canPushToHome = () => {
        if (
            !this.state.incompleteAcknowledgements &&
            !this.state.logInTasks.length &&
            !this.state.showPaywall
        ) {
            window.localStorage.removeItem("redirectAfterAuth");
            this.props.history.push("/app/home");
        }
    };

    removeLogInTask = (task) => {
        const tasks = this.state.logInTasks;
        const { policy_type, target_patient_id, task_type } = task;
        const updatedTasks = tasks.filter(
            (item) =>
                !(
                    item.policy_type === policy_type &&
                    item.target_patient_id === target_patient_id &&
                    item.task_type === task_type
                ),
        );
        if (!updatedTasks.length) {
            store.dispatch(
                profileFetchSuccess({
                    ...this.props.profile,
                    tasks: this.filterOutLogInTasks(this.props.profile.tasks),
                }),
            );
        }

        this.setState(
            {
                logInTasks: updatedTasks,
            },
            () => {
                this.canPushToHome();
            },
        );
    };

    filterOutLogInTasks = (tasks) => {
        return tasks.filter((item) => !item.display_log_in);
    };

    componentWillUnmount() {
        if (this.notification) {
            clearInterval(this.notification);
        }
    }

    async updateUnreadCount() {
        const options = { params: { showLoader: false } };
        let count = await api.messaging.fetch_unread_message_count({ options });
        this.setState({
            totalUnreadMessages: count["unread_count"],
        });
    }

    toggleSideDrawyer = () => {
        this.setState((prevState) => {
            return {
                showSideDrawyer: !prevState.showSideDrawyer,
            };
        });
    };

    paywallCompleted = () => {
        this.setState({
            ...this.state,
            showPaywall: false,
        });

        this.props.history.push("/app");
    };

    checkForMobileApp = async () => {
        try {
            if (this.deviceInfo.isMobile && !sessionStorage.getItem("mobileAppBannerClosed")) {
                if (this.deviceInfo.os.toLowerCase() === "ios") {
                    this.setState({
                        ...this.state,
                        showMobileAppBanner: true,
                    });
                } else if (this.deviceInfo.os.toLowerCase() === "android") {
                    const relatedApps = await navigator.getInstalledRelatedApps();
                    if (relatedApps) {
                        const psApp = relatedApps.find(
                            (app) => app.id === "com.aptihealth.aptihealth",
                        );
                        if (!psApp) {
                            this.setState({
                                ...this.state,
                                showMobileAppBanner: true,
                            });
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleCloseMobileBanner = () => {
        this.setState({
            ...this.state,
            showMobileAppBanner: false,
        });
        sessionStorage.setItem("mobileAppBannerClosed", true);
    };

    getIncompleteLogInTasks = (profile) => {
        const logInTasks = profile?.tasks?.filter((task) => task.display_log_in) || [];

        return Array.from(logInTasks).sort((a, b) => {
            if (a.rank === null) {
                return 1;
            }
            if (b.rank === null) {
                return -1;
            }
            return b.rank - a.rank;
        });
    };

    renderAcknowledgementLayout = () => {
        return (
            <Layout>
                <Switch>
                    <Route
                        path="/app/acknowledgments/:policy_name"
                        render={(props) => (
                            <PolicyAcknowledgement
                                {...props}
                                acknowledged={this.acknowledged}
                                acknowledgements={this.state.acknowledgements}
                                incompleteAcknowledgements={this.state.incompleteAcknowledgements}
                            />
                        )}
                    />
                    <Route
                        path="/app/log-in-tasks/:task_name"
                        render={(props) => (
                            <LogInTasks
                                {...props}
                                task={this.state.logInTasks[0]}
                                onTaskCompleted={this.onTaskCompleted}
                            />
                        )}
                    />
                    <Route
                        path="/app/payment-check"
                        render={(props) => (
                            <PaymentPaywall {...props} paywallCompleted={this.paywallCompleted} />
                        )}
                    />
                </Switch>
            </Layout>
        );
    };

    renderBaseLayout = () => {
        return (
            <Layout>
                {this.state.showMobileAppBanner && this.deviceInfo && (
                    <AppBanner
                        onClose={this.handleCloseMobileBanner}
                        deviceInfo={this.deviceInfo}
                    />
                )}
                <Toolbar
                    navbarConfiguration={this.navbarConfiguration}
                    toggleHandler={this.toggleSideDrawyer}
                    sidebarActive={this.state.showSideDrawyer}
                    baseRoute={baseRoute}
                    totalUnreadMessages={this.state.totalUnreadMessages}></Toolbar>
                <Switch>
                    <Route
                        path="/app/home"
                        render={(props) => (
                            <MemberHome
                                {...props}
                                totalUnreadMessages={this.state.totalUnreadMessages}
                            />
                        )}
                    />
                    <Route path="/app/profile" component={Profile} />
                    <Route path="/app/patient-notifications" component={PatientNotifications} />
                    <Route path="/app/patient-insurance" component={PatientInsurance} />
                    <Route path="/app/patient-payment" component={PaymentsPage} />
                    <Route
                        path="/app/tutorial"
                        render={() => <TutorialScreens baseRoute="/app" />}
                    />
                    <Route
                        path="/app/change-password"
                        render={(props) => <ChangePassword {...props} baseRoute="/app" />}
                    />
                    <Route path="/app/assessment" component={Assessment} />
                    <Route path="/app/schedule" component={ScheduleRouter} />
                    <Route
                        path="/app/settings"
                        render={(props) => <SettingsPage {...props} baseRoute="/app" />}
                    />
                    <Route path="/app/system-check" component={SystemCheck} />
                    <Route path="/app/av-check" component={AVCheck} />
                    <Route path="/app/video-call/ratings" component={PostSessionSurveyWrapper} />
                    <Route path="/app/video-call" render={() => <VideoClient {...this.props} />} />
                    <Route
                        path="/app/privacy-policy"
                        render={(props) => <PrivacyPolicy {...props} baseRoute="/app" />}
                    />
                    <Route path="/app/satisfaction-survey" component={Survey} />
                    <Route path="/app/phq-9" component={PHQ9} />
                    <Route path="/app/gad-7" component={GAD7} />
                    <Route
                        path="/app/outcome-assessment"
                        render={(props) => <Assessment {...props} iteration="post_90_day" />}
                    />
                    <Route path="/app/messages" render={(props) => <MessagesView {...props} />} />
                    <Route path="/app/mobile-add-payment" component={MobileAddPayment} />
                    <Route
                        path="/app/acknowledgments/:policy_name"
                        render={(props) => (
                            <PolicyAcknowledgement
                                {...props}
                                acknowledged={this.acknowledged}
                                acknowledgements={this.state.acknowledgements}
                            />
                        )}
                    />

                    <Route path="/app/teens-and-kids/:step" component={TeensAndKidsCareProgram} />

                    <Redirect from="/app" to="/app/home" />
                </Switch>
                <Footer />
            </Layout>
        );
    };

    render() {
        if (
            this.props.profile &&
            this.props.patientCardInfo.showPaywall !== undefined &&
            !this.state.incompleteAcknowledgements &&
            !this.state.showPaywall &&
            !this.state.logInTasks.length
        ) {
            return <>{this.renderBaseLayout()}</>;
        }

        if (
            this.props.profile &&
            this.props.patientCardInfo.showPaywall !== undefined &&
            (this.state.incompleteAcknowledgements ||
                this.state.showPaywall ||
                this.state.logInTasks.length)
        ) {
            return <>{this.renderAcknowledgementLayout()}</>;
        }

        return <Spinner />;
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        assessmentStatus: state.patient.assessmentStatus,
        patientCardInfo: state.patientCardInfo,
        scheduleList: state.patient.scheduleList,
        configs: state.configs,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, {
    getPatientProfile,
    getAssessmentStatus,
    updateScheduleList,
    fetchPatientPaywall,
})(withRouter(Patient));
