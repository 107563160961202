// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";
import { showLoader } from "./loader";
import moment from "moment";
import _ from "lodash";
import {
    buildAddUpdateRequest,
    getAllottedTime,
    getDefaultEventTypeByUserType,
    getDefaultScheduleTypeOptions,
    getSelectableTimes,
    getSelectableTimesAvailable,
    getSelectableTimesAway,
    isChild,
    showDeleteAppointmentToast,
    showRescheduleAppointmentToast,
    validateAvailableAppointmentSelections,
} from "../../utils/calendar/util";
import { isUserAdmin } from "./auth";
import { createDatesToDisplay } from "../../utils/calendar/dates";
import { AVAILABLE, AWAY, isSpecialEvent } from "../../constants/event";
import momentTZ from "moment-timezone";
import { convertWorkingHourToLocal } from "../../utils/calendar/workingHours";
import { initialState } from "../reducers/calendarReducer";
import { recurringScheduleWontCreateCalls } from "../../utils/calendar/event";

export const initializeCalendar = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE,
        data: {
            selectedScheduleDate: moment().local(),
        },
    });
};

export const initializeProviderAsSelf = (currentUserProfile) => async (dispatch) => {
    const timeZone = currentUserProfile?.preferences?.time_zone ?? "US/Eastern";
    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE_PROVIDER,
        data: {
            provider: {
                providerId: currentUserProfile?.username,
                providerType: currentUserProfile?.provider_type,
                localAvailability: convertWorkingHourToLocal(
                    currentUserProfile?.availability,
                    timeZone,
                    momentTZ.tz.guess(),
                ),
                timeZone,
            },
        },
    });
};

export const initializeProviderAsAdmin = (providerId) => async (dispatch) => {
    const provider = {
        providerId: providerId,
        providerType: null,
        localAvailability: null,
        timeZone: "US/Eastern",
    };
    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE_PROVIDER,
        data: { provider },
    });

    const queryParams = { providerId };
    const userProfile = (await api.provider.fetch_provider_profile({ queryParams }))?.user;

    provider.providerType = userProfile?.provider_type;
    provider.timeZone = userProfile?.preferences?.time_zone ?? "US/Eastern";
    provider.localAvailability = convertWorkingHourToLocal(
        userProfile?.availability,
        provider.timeZone,
        momentTZ.tz.guess(),
    );
    provider.name = userProfile?.name;
    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE_PROVIDER,
        data: { provider },
    });
};

export const initializeOnBehalfOfProviderPatient = (providerId, patientId) => async (dispatch) => {
    const provider = {
        providerId: providerId,
        providerType: null,
        localAvailability: null,
        timeZone: "US/Eastern",
    };
    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE_BEHALF_OF_PROVIDER_PATIENT,
        data: {
            provider: provider,
            patientId: patientId,
        },
    });

    const queryParams = { providerId, patientId };
    const userProfile = (await api.provider.fetch_provider_profile_on_behalf_of({ queryParams }))
        ?.user;

    provider.providerType = userProfile?.provider_type;
    provider.timeZone = userProfile?.preferences?.time_zone ?? "US/Eastern";
    provider.localAvailability = convertWorkingHourToLocal(
        userProfile?.availability,
        provider.timeZone,
        momentTZ.tz.guess(),
    );
    provider.name = userProfile?.name;

    const patientData = await dispatch(fetchPatient(patientId));
    dispatch(updateAppointmentPatient({ patient_id: patientId }));

    dispatch({
        type: actionTypes.CALENDAR_INITIALIZE_BEHALF_OF_PROVIDER_PATIENT,
        data: {
            provider,
            patientId,
            acuityScore: patientData?.user?.acuity_score,
        },
    });
};

export const isOnBehalfOfProviderPatientScheduling = () => (dispatch, getState) => {
    const calendarState = getState().calendar;
    return Boolean(calendarState.onBehalfOfPatient);
};

export const fetchEventTypeOptions = (provider, patient) => async (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_FETCH_EVENT_TYPE_OPTIONS,
        data: {
            eventTypeOptions: await getDefaultScheduleTypeOptions(provider, patient),
            acuityScore: patient?.acuity_score,
        },
    });
};

export const fetchPatients =
    ({ queryParams } = {}) =>
    (dispatch) => {
        return api.provider.fetch_patient_list({ queryParams }).then((data) => {
            const activePatients = data.user.filter(
                (patient) => patient.patient_status !== "INACTIVE",
            );

            const patientDropdownOptions = activePatients.map((patient) => {
                const { first_name, last_name, preferred_name, dob } = patient;
                return {
                    value: patient.patient_id,
                    label: `${first_name} ${last_name}${preferred_name ? ` (${preferred_name})` : ""}, ${dob}`
                };
            });

            const patientMap = activePatients.reduce((map, patient) => {
                map[patient["patient_id"]] = patient;
                return map;
            }, {});

            dispatch(updatePatientOptions({ patientDropdownOptions, patientMap }));
        });
    };

export const fetchPatient = (patientId) => async (dispatch) => {
    const urlParams = { patientId };
    const data = await api.shared.fetch_patient_details({ urlParams });

    const patient = data.user;
    patient["patient_id"] = patient.username;

    dispatch(
        updatePatientOptions({
            patientDropdownOptions: [
                {
                    value: patientId,
                    label: `${patient.first_name} ${patient.last_name}${
                        patient.preferred_name ? ` (${patient.preferred_name})` : ""
                    }, ${patient.dob}`,
                },
            ],
            patientMap: {
                [patientId]: patient,
            },
        }),
    );

    return data;
};

export const updatePatientOptions = (data) => {
    return {
        type: actionTypes.CALENDAR_FETCH_PATIENT_OPTIONS,
        data,
    };
};

/**
 * updates the selected date and fetches the schedule for the current day
 * @param {*} selectedDate
 * @returns
 */
export const updateSelectedDate = (selectedDate) => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const { viewType, provider } = calendarState;
    const currentDatesToDisplay = calendarState?.datesToDisplay ?? [];

    if (isUserAdmin() && !provider?.providerId) {
        return;
    }

    const datesToDisplay = createDatesToDisplay(selectedDate, viewType);

    // Set new selected dates
    dispatch(
        updateSelectedScheduleDates({
            selectedScheduleDate: selectedDate,
            selectedScheduleDates: datesToDisplay,
        }),
    );

    // only refetch the calendar if it is a new date
    if (currentDatesToDisplay.some((date) => date.isSame(selectedDate))) {
        return;
    }
    dispatch(updateScheduledEvents());

    if (calendarState.unconfirmedEvent) {
        dispatch(upsertUnconfirmedEvent(selectedDate));
    }
};

/**
 * updates the selected date and fetches the schedule for the current day
 * @param {*} selectedDate
 * @returns
 */
export const updateSelectedDateV2 =
    (selectedDate, selectedEventId = null) =>
    (dispatch, getState) => {
        const calendarState = getState().calendar;
        const { viewType, provider } = calendarState;
        const currentDatesToDisplay = calendarState?.datesToDisplay ?? [];

        if (isUserAdmin() && !provider?.providerId) {
            return;
        }

        const datesToDisplay = createDatesToDisplay(selectedDate, viewType).map((date) =>
            moment(date, "YYYY-MM-DD"),
        );

        // Set new selected dates
        dispatch(
            updateSelectedScheduleDates({
                selectedScheduleDate: selectedDate,
                selectedScheduleDates: datesToDisplay,
            }),
        );

        // only refetch the calendar if it is a new date
        if (currentDatesToDisplay.some((date) => date.isSame(selectedDate))) {
            return;
        }

        dispatch(updateScheduledEventsV2(selectedEventId));

        if (calendarState.unconfirmedEvent) {
            dispatch(upsertUnconfirmedEvent(selectedDate));
        }
    };

export const updateScheduledEvents = () => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const { provider, selectedScheduleDates } = calendarState;
    const startDate = _.cloneDeep(selectedScheduleDates[0]).startOf("day");
    const endDate = _.cloneDeep(selectedScheduleDates.slice(-1)[0]).endOf("day");

    const isOnBehalfProviderPatient = dispatch(isOnBehalfOfProviderPatientScheduling());

    const queryParams = {
        local_start_date_time: startDate.format("YYYY-MM-DD HH:mm"),
        local_end_date_time: endDate.format("YYYY-MM-DD HH:mm"),
        timezone: moment.tz.guess(),
        show_deleted: true,
        show_unjoinable: true,
        provider_id: provider?.providerId,
    };

    if (isOnBehalfProviderPatient) {
        queryParams["patient_id"] = calendarState.onBehalfOfPatient;
    }

    return api.schedule
        .get_schedule({
            options: {
                params: { showLoader },
                queryParams: queryParams,
            },
        })
        .then((data) => {
            dispatch(
                updateProviderScheduleCallList({
                    events: [...data.schedule, ...data.away, ...data.availability],
                }),
            );
            dispatch(updateTimeSlots());
        });
};

export const updateScheduledEventsV2 =
    (selectedEventId = null) =>
    (dispatch, getState) => {
        const calendarState = getState().calendar;
        const { provider, selectedScheduleDates } = calendarState;

        const startDate = _.cloneDeep(selectedScheduleDates[0]).startOf("day");
        const endDate = _.cloneDeep(selectedScheduleDates.slice(-1)[0]).endOf("day");

        return api.schedule
            .get_schedule({
                options: {
                    params: { showLoader },
                    queryParams: {
                        local_start_date_time: startDate.format("YYYY-MM-DD HH:mm"),
                        local_end_date_time: endDate.format("YYYY-MM-DD HH:mm"),
                        timezone: moment.tz.guess(),
                        show_deleted: true,
                        show_unjoinable: true,
                        provider_id: provider?.providerId,
                    },
                },
            })
            .then((data) => {
                dispatch(
                    updateProviderScheduleCallList({
                        events: [...data.schedule, ...data.away, ...data.availability],
                    }),
                );
                dispatch(updateTimeSlots());

                // Find the specific event by ID if selectedEventId is provided
                if (selectedEventId) {
                    const selectedEvent = data.schedule.find(
                        (event) => event.callId === selectedEventId,
                    );
                    if (selectedEvent) {
                        dispatch(updateSelectedEvents(selectedEvent));
                    }
                }
            });
    };

export const updateSelectedScheduleDates = (data) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_DATE,
        data,
    });

    dispatch(updateTimeSlots());
};

export const openAppointmentPicker = (data) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.OPEN_APPOINTMENT_PICKER,
        data,
    });
    dispatch(updateTimeSlots());
    dispatch(upsertUnconfirmedEvent(data.selectedScheduleDate));
};

export const closeDrawer = () => (dispatch, getState) => {
    const calendarState = getState().calendar;
    dispatch(deleteUnconfirmedEvent());
    const data = getAppointmentSelectionsAfterReset(calendarState, dispatch);
    dispatch({
        type: actionTypes.CLOSE_DRAWER,
        data,
    });
};

const upsertUnconfirmedEvent =
    (overrideDate = null) =>
    (dispatch, getState) => {
        const calendarState = getState().calendar;
        const { appointmentSelections, patientMap, selectedScheduleDate } = calendarState;
        const { eventType, patientId, time, startTime, endTime } = appointmentSelections;
        const date = overrideDate ? overrideDate : selectedScheduleDate;

        if (isSpecialEvent(eventType)) {
            // if the start or end time is not valid default to 0 minutes
            let data = {
                unconfirmed: true,
                allotted_time: 0,
            };
            if (date && startTime && endTime) {
                const localTimestamp = `${date.format("YYYY-MM-DD")} ${startTime}`;
                const timestamp = moment(localTimestamp).utc().format("YYYY-MM-DD HH:mm");
                const allottedTime = Math.max(
                    moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"), "minutes"),
                    0,
                );
                data = {
                    allotted_time: allottedTime,
                    unconfirmed: true,
                    timestamp,
                };
            }
            dispatch({
                type: actionTypes.CALENDAR_UPSERT_UNCONFIRMED_EVENT,
                data,
            });
        } else if (date && time) {
            const localTimestamp = `${date.format("YYYY-MM-DD")} ${time}`;
            const timestamp = moment(localTimestamp).utc().format("YYYY-MM-DD HH:mm");
            const isPatientChild = patientId ? isChild(patientMap[patientId]) : false;
            const allottedTime = getAllottedTime(eventType, isPatientChild);

            dispatch({
                type: actionTypes.CALENDAR_UPSERT_UNCONFIRMED_EVENT,
                data: {
                    allotted_time: allottedTime,
                    unconfirmed: true,
                    timestamp,
                },
            });
        }
    };

export const deleteUnconfirmedEvent = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_DELETE_UNCONFIRMED_EVENT,
    });
};

/**
 * updates the selectable time slots on the appointment picker
 * @returns
 */
export const updateTimeSlots = () => (dispatch, getState) => {
    const calendar = getState().calendar;
    const { events, selectedScheduleDate } = calendar;
    const workingHours = calendar.provider.localAvailability;
    const { eventType, time, patientId, startTime, endTime, awayDuration } =
        calendar.appointmentSelections;
    const patient = calendar.patientMap[patientId];
    const filteredEvents = events.filter(
        (event) => event.callId !== calendar.appointmentSelections.callId && event.deleted !== true,
    );
    const date = moment.utc(selectedScheduleDate, "YYYY-MM-DD HH:mm").local();

    // if the event type is available then all working hours should be filtered out
    if (eventType === AVAILABLE) {
        const { fromSlots, toSlots } = getSelectableTimesAvailable(
            date,
            workingHours,
            filteredEvents.filter((event) => event.event_type === AVAILABLE),
            filteredEvents.filter((event) => event.event_type === AWAY),
        );

        const shouldRemoveTimeFrom =
            startTime &&
            !fromSlots.some((timeSlot) => {
                return timeSlot.value === startTime;
            });

        const shouldRemoveTimeTo =
            endTime &&
            !toSlots.some((timeSlot) => {
                return timeSlot.value === endTime;
            });

        if (shouldRemoveTimeFrom) {
            dispatch(updateAppointmentStartTime(null));
        }

        if (shouldRemoveTimeTo) {
            dispatch(updateAppointmentEndTime(null));
        }

        dispatch(updateSelectableTimesFrom(fromSlots));
        dispatch(updateSelectableTimesTo(toSlots));
    } else if (eventType === AWAY) {
        const { fromSlots, toSlots } = getSelectableTimesAway(filteredEvents, date);

        const shouldRemoveTimeFrom =
            startTime &&
            !fromSlots.some((timeSlot) => {
                return timeSlot.value === startTime;
            });

        const shouldRemoveTimeTo =
            endTime &&
            !toSlots.some((timeSlot) => {
                return timeSlot.value === endTime;
            });

        if (shouldRemoveTimeFrom) {
            dispatch(updateAppointmentStartTime(null));
        }

        if (shouldRemoveTimeTo) {
            dispatch(updateAppointmentEndTime(null));
        }

        dispatch(updateSelectableTimesFrom(fromSlots));
        dispatch(updateSelectableTimesTo(toSlots));
    } else {
        const allottedTime = eventType === AWAY ? parseInt(awayDuration) : null;
        const selectableTimeSlots = getSelectableTimes({
            events: filteredEvents,
            allottedTime,
            eventType,
            patient,
            date,
        });

        const shouldRemoveTime =
            time &&
            !selectableTimeSlots.some((timeSlot) => {
                return timeSlot.value === time;
            });

        if (shouldRemoveTime) {
            dispatch(clearTimes(null));
        }

        dispatch(updateSelectableTimes(selectableTimeSlots));
    }
};

export const updateProviderScheduleCallList = (data) => {
    return {
        type: actionTypes.CALENDAR_UPDATE_SCHEDULE_CALL_LIST,
        data,
    };
};

export const updateAppointmentEventType = (eventType) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { eventType },
    });
    dispatch(updateTimeSlots());
    dispatch(upsertUnconfirmedEvent());
};

/**
 * redux action to update the times selectable from the appointment picker
 * @param {*} selectableTimes
 * @returns
 */
export const updateSelectableTimes = (selectableTimes) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { selectableTimes },
    });
};

export const updateSelectableTimesFrom = (selectableTimesFrom) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { selectableTimesFrom },
    });
};

export const updateSelectableTimesTo = (selectableTimesTo) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { selectableTimesTo },
    });
};

export const updateAppointmentTime = (time) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { time },
    });
    dispatch(upsertUnconfirmedEvent());
};

export const clearTimes = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: {
            time: null,
        },
    });
};

export const updateAppointmentStartTime = (startTime) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { startTime },
    });

    dispatch(upsertUnconfirmedEvent());
};

export const updateAppointmentEndTime = (endTime) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { endTime },
    });

    dispatch(upsertUnconfirmedEvent());
};

export const updateAppointmentPatient = (patient) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { patientId: patient.patient_id },
    });
};

export const updateAppointmentLabel = (label) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { label },
    });
};

export const updateAppointmentIsReoccurring = (isReoccurring) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: {
            seriesData: {
                frequencyDays: [
                    moment(getState().calendar?.selectedScheduleDate, "YYYY-MM-DD").day(),
                ],
                frequencyWeeks: 0,
                isReoccurring: isReoccurring,
                numberOfOccurrences: 1,
            },
        },
    });
};

export const updateAppointmentFrequencyDays = (frequencyDays) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { seriesData: { frequencyDays } },
    });
};

export const updateAppointmentFrequencyWeeks = (frequencyWeeks) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { seriesData: { frequencyWeeks } },
    });
};

export const updateAppointmentNumberOfOccurrences = (numberOfOccurrences) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS,
        data: { seriesData: { numberOfOccurrences } },
    });
};

/**
 * added as apart of https://aptihealth.atlassian.net/browse/AT1-6273
 * in order to lock the calendar to only viewing the patient x provider
 * combo
 * @param {*} state
 * @returns undefined if the state should be reset to the initial state,
 * otherwise returns a new state where some values have been retained
 */
const getAppointmentSelectionsAfterReset = (calendarState, dispatch) => {
    const isOnBehalfProviderPatient = dispatch(isOnBehalfOfProviderPatientScheduling());
    let newAppointmentSelectionData = undefined;

    // if on behalf of scheduling do not reset the patient relevant appointment selections
    if (isOnBehalfProviderPatient) {
        newAppointmentSelectionData = {};
        const newAppointmentSelections = _.cloneDeep(initialState.appointmentSelections);
        const keysToRetain = ["patientId", "acuityScore"];
        for (const key of keysToRetain) {
            newAppointmentSelections[key] = calendarState.appointmentSelections[key];
        }

        const defaultEventType = getDefaultEventTypeByUserType(
            calendarState?.provider?.providerType,
        );
        newAppointmentSelections["eventType"] = defaultEventType;

        newAppointmentSelectionData.appointmentSelections = newAppointmentSelections;
    }

    return newAppointmentSelectionData;
};

export const resetAppointmentSelections = () => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const data = getAppointmentSelectionsAfterReset(calendarState, dispatch);

    dispatch({
        type: actionTypes.CALENDAR_RESET_APPOINTMENT_SELECTIONS,
        data,
    });
};

export const resetSchedulingMode = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_RESET_SCHEDULING_MODE,
    });
};

export const refetchSchedule = () => (dispatch) => {
    dispatch(updateScheduledEvents());
};

export const deleteAppointment =
    (callId, cancellation_reason, twenty_four_hour_notice, delete_series) =>
    (dispatch, getState) => {
        let options = { queryParams: { call_id: callId } };

        return api.schedule
            .delete_schedule({
                options,
                data: {
                    cancellation_reason: {
                        reason_type: cancellation_reason,
                        twenty_four_hour_notice: twenty_four_hour_notice === "yes",
                    },
                    delete_series: delete_series,
                },
            })
            .then((data) => {
                dispatch(refetchSchedule());
                dispatch(resetAppointmentSelections());
                dispatch(closeDrawer());
                showDeleteAppointmentToast(data, dispatch);
            })
            .catch((error) => {
                console.error(error);
                dispatch(refetchSchedule());
            });
    };

export const addAppointment = () => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const appointment = calendarState.appointmentSelections;

    const errors = validateAppointmentSelections(appointment);
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTION_ERRORS,
        data: { appointmentSelectionErrors: errors },
    });

    const hasErrors = Object.keys(errors).some((key) => errors[key]);
    if (hasErrors) {
        return;
    }

    const patientId = appointment.patientId;
    const patient = calendarState.patientMap[patientId];
    const seriesData = appointment.seriesData;

    const data = buildAddUpdateRequest(
        seriesData,
        appointment.time,
        calendarState.selectedScheduleDate.format("YYYY-MM-DD"),
        appointment.eventType,
        patient,
        calendarState?.provider?.providerId,
        appointment.startTime,
        appointment.endTime,
        appointment.label,
    );

    return api.schedule.post_schedule({ data }).then((data) => {
        // Creating a series only returns the first call created, so we re-fetch all
        if (seriesData.isReoccurring) {
            dispatch(updateScheduledEvents());
        } else {
            dispatch(
                updateProviderScheduleCallList({
                    events: [...calendarState.events, data.appointment_details],
                }),
            );
        }

        dispatch(closeDrawer());
        dispatch(resetAppointmentSelections());
        dispatch(fetchEventTypeOptions(calendarState.provider));
        dispatch(
            updateAppointmentEventType(
                getDefaultEventTypeByUserType(calendarState.provider?.providerType),
            ),
        );
    });
};

const validateAppointmentSelections = (appointmentSelections) => {
    let appointmentSelectionErrors = {
        eventType: null,
        patientId: null,
        seriesData: null,
        time: null,
        startTime: null,
        endTime: null,
    };

    if (!appointmentSelections.eventType) {
        appointmentSelectionErrors.eventType = "Appointment type is required";
    }

    if (isSpecialEvent(appointmentSelections.eventType)) {
        appointmentSelectionErrors = validateAvailableAppointmentSelections(
            appointmentSelections,
            appointmentSelectionErrors,
        );
    } else {
        if (
            appointmentSelections.eventType !== AWAY &&
            appointmentSelections.eventType !== AVAILABLE &&
            !appointmentSelections.patientId
        ) {
            appointmentSelectionErrors.patientId = "Patient is required";
        }

        if (!appointmentSelections.time) {
            appointmentSelectionErrors.time = "Time is required";
        }
    }

    const seriesData = appointmentSelections.seriesData;
    if (
        seriesData?.isReoccurring &&
        (seriesData?.frequencyDays.length === 0 ||
            seriesData?.frequencyWeeks === null ||
            seriesData.numberOfOccurrences === null)
    ) {
        appointmentSelectionErrors.seriesData = "Reoccurrence details required";
    } else if (recurringScheduleWontCreateCalls(seriesData)) {
        appointmentSelectionErrors.seriesData =
            "This recurring schedule would create no appointments";
    }

    return appointmentSelectionErrors;
};

export const updateCalendarView = (viewType) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_VIEW_TYPE,
        data: { viewType },
    });
};

export const updateSelectedEvents = (selectedEvents) => (dispatch, getState) => {
    const calendarState = getState().calendar;
    selectedEvents = Array.isArray(selectedEvents) ? selectedEvents : [selectedEvents];
    const data = getAppointmentSelectionsAfterReset(calendarState, dispatch);

    dispatch({
        type: actionTypes.CALENDAR_UPDATE_SELECTED_EVENTS,
        data: { selectedEvents, ...data },
    });
};

export const editAppointmentSelected = (event) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_EDIT_APPOINTMENT_SELECTED,
        data: { event },
    });
    dispatch(updateTimeSlots());
};

export const checkEditUntouched = (event) => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const existingCall = calendarState.events.find(
        (event) => event.callId === calendarState.appointmentSelections.callId,
    );

    if (!existingCall) {
        dispatch({
            type: actionTypes.CALENDAR_RESET_APPOINTMENT_EDIT_SELECTION_ERRORS,
        });
        return;
    }

    const appointmentEditSelectionErrors = {
        untouched:
            moment.utc(existingCall.timestamp).tz(momentTZ.tz.guess()).format("HH:mm") ===
                calendarState?.appointmentSelections?.time &&
            moment.utc(existingCall?.timestamp).format("YYYY-MM-DD") ===
                moment(calendarState?.selectedScheduleDate).format("YYYY-MM-DD") &&
            existingCall?.patient_username === calendarState?.appointmentSelections?.patientId &&
            existingCall?.event_type === calendarState?.appointmentSelections?.eventType,
    };

    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_EDIT_SELECTION_ERRORS,
        data: { appointmentEditSelectionErrors },
    });
};

export const editAppointment = (oldEvent) => (dispatch, getState) => {
    const calendarState = getState().calendar;
    const appointment = calendarState.appointmentSelections;
    const patientId = appointment.patientId;
    const patient = calendarState.patientMap[patientId];
    const seriesData = appointment.seriesData;
    const callId = appointment.callId;

    const errors = validateAppointmentSelections(appointment);
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTION_ERRORS,
        data: { appointmentSelectionErrors: errors },
    });

    const hasErrors = Object.keys(errors).some((key) => errors[key]);
    if (hasErrors) {
        return;
    }

    const requestBody = buildAddUpdateRequest(
        seriesData,
        appointment.time,
        calendarState.selectedScheduleDate.format("YYYY-MM-DD"),
        appointment.eventType,
        patient,
        calendarState?.provider?.providerId,
        appointment.startTime,
        appointment.endTime,
        appointment.label,
    );

    const data = { ...requestBody, call_id: callId };

    api.schedule
        .put_schedule({ data })
        .then((data) => {
            showRescheduleAppointmentToast(data, dispatch);
            dispatch(refetchSchedule());
        })
        .catch((error) => {
            console.error(error);
            dispatch(refetchSchedule());
        });
    dispatch(resetAppointmentSelections());
    dispatch(closeDrawer());
};

export const updateCalendarFilters = (filters) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_FILTERS,
        data: { filters },
    });
};

export const resetCalendarFilters = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_RESET_FILTERS,
    });
};

export const toggleDatePicker = () => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_TOGGLE_DATEPICKER,
    });
};

export const updateTimezone = (timezone) => (dispatch) => {
    dispatch({
        type: actionTypes.CALENDAR_UPDATE_TIMEZONE,
        data: { timezone },
    });
};
