// Copyright aptihealth, inc. 2019 All Rights Reserved
import { RELATIONSHIP_TO_INSURED_OPTIONS } from "../../Providers/schema/newPatientFormSchema";
import { STATES } from "../../../../constants/states";

export const BASIC_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile: "",
    email: "",
    "address.street": "",
    "address.state": "",
    "address.city": "",
    "address.zip_code": "",
};

export const PREFERRED_NAME_SCHEMA_NON_PATIENT = Object.freeze({
    name: "preferred_name",
    elementType: "input",
    elementConfig: {
        type: "text",
        label: "Nickname/Preferred First Name",
        placeholder: "Nickname",
        optional: true,
    },
});

export const PREFERRED_NAME_SCHEMA_PATIENT = Object.freeze({
    name: "preferred_name",
    elementType: "input",
    elementConfig: {
        type: "text",
        label: "Nickname",
        placeholder: "Nickname",
        subtext: "What you’d like your provider to call you",
        optional: true,
    },
});

export const PREFERRED_PRONOUNS_SCHEMA = Object.freeze({
    name: "preferred_pronouns",
    elementType: "select",
    elementConfig: {
        options: [
            { value: "He / him", display: "He / him" },
            { value: "She / her", display: "She / her" },
            { value: "They / them", display: "They / them" },
        ],
        label: "Preferred Pronouns",
        placeholder: "",
        optional: true,
    },
});

export const BASIC_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Legal First Name",
            placeholder: "Legal First Name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Legal Last Name",
            placeholder: "Legal Last Name",
        },
    },
    PREFERRED_NAME_SCHEMA_NON_PATIENT,
    PREFERRED_PRONOUNS_SCHEMA,
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
];

export const PATIENT_BASIC_SCHEMA = [
    {
        name: "first_name",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "Legal First Name",
            placeholder: "Legal First Name",
        },
    },
    {
        name: "last_name",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "Legal Last Name",
            placeholder: "Legal Last Name",
        },
    },
    PREFERRED_NAME_SCHEMA_PATIENT,
    PREFERRED_PRONOUNS_SCHEMA,
    {
        name: "dob",
        elementType: "readOnly",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
];

export const EMERGENCY_SCHEMA = [
    {
        name: "emergency_contact_person",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Person",
            placeholder: "Emergency Contact Person",
        },
    },
    {
        name: "emergency_contact_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Number",
            placeholder: "Emergency Contact Number",
        },
    },
];

export const PRI_INSURANCE_SCHEMA = [
    {
        name: "primary_insurance.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "primary_insurance.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        name: "primary_insurance.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member ID",
            placeholder: "Enter Member ID",
        },
    },
    {
        name: "primary_insurance.group_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Group ID",
            placeholder: "Enter Group ID",
        },
    },
    {
        name: "primary_insurance.card_front",
        type: "f",
        elementType: "InsuranceCard",
    },
    {
        name: "primary_insurance.card_back",
        type: "b",
        elementType: "InsuranceCard",
    },
    {
        name: "primary_insurance.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "primary_insurance.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "primary_insurance.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];

export const SEC_INSURANCE_SCHEMA = [
    {
        name: "secondary_insurance.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "secondary_insurance.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        name: "secondary_insurance.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member Id",
            placeholder: "Enter Member Id",
        },
    },
    {
        name: "secondary_insurance.group_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Group Id",
            placeholder: "Enter Group Id",
        },
    },
    {
        name: "secondary_insurance.card_front",
        type: "f",
        elementType: "InsuranceCard",
    },
    {
        name: "secondary_insurance.card_back",
        type: "b",
        elementType: "InsuranceCard",
    },
    {
        name: "secondary_insurance.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "secondary_insurance.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "secondary_insurance.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];
