// Copyright aptihealth, inc. 2019 All Rights Reserved
import _ from "lodash";
import moment from "moment";
import momentTZ from "moment-timezone";
import { AVAILABLE, AWAY } from "../../constants/event";
import * as actionTypes from "../actions/actionTypes";
import { getLocalTimezone } from "./authReducer";

export const initialState = {
    viewType: "DAY",
    patientDropdownOptions: [],
    patientMap: {},
    onBehalfOfPatient: null,
    eventTypeOptions: [],
    selectedScheduleDate: null,
    selectedScheduleDates: [],
    events: [],
    provider: {
        providerId: null,
        providerType: null,
        availability: null,
        timeZone: null,
        name: null,
    },
    appointmentSelections: {
        eventType: null,
        time: null,
        startTime: null,
        endTime: null,
        patientId: null,
        acuityScore: null,
        seriesData: {
            frequencyDays: [],
            frequencyWeeks: null,
            isReoccurring: false,
            numberOfOccurrences: null,
        },
        callId: null,
        selectableTimes: [],
        selectableTimesFrom: [],
        selectableTimesTo: [],
        allottedTime: null,
        label: null,
    },
    appointmentSelectionErrors: {
        eventType: null,
        patientId: null,
        seriesData: null,
        time: null,
        startTime: null,
        endTime: null,
        label: null,
    },
    appointmentEditSelectionErrors: {
        untouched: null,
    },
    selectedEvents: [],
    selectedEvent: undefined,
    openDrawer: false,
    isInitialized: false,
    unconfirmedEvent: null,
    calendarFilters: {
        scheduledEvents: true,
        cancelledEvents: false,
        noShowEvents: false,
        rescheduledEvents: false,
    },
    showDatePicker: false,
    timezone: getLocalTimezone(),
};

const calendarInitialize = (state, action) => {
    return {
        ...state,
        selectedScheduleDate: action.data.selectedScheduleDate,
        isInitialized: true,
    };
};

const calendarInitializeProvider = (state, action) => {
    return {
        ...state,
        provider: action.data.provider,
    };
};

const calendarInitializeProviderAsPeerOrCM = (state, action) => {
    return {
        ...state,
        provider: action.data.provider,
        onBehalfOfPatient: action.data.patientId,
        appointmentSelections: {
            ...state.appointmentSelections,
            patientId: action.data.patientId,
            acuityScore: action.data.acuityScore,
        },
    };
};

const calendarUpdateScheduleCallList = (state, action) => {
    return {
        ...state,
        ...action.data,
    };
};

const calendarFetchPatientOptions = (state, action) => {
    return {
        ...state,
        patientDropdownOptions: action.data.patientDropdownOptions,
        patientMap: action.data.patientMap,
    };
};

const calendarFetchEventTypeOptions = (state, action) => {
    return {
        ...state,
        eventTypeOptions: action.data.eventTypeOptions,
        appointmentSelections: {
            ...state.appointmentSelections,
            acuityScore: action.data.acuityScore,
        },
    };
};

const calendarUpdateDate = (state, action) => {
    return {
        ...state,
        selectedScheduleDate: action.data.selectedScheduleDate,
        selectedScheduleDates: action.data.selectedScheduleDates,
    };
};

const calendarResetAppointmentSelections = (state, action) => {
    return {
        ...state,
        appointmentSelections:
            action?.data?.appointmentSelections ?? initialState.appointmentSelections,
        appointmentSelectionErrors: initialState.appointmentSelectionErrors,
    };
};

const calendarUpdateAppointmentSelections = (state, action) => {
    // merge doesn't play nice with arrays
    if (action.data?.seriesData?.frequencyDays) {
        let seriesData = {
            ...state.appointmentSelections?.seriesData,
            frequencyDays: action.data?.seriesData?.frequencyDays,
        };

        // If 2 fields present in series data we use set the whole object
        if (action.data?.seriesData?.numberOfOccurrences) {
            seriesData = action.data?.seriesData;
        }

        return {
            ...state,
            appointmentSelections: {
                ...state.appointmentSelections,
                seriesData: seriesData,
            },
        };
    }

    const mergedState = _.merge({}, state, { appointmentSelections: action.data });

    if (action.data.selectableTimes) {
        mergedState.appointmentSelections.selectableTimes = action.data.selectableTimes;
    }

    // if we are editing time value also update start and end time
    // so if the user switches over we provide a seamless experience
    // when switching to a event type that has a from and to time field
    if (action.data.time) {
        mergedState.appointmentSelections.startTime = action.data.time;
        mergedState.appointmentSelections.endTime = action.data.time;
    }
    // if we are editing startTime (aka From field) value also update time
    // so if the user switches over we provide a seamless experience
    // when switching to a event type that has a from and to time field
    else if (action.data.startTime) {
        mergedState.appointmentSelections.time = action.data.startTime;
    }

    return mergedState;
};

const calendarUpdateAppointmentSelectionErrors = (state, action) => {
    return {
        ...state,
        appointmentSelectionErrors: action.data.appointmentSelectionErrors,
    };
};

const calendarUpdateAppointmentEditSelectionErrors = (state, action) => {
    return {
        ...state,
        appointmentEditSelectionErrors: action.data.appointmentEditSelectionErrors,
    };
};

const calendarUpdateAppointmentLabel = (state, action) => {
    return {
        ...state,
        label: action.data.label,
    };
};

const calendarResetAppointmentEditSelectionErrors = (state, action) => {
    return {
        ...state,
        appointmentEditSelectionErrors: initialState.appointmentEditSelectionErrors,
    };
};

const calendarUpdateViewType = (state, action) => {
    return {
        ...state,
        viewType: action.data.viewType,
    };
};

const calendarUpdateSelectedEvents = (state, action) => {
    return {
        ...state,
        selectedEvents: action.data.selectedEvents,
        openDrawer: true,
        appointmentSelections:
            action?.data?.appointmentSelections ?? initialState.appointmentSelections,
    };
};

const calendarOpenAppointmentPicker = (state, action) => {
    return {
        ...state,
        openDrawer: true,
        selectedScheduleDate: action.data.selectedScheduleDate,
        appointmentSelections: {
            ...state.appointmentSelections,
            time: action.data.selectedScheduleTime,
            startTime: action.data.selectedScheduleTime,
            endTime: action.data.selectedScheduleTime,
        },
        selectedEvents: [],
    };
};

const calendarCloseDrawer = (state, action) => {
    return {
        ...state,
        openDrawer: false,
        selectedEvents: [],
        selectedEvent: undefined,
        appointmentSelections:
            action?.data?.appointmentSelections ?? initialState.appointmentSelections,
    };
};

const calendarEditAppointmentSelected = (state, action) => {
    const newState = {
        ...state,
        openDrawer: true,
        selectedEvents: [],
        selectedEvent: action.data.event,
        selectedScheduleDate: moment.utc(action.data.event.timestamp).tz(momentTZ.tz.guess()),
        appointmentSelections: {
            eventType: action.data.event.event_type,
            date: action.data.event.timestamp,
            time: moment.utc(action.data.event.timestamp).tz(momentTZ.tz.guess()).format("HH:mm"),
            patientId: action.data.event.patient_username,
            seriesData: initialState.appointmentSelections.seriesData, // Editing reoccuring appointment treats each appointment as individual
            callId: action.data.event.callId,
        },
    };
    if (action.data.event.event_type === AWAY) {
        newState.appointmentSelections.startTime = moment
            .utc(action.data.event.timestamp)
            .tz(momentTZ.tz.guess())
            .format("HH:mm");
        newState.appointmentSelections.endTime = moment
            .utc(action.data.event.timestamp)
            .tz(momentTZ.tz.guess())
            .add(action.data.event?.allotted_time, "minutes")
            .format("HH:mm");

        // Moment handles 12:00am as 00:00 in 24 hour format, since we are
        // representing the last time slot of the day as 24:00, and the only case
        // where end time will be 00:00 is when scheduling to the end of the day,
        // we can switch this to 24:00 which is functionally the same as 0:00
        if (newState.appointmentSelections.endTime === "00:00") {
            newState.appointmentSelections.endTime = "24:00";
        }

        newState.appointmentSelections.label = action.data.event.label;
    }

    if (action.data.event.event_type === AVAILABLE) {
        newState.appointmentSelections.startTime = moment
            .utc(action.data.event.timestamp)
            .tz(momentTZ.tz.guess())
            .format("HH:mm");
        newState.appointmentSelections.endTime = moment
            .utc(action.data.event.timestamp)
            .tz(momentTZ.tz.guess())
            .add(action.data.event?.allotted_time, "minutes")
            .format("HH:mm");
    }
    return newState;
};

const calendarUpsertUnconfirmedEvent = (state, action) => {
    return {
        ...state,
        unconfirmedEvent: action.data,
    };
};

const calendarDeleteUnconfirmedEvent = (state) => {
    return {
        ...state,
        unconfirmedEvent: initialState.unconfirmedEvent,
    };
};

const calendarUpdateFilters = (state, action) => {
    return {
        ...state,
        calendarFilters: action.data.filters,
    };
};

const calendarResetFilters = (state) => {
    return {
        ...state,
        calendarFilters: initialState.calendarFilters,
    };
};

const calendarToggleDatepicker = (state) => {
    return {
        ...state,
        showDatePicker: !state.showDatePicker,
    };
};

const calendarUpdateTimezone = (state, action) => {
    return {
        ...state,
        timezone: action.data.timezone,
    };
};

const calendarResetSchedulingMode = (state) => {
    return {
        ...state,
        onBehalfOfPatient: initialState.onBehalfOfPatient,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CALENDAR_INITIALIZE:
            return calendarInitialize(state, action);
        case actionTypes.CALENDAR_INITIALIZE_PROVIDER:
            return calendarInitializeProvider(state, action);
        case actionTypes.CALENDAR_INITIALIZE_BEHALF_OF_PROVIDER_PATIENT:
            return calendarInitializeProviderAsPeerOrCM(state, action);
        case actionTypes.CALENDAR_UPDATE_SCHEDULE_CALL_LIST:
            return calendarUpdateScheduleCallList(state, action);
        case actionTypes.CALENDAR_FETCH_PATIENT_OPTIONS:
            return calendarFetchPatientOptions(state, action);
        case actionTypes.CALENDAR_UPDATE_DATE:
            return calendarUpdateDate(state, action);
        case actionTypes.CALENDAR_FETCH_EVENT_TYPE_OPTIONS:
            return calendarFetchEventTypeOptions(state, action);
        case actionTypes.CALENDAR_RESET_APPOINTMENT_SELECTIONS:
            return calendarResetAppointmentSelections(state, action);
        case actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTIONS:
            return calendarUpdateAppointmentSelections(state, action);
        case actionTypes.CALENDAR_UPDATE_VIEW_TYPE:
            return calendarUpdateViewType(state, action);
        case actionTypes.CALENDAR_UPDATE_SELECTED_EVENTS:
            return calendarUpdateSelectedEvents(state, action);
        case actionTypes.OPEN_APPOINTMENT_PICKER:
            return calendarOpenAppointmentPicker(state, action);
        case actionTypes.CLOSE_DRAWER:
            return calendarCloseDrawer(state, action);
        case actionTypes.CALENDAR_EDIT_APPOINTMENT_SELECTED:
            return calendarEditAppointmentSelected(state, action);
        case actionTypes.CALENDAR_UPDATE_APPOINTMENT_SELECTION_ERRORS:
            return calendarUpdateAppointmentSelectionErrors(state, action);
        case actionTypes.CALENDAR_UPDATE_APPOINTMENT_EDIT_SELECTION_ERRORS:
            return calendarUpdateAppointmentEditSelectionErrors(state, action);
        case actionTypes.CALENDAR_RESET_APPOINTMENT_EDIT_SELECTION_ERRORS:
            return calendarResetAppointmentEditSelectionErrors(state, action);
        case actionTypes.CALENDAR_UPSERT_UNCONFIRMED_EVENT:
            return calendarUpsertUnconfirmedEvent(state, action);
        case actionTypes.CALENDAR_DELETE_UNCONFIRMED_EVENT:
            return calendarDeleteUnconfirmedEvent(state, action);
        case actionTypes.CALENDAR_UPDATE_FILTERS:
            return calendarUpdateFilters(state, action);
        case actionTypes.CALENDAR_RESET_FILTERS:
            return calendarResetFilters(state);
        case actionTypes.CALENDAR_TOGGLE_DATEPICKER:
            return calendarToggleDatepicker(state, action);
        case actionTypes.CALENDAR_UPDATE_TIMEZONE:
            return calendarUpdateTimezone(state, action);
        case actionTypes.CALENDAR_RESET_SCHEDULING_MODE:
            return calendarResetSchedulingMode(state);
        default:
            return state;
    }
};

export default reducer;
