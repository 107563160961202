import React, { useState } from "react";
import { api } from "../../../../../APIRequests";
import { Portal } from "../../../../Common/Modal/Portal";

import { getPatientProfile } from "../../../../../redux/actions/auth";
import { connect } from "react-redux";
import { CompleteProfileAddress } from "./CompleteProfileAddress";
import { CompleteProfileEmergencyContact } from "./CompleteProfileEmergencyContact";
import { CompleteProfilePhysician } from "./CompleteProfilePhysician";
import CompleteProfileHeaderWidget from "./CompleteProfileHeaderWidget";
import { CompletePreferredName } from "./CompletePreferredName";
import "./styles.scss";

export const COMPLETE_PROFILE_STEPS = Object.freeze({
    ADDRESS: "Address",
    EMERGENCY_CONTACT: "Emergency Contact",
    PRIMARY_CARE_PHYSICIAN: "Primary Care Physician",
    PREFERRED_NAME: "Preferred Name",
});

class CompleteProfileBodyComponent extends React.Component {
    render() {
        const {
            currentCompleteProfileStep,
            setCurrentCompleteProfileStep,
            updatedProfile,
            setUpdatedProfile,
            closeModal,
        } = this.props;

        const submitHandler = async (formData, actions) => {
            const mergedAccountData = { ...updatedProfile, ...formData };

            setUpdatedProfile(mergedAccountData);
            await api.patient.update_profile({ data: formData });
            this.props.getPatientProfile();
        };

        const formProps = {
            setCurrentCompleteProfileStep,
            submitHandler,
            profile: updatedProfile,
            closeModal,
        };

        return (
            <div className={"workflow-container"}>
                {currentCompleteProfileStep === COMPLETE_PROFILE_STEPS.ADDRESS && (
                    <CompleteProfileAddress {...formProps} />
                )}
                {currentCompleteProfileStep === COMPLETE_PROFILE_STEPS.EMERGENCY_CONTACT && (
                    <CompleteProfileEmergencyContact {...formProps} />
                )}
                {currentCompleteProfileStep === COMPLETE_PROFILE_STEPS.PRIMARY_CARE_PHYSICIAN && (
                    <CompleteProfilePhysician {...formProps} />
                )}
                {currentCompleteProfileStep === COMPLETE_PROFILE_STEPS.PREFERRED_NAME && (
                    <CompletePreferredName {...formProps} />
                )}
            </div>
        );
    }
}

const CompleteProfileBody = connect(null, { getPatientProfile })(CompleteProfileBodyComponent);

const CompleteProfileWidget = (props) => {
    const { profile } = props;
    const [currentCompleteProfileStep, setCurrentCompleteProfileStep] = useState(
        COMPLETE_PROFILE_STEPS.ADDRESS,
    );
    const completeProfileSteps = [
        COMPLETE_PROFILE_STEPS.ADDRESS,
        COMPLETE_PROFILE_STEPS.EMERGENCY_CONTACT,
        COMPLETE_PROFILE_STEPS.PRIMARY_CARE_PHYSICIAN,
        COMPLETE_PROFILE_STEPS.PREFERRED_NAME,
    ];
    const [updatedProfile, setUpdatedProfile] = useState(profile);
    const [closeHandler, setCloseHandler] = useState(null);
    const closeModal = () => setCloseHandler(true);

    return (
        <Portal
            isBottomCloseActive={false}
            openButtonRender={<span>Complete aptihealth Profile</span>}
            headerRender={
                <CompleteProfileHeaderWidget
                    currentCompleteProfileStep={currentCompleteProfileStep}
                    completeProfileSteps={completeProfileSteps}
                />
            }
            triggerModalStateChange={closeHandler}
            className={"CompleteProfileWidget--modal"}>
            <CompleteProfileBody
                currentCompleteProfileStep={currentCompleteProfileStep}
                setCurrentCompleteProfileStep={setCurrentCompleteProfileStep}
                updatedProfile={updatedProfile}
                setUpdatedProfile={setUpdatedProfile}
                closeModal={closeModal}
            />
        </Portal>
    );
};

export default CompleteProfileWidget;
