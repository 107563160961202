// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import ImageFullPreview from "../../Common/ImageFullPreview";
import { api } from "../../../APIRequests";
import {
    PRI_INSURANCE_SCHEMA,
    SEC_INSURANCE_SCHEMA,
} from "../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import { PRI } from ".";
import InsuranceInfo from "./InsuranceInfo";
import { trackPolicyAcknowledgement } from "../../../utils/userAgreementUtil";

class PatientProfileInsuranceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentType: null,
            acknowledgements: {},
        };
    }

    componentDidMount() {
        const options = { params: { showLoader: false } };
        api.patient
            .fetch_insurance_list({ options })
            .then((cleanResponse) => {
                let carriersOptions = cleanResponse.carriers.map((carriers) => {
                    return { value: carriers, display: carriers };
                });
                let statesOptions = cleanResponse.state.map((states) => {
                    return { value: states, display: states };
                });
                // modify original schema with received options list defined and set them in state
                PRI_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                SEC_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                this.setState({
                    pri_insurance_schema: PRI_INSURANCE_SCHEMA,
                    sec_insurance_schema: SEC_INSURANCE_SCHEMA,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setInsOptions = (el, carriersOptions, statesOptions) => {
        if (el.elementConfig && el.elementConfig.label === "Carrier") {
            el.elementConfig.options = carriersOptions;
        } else if (el.elementConfig && el.elementConfig.label === "State") {
            el.elementConfig.options = statesOptions;
        }
    };

    submitInsHandler = (formData, action) => {
        const data = {
            ...this.props.data,
            ...formData,
        };
        data.acknowledgements = {
            ...data.acknowledgements,
            ...this.state.acknowledgements,
        };
        this.props.submit(data, action, this.props.type);
    };

    renderImgFullPreview = (url, contentType) => {
        this.setState({
            insFullPreviewUrl: url,
            contentType: contentType,
        });
    };

    closeFullPreview = () => {
        this.setState({
            insFullPreviewUrl: null,
        });
    };

    addAcknowledgement = (policyName, acknowledgement) => {
        const { data } = this.props;
        if (data.acknowledgements && !data.acknowledgements[policyName]) {
            const acknowledgements = {
                ...this.state.acknowledgements,
                [policyName]: acknowledgement,
            };
            this.setState({ acknowledgements });
            trackPolicyAcknowledgement(policyName);
        }
    };

    render() {
        let fUrl, bUrl, ins, fContentType, bContentType;
        if (PRI === this.props.type) {
            fUrl = this.props.insuranceImageUrls.pfUrl;
            fContentType = this.props.insuranceImageTypes.pfType;
            bContentType = this.props.insuranceImageTypes.pbType;
            bUrl = this.props.insuranceImageUrls.pbUrl;
            ins = this.props.data.primary_insurance;
        } else {
            fUrl = this.props.insuranceImageUrls.sfUrl;
            fContentType = this.props.insuranceImageTypes.sfType;
            bContentType = this.props.insuranceImageTypes.sbType;
            bUrl = this.props.insuranceImageUrls.sbUrl;
            ins = this.props.data.secondary_insurance;
        }
        return (
            <>
                {this.state.insFullPreviewUrl && (
                    <ImageFullPreview
                        url={this.state.insFullPreviewUrl}
                        contentType={this.state.contentType}
                        close={this.closeFullPreview}
                    />
                )}
                <InsuranceInfo
                    {...this.props}
                    insuranceImageUrls={this.props.insuranceImageUrls}
                    insuranceImageStatuses={this.props.insuranceImageStatuses}
                    submit={this.submitInsHandler}
                    edit={this.props.edit}
                    onEdit={this.props.onEdit}
                    type={this.props.type}
                    emptyTitle={this.props.emptyTitle}
                    emptyDetails={this.props.emptyDetails}
                    editTitle={this.props.editTitle}
                    completedTitle={this.props.completedTitle}
                    isEditing={this.props.edit}
                    onEditButtonClick={this.props.onEdit}
                    onAddButtonClick={this.props.onEdit}
                    onCancelButtonClick={this.props.onEdit}
                    onFrontImageClick={() => {
                        this.renderImgFullPreview(fUrl, fContentType);
                    }}
                    onBackImageClick={() => this.renderImgFullPreview(bUrl, bContentType)}
                    insurance={ins}
                    addAcknowledgement={this.addAcknowledgement}
                />
            </>
        );
    }
}
export default PatientProfileInsuranceInfo;
