import React from "react";
import PropTypes from "prop-types";

import { createClassNameString, generateRandomId } from "../../utils";
import { TextTypes } from "../../constants";

import { InsuranceField } from "./_/InsuranceField";
import { Text } from "../../Text";
import { Image } from "../../Image";
import { PageBreak } from "../../PageBreak";

import "./styles.scss";
import { Popover } from "../../Popover";
import { AlertIcon, DocumentIcon } from "../../Icons";
import { FileScanStatus, FileScanStatusToMessage } from "../../../utils/fileUtil";

export const CompletedProfileInsurance = ({
    className,
    title,

    insuranceCompany,
    policyHolder,
    memberId,
    groupId,
    stateOfInsurancePlan,
    policyHolderName,
    policyHolderDOB,

    insuranceCardImgFrontSrc,
    insuranceCardImgFrontStatus,
    onInsuranceCardImgFrontClick,

    insuranceCardImgBackSrc,
    insuranceCardImgBackStatus,
    onInsuranceCardImgBackClick,

    onEditButtonClick,
    isEditable,
    ...props
}) => {
    const renderImageThumbnail = (imageSource, imageStatus, onClick) => {
        const tooltipId = `${generateRandomId()}-tooltip`;
        const dataTip = FileScanStatusToMessage[imageStatus];

        return (
            <>
                {(!imageStatus || imageStatus === FileScanStatus.READY) && imageSource && (
                    <img
                        className="apti-CompletedProfileInsurance--image"
                        src={imageSource}
                        onClick={onClick}
                    />
                )}
                {imageStatus === FileScanStatus.PENDING && (
                    <>
                        <span
                            data-tip={dataTip}
                            data-for={tooltipId}
                            className={"apti-CompletedProfileInsurance--tooltip-icon"}>
                            <DocumentIcon />
                        </span>
                        <Popover
                            toolTipId={tooltipId}
                            className={"apti-Info--popover"}
                            position={"top"}>
                            {dataTip}
                        </Popover>
                    </>
                )}
                {imageStatus === FileScanStatus.ERROR && (
                    <>
                        <span
                            data-tip={dataTip}
                            data-for={tooltipId}
                            className={"apti-CompletedProfileInsurance--tooltip-icon"}>
                            <AlertIcon />
                        </span>
                        <Popover
                            toolTipId={tooltipId}
                            className={"apti-Info--popover"}
                            position={"top"}>
                            {dataTip}
                        </Popover>
                    </>
                )}
            </>
        );
    };

    return (
        <div
            className={createClassNameString(["apti-CompletedProfileInsurance", className])}
            {...props}>
            <div className="apti-CompletedProfileInsurance--header">
                <Text type={TextTypes.heading2}>{title}</Text>
                {isEditable && (
                    <Image src="icons/green-edit-icon.svg" alt="edit" onClick={onEditButtonClick} />
                )}
            </div>
            <PageBreak />
            <div className="apti-CompletedProfileInsurance--body">
                <div className="apti-CompletedProfileInsurance--left">
                    <InsuranceField title="Insurance Company">{insuranceCompany}</InsuranceField>
                    <InsuranceField title="Member ID">{memberId}</InsuranceField>
                    <InsuranceField title="Insurance Card Images">
                        <div
                            className="apti-CompletedProfileInsurance--image-container"
                            data-private={"true"}>
                            {renderImageThumbnail(
                                insuranceCardImgFrontSrc,
                                insuranceCardImgFrontStatus,
                                onInsuranceCardImgFrontClick,
                            )}
                            {renderImageThumbnail(
                                insuranceCardImgBackSrc,
                                insuranceCardImgBackStatus,
                                onInsuranceCardImgBackClick,
                            )}
                        </div>
                    </InsuranceField>
                    <InsuranceField title="Policy Holder Name">{policyHolderName}</InsuranceField>
                </div>
                <div className="apti-CompletedProfileInsurance--right">
                    <InsuranceField title="Policy Holder">{policyHolder}</InsuranceField>
                    <InsuranceField title="Group ID">{groupId}</InsuranceField>
                    <InsuranceField title="State of Insurance Plan">
                        {stateOfInsurancePlan}
                    </InsuranceField>
                    <InsuranceField title="Policy Holder Date of Birth">
                        {policyHolderDOB}
                    </InsuranceField>
                </div>
            </div>
        </div>
    );
};

CompletedProfileInsurance.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,

    insuranceCompany: PropTypes.string.isRequired,
    policyHolder: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    stateOfInsurancePlan: PropTypes.string.isRequired,
    policyHolderName: PropTypes.string.isRequired,
    policyHolderDOB: PropTypes.string.isRequired,

    insuranceCardImgFrontSrc: PropTypes.string.isRequired,
    insuranceCardImgFrontStatus: PropTypes.string,
    onInsuranceCardImgFrontClick: PropTypes.func,

    insuranceCardImgBackSrc: PropTypes.string.isRequired,
    insuranceCardImgBackStatus: PropTypes.string,
    onInsuranceCardImgBackClick: PropTypes.func,

    isEditable: PropTypes.bool.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
};

CompletedProfileInsurance.defaultProps = {
    className: undefined,
    title: "",

    insuranceCompany: "",
    policyHolder: "",
    memberId: "",
    groupId: "",
    stateOfInsurancePlan: "",
    policyHolderName: "",
    policyHolderDOB: "",

    insuranceCardImgFrontSrc: null,
    insuranceCardImgFrontStatus: null,
    onInsuranceCardImgFrontClick: () => {},

    insuranceCardImgBackSrc: null,
    insuranceCardImgBackStatus: null,
    onInsuranceCardImgBackClick: () => {},

    onEditButtonClick: "",
    isEditable: true,
};
