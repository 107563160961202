import React from "react";
import _ from "lodash";

const zipWithIndex = (elements) => {
    let counter = 0;
    let indexedSteps = [];
    for (const e of elements) {
        const result = [counter, e];
        indexedSteps.push(result);
        counter += 1;
    }

    return indexedSteps;
};

const makeTextLines = (label, maxCharsPerLabelLine) => {
    let counter = 0;
    let currentLine = "";
    let textLines = [];
    for (const word of label.split(" ")) {
        const cleanWord = word.trim();

        if (counter === 0 && cleanWord.length >= maxCharsPerLabelLine) {
            textLines.push(cleanWord);
            currentLine = "";
            counter = 0;
        } else if (currentLine.length + cleanWord.length + 1 > maxCharsPerLabelLine) {
            textLines.push(currentLine);
            currentLine = cleanWord;
            counter = 0;
        } else {
            if (counter === 0) {
                currentLine = cleanWord;
            } else {
                currentLine = `${currentLine} ${cleanWord}`;
            }
            counter += 1;
        }
    }

    textLines.push(currentLine);
    return textLines.filter((line) => !_.isEmpty(line));
};

const StepLabel = ({ name, spacingProps, xAxis, color }) => {
    const { yOffset, maxCharsPerLabelLine } = spacingProps;
    const textLines = zipWithIndex(makeTextLines(name, maxCharsPerLabelLine));
    const normalizedX = xAxis; //- (lineOffset/2 + 5)
    const normalizedY = yOffset + 25;
    const textPixelSize = 18;

    return (
        <text
            x={normalizedX}
            y={normalizedY}
            fill={color}
            className={"CompleteProfileHeaderWidget--step-label"}>
            {textLines.map(([idx, line]) => (
                <tspan x={normalizedX} y={normalizedY + idx * textPixelSize} textAnchor="middle">
                    {line}
                </tspan>
            ))}
            {/* {name} */}
        </text>
    );
};

const StepDot = ({ name, stepIndex, spacingProps }) => {
    const { currentIndex, lineOffset, desiredSpacing, yOffset } = spacingProps;
    // Default color is grey
    let color = "rgb(169, 169, 169)";
    if (stepIndex <= currentIndex) {
        color = "rgb(12,208,167)";
    }
    const xAxis = desiredSpacing * stepIndex + lineOffset;

    return (
        <>
            <circle cx={xAxis} cy={yOffset} r="8" stroke={color} strokeWidth="3" fill={color} />
            <StepLabel name={name} spacingProps={spacingProps} xAxis={xAxis} color={color} />
        </>
    );
};

const CompleteProfileHeaderWidget = ({ currentCompleteProfileStep, completeProfileSteps }) => {
    const sizeOfWindow = window.innerWidth;
    let availableSize = 500;
    const numberOfSpaces = completeProfileSteps.length - 1;
    const yOffset = 30;
    const maxCharsPerLabelLine = 12;

    let lineOffset = 50;
    let desiredSpacing = (availableSize - 2 * lineOffset) / numberOfSpaces;

    if (sizeOfWindow < 800) {
        // Only subset of size available
        availableSize = sizeOfWindow * 0.8;
        // Time for math
        // Each line offset should be 10% from the edge
        lineOffset = availableSize * 0.1;
        desiredSpacing = (availableSize - 2 * lineOffset) / numberOfSpaces;
    }

    const currentIndex = _.indexOf(completeProfileSteps, currentCompleteProfileStep);
    const totalSvgWidth = lineOffset * 2 + numberOfSpaces * desiredSpacing;
    const lineBreak = currentIndex * desiredSpacing + lineOffset;
    const indexedSteps = zipWithIndex(completeProfileSteps);
    const spacingProps = {
        currentIndex,
        lineOffset,
        desiredSpacing,
        yOffset,
        maxCharsPerLabelLine,
    };

    let circles = indexedSteps.map(([stepIndex, stepName]) => (
        <StepDot name={stepName} stepIndex={stepIndex} spacingProps={spacingProps} />
    ));

    return (
        <div className="container">
            <div className={"row"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                    Complete your profile
                </div>
            </div>
            <div className={"row justify-content-center"}>
                <svg
                    height="100"
                    width={totalSvgWidth}
                    style={{ "padding-top": "15px", "text-align": "center" }}>
                    <line
                        x1={lineOffset}
                        y1={yOffset}
                        x2={lineBreak}
                        y2={yOffset}
                        style={{ stroke: "rgb(12,208,167)", "stroke-width": "3" }}
                    />
                    <line
                        x1={lineBreak}
                        y1={yOffset}
                        x2={(completeProfileSteps.length - 1) * desiredSpacing + lineOffset}
                        y2={yOffset}
                        style={{ stroke: "rgb(169,169,169)", "stroke-width": "3" }}
                    />
                    {circles}
                </svg>
            </div>
            <div className={"row"}>
                <h6 className="w-100 mt-3">
                    <b className={"txt-sec"}>{currentCompleteProfileStep}</b>
                </h6>
            </div>
        </div>
    );
};

export default CompleteProfileHeaderWidget;
