// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";
import { httpAuth } from "../../utils/axios";
import moment from "moment-timezone";
import LogRocket from "logrocket";

const loadAssessment = (startQuestion, assessmentPerc) => {
    return {
        type: actionTypes.START_ASSESSMENT,
        payload: { startQuestion, assessmentPerc },
    };
};

const nextQuestion = (nextQuestion, isLast, assessmentPerc) => {
    return {
        type: actionTypes.NEXT_QUESTION,
        payload: { nextQuestion, isLast, assessmentPerc },
    };
};

const previousQuestion = (payload) => {
    return {
        type: actionTypes.PREVIOUS_QUESTION,
        payload,
    };
};

const selectOption = (options) => {
    return {
        type: actionTypes.OPTION_SELECTED,
        payload: options,
    };
};

const setAssessmentStatus = (status) => {
    return {
        type: actionTypes.SET_ASSESSMENT_STATUS,
        payload: status,
    };
};

export const startAssessment = (assessmentType, errorTypeConfig) => (dispatch) => {
    let promise = null;

    if (assessmentType === "post_90_day") {
        promise = api.patient.start_outcome_assessment();
    } else {
        let data = {};

        if (assessmentType != null && assessmentType !== "") {
            data["assessment_type"] = assessmentType;
        }

        promise = api.patient.get_next_question({ data, errorTypeConfig });
    }

    promise.then((cleanResponse) => {
        if (!cleanResponse) {
            dispatch({ type: actionTypes.NO_ASSESSMENT });
        } else {
            let question = cleanResponse.question;
            if (question == null) {
                dispatch({ type: actionTypes.START_RATING_SCALE });
            } else {
                let assessmentPerc = cleanResponse.percentage;
                dispatch(loadAssessment(cleanResponse.question, assessmentPerc));
            }
        }
    });
};

export const getNextQuestion = (requestBody, assessmentType, errorTypeConfig) => (dispatch) => {
    let request =
        "post_90_day" !== assessmentType
            ? api.patient.submit_question
            : api.patient.fetch_next_outcome_question;
    request({ data: requestBody, errorTypeConfig }).then((cleanResponse) => {
        if (cleanResponse === null) {
            dispatch({ type: actionTypes.ASSESSMENT_COMPLETED });
            console.error("Unexpected response from assessment submit_question");
            LogRocket.captureMessage("Unexpected response from assessment submit_question");
            return;
        }
        let nextQ = cleanResponse.question;
        let assessmentPerc = cleanResponse.percentage;
        let isLast = (cleanResponse.question && cleanResponse.question.is_last) || false;
        if (nextQ == null) {
            dispatch({ type: actionTypes.ASSESSMENT_COMPLETED });
        } else {
            dispatch(nextQuestion(nextQ, isLast, assessmentPerc));
        }
    });
};

export const getPreviousQuestion = (assessmentType, lastQuestionErrorTypeConfig) => (dispatch) => {
    let promise = null;

    if (assessmentType === "post_90_day") {
        promise = api.patient.fetch_previous_outcome_question();
    } else {
        let data = {};

        if (assessmentType != null && assessmentType !== "") {
            data["assessment_type"] = assessmentType;
        }

        promise = api.patient.get_last_question({
            data,
            errorTypeConfig: lastQuestionErrorTypeConfig,
        });
    }

    promise.then((cleanResponse) => {
        let assessmentPerc = cleanResponse.percentage;
        let payload = {
            assessmentPerc,
            currentQuestion: cleanResponse.question,
        };
        dispatch(previousQuestion(payload));
    });
};

export const getAssessmentStatus = (options) => (dispatch) => {
    options = { params: { showLoader: false } };
    api.patient.fetch_assessment_status({ options }).then((cleanResponse) => {
        let status = cleanResponse.user;
        dispatch(setAssessmentStatus(status));
    });
};

export const setSelectedOptions = (selectedOptions, selectedOptionFull) => (dispatch) => {
    dispatch(selectOption({ selectedOptions, selectedOptionFull }));
};

export const submitRatingScaleQuestions = (data) => (dispatch) => {
    return api.patient.submit_rating_scale_questions({ data }).then((response) => {
        dispatch({ type: actionTypes.ASSESSMENT_COMPLETED });
    });
};

export const updateScheduleList = () => (dispatch) => {
    api.schedule
        .get_schedule({
            options: {
                params: {
                    showLoader: false,
                },
                queryParams: {
                    local_start_date_time: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
                    timezone: moment.tz.guess(),
                },
            },
        })
        .then((response) => {
            dispatch({
                type: actionTypes.UPDATE_SCHEDULE_LIST,
                payload: {
                    scheduleList: response.schedule || [],
                },
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.UPDATE_SCHEDULE_LIST,
                payload: {
                    scheduleList: [],
                },
            });
        });
};

export const updateActivityList = (data) => {
    return {
        type: actionTypes.UPDATE_ACTIVITY_LIST,
        payload: {
            activityList: data,
        },
    };
};

export const updateCrisisRisk = (data) => {
    return {
        type: actionTypes.UPDATE_CRISIS_RISK,
        payload: {
            crisisRisk: data,
        },
    };
};

export const allowLoginPopUps = (firstLogin = false) => {
    const payload = {
        allowInactivePopUp: true,
        allowWaitListPopUp: true,
    };
    return {
        type: actionTypes.ALLOW_LOGIN_POP_UPS,
        payload,
    };
};

export const disableLoginPopUps = () => {
    return {
        type: actionTypes.DISABLE_LOGIN_POP_UPS,
    };
};

// ----------- Thunks --------------

export const fetchActivityList = ({ urlParams, options, dispatch } = {}) => {
    const urlQueryString = urlParams ? new URLSearchParams(urlParams) : "";
    let conf = {
        method: "post",
        url: `/v1/patient/activity?${urlQueryString}`,
        ...options,
    };

    return httpAuth(conf).then((data) => {
        dispatch(updateActivityList(data));
        return data;
    });
};

export const fetchAssessmentCrisisRisk = ({ urlParams, options, dispatch } = {}) => {
    const urlQueryString = urlParams ? new URLSearchParams(urlParams) : "";
    let conf = {
        method: "post",
        url: `/v1/a5_screening/get_crisis_risk?${urlQueryString}`,
        ...options,
    };

    return httpAuth(conf).then((data) => {
        dispatch(updateCrisisRisk(data));
        return data;
    });
};
