// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, createRef } from "react";
import video, { Logger } from "twilio-video";
import { api } from "../../APIRequests";
import { getQueryParams } from "../../utils/filters";
import { connect } from "react-redux";
import { showAlertWithAction, showAlertWithCustomHTML } from "../../redux/actions/alerts";
import moment from "moment-timezone";
import { isAuthorized, resetSessionTimeout } from "../../redux/actions/auth";
import _ from "lodash";
import Spinner from "../../components/UI/Spinner";
import { GuidedVideoCall } from "../../components/Common/GuidedVideoCall";
import { getCPTWorkflow } from "../../components/Common/GuidedVideoCall/Steps/ProviderWorkflow/ProviderWorkflows";
import { deviceDetect } from "react-device-detect";
import { EXTENSIBLE_TOAST_ERROR } from "../../components/UI/Alert";
import LogRocket from "logrocket";

const UNSTABLE_CONNECT_ERROR_MESSAGE =
    "Your internet connection appears unstable, which may cause disruptions in this call. If you're experiencing issues, move to a location with a stronger connection.";

class VideoClient extends Component {
    localVideoRef = createRef();
    localAudioRef = createRef();
    remoteVideoRef = createRef();
    remoteAudioRef = createRef();
    remoteVideoPlaceholderRef = createRef();

    localMedia = (
        <>
            <video ref={this.localVideoRef} autoPlay={true} />
            <audio ref={this.localAudioRef} autoPlay={true} />
        </>
    );

    remoteMedia = (
        <>
            <video ref={this.remoteVideoRef} autoPlay={true} />
            <audio ref={this.remoteAudioRef} autoPlay={true} />
        </>
    );

    state = {
        roomName: null,
        otherParticipantName: null,
        callDuration: 0,
        activeRoom: null,
        audioOff: false,
        videoOff: false,
        startTouchPosition: "",
        localTracks: [],
        remoteTracks: [],
        step: "ALLOW_AV",
        callDetails: null,
        pss_enabled: false,
    };

    async componentDidMount() {
        /**
         * fetch the callId and otherParticipantName from url
         * URL example: `www.domain.com/video-call?callId=xxx&userName=userName=yyyy`
         */
        let callId = getQueryParams("callId", this.props.match.search);
        let tokenResponse;

        try {
            tokenResponse = await this.fetchTwilioToken();
        } catch (e) {
            this.props.history.push("/app/home");
        }

        if (!tokenResponse) {
            return;
        }
        let callDetails = tokenResponse.call_details;
        let startTime = moment.utc(callDetails.timestamp);
        let allottedTime = tokenResponse.call_details.allotted_time;
        let warningTime = moment.utc(callDetails.timestamp).add(allottedTime - 5, "minutes");
        let endTime = moment.utc(callDetails.timestamp).add(allottedTime, "minutes");

        let workflow;
        let otherParticipantName;
        if (!isAuthorized("user:patient")) {
            // this is where the workflow configs are pulled in and set in state
            // based on the calls cpt code
            workflow = _.cloneDeep(getCPTWorkflow(callDetails.event_type, this.props.profile));
            otherParticipantName = callDetails.patient_name;
        } else {
            otherParticipantName = callDetails.provider_name;
        }

        this.resetSessionInterval = setInterval(resetSessionTimeout, 5 * 1000 * 60);

        this.setState({
            otherParticipantName,
            roomName: callId,
            token: tokenResponse.access_token,
            startTime,
            endTime,
            allottedTime,
            warningTime,
            callDetails,
            workflow,
            pss_enabled: _.get(this.props, "configs.POST_SESSION_SURVEY", false),
        });

        this.setLoggers(callId, this.props.showAlertWithAction);
    }

    setLoggers(callId, alertFunction) {
        let logger = Logger.getLogger("twilio-video");
        const originalFactory = logger.methodFactory;
        logger.methodFactory = function (methodName, level, loggerName) {
            const loggerMethod = originalFactory(methodName, level, loggerName);

            return (datetime, logLevel, component, message, metadata) => {
                loggerMethod(datetime, logLevel, component, message, metadata);
                if (message === "event" && metadata.group === "signaling") {
                    if (metadata.name === "closed" && metadata.level === "error") {
                        let data = generateVideoCallErrorLog(
                            callId,
                            message,
                            "VideoClient.twilioErrorLogging",
                            metadata,
                        );
                        api.shared.report_video_call_error({ data });
                        alertFunction(UNSTABLE_CONNECT_ERROR_MESSAGE);
                    }
                }
            };
        };

        logger.setLevel("error");
    }

    async fetchTwilioToken() {
        let callId = getQueryParams("callId", this.props.match.search);
        if (!callId) {
            this.props.showAlertWithAction("Invalid Request. Missing required field: callId.");
            this.props.history.push("/app/home");
            return;
        }
        let data = {
            call_id: callId,
            device: {
                device_info: deviceDetect(),
                screen: {
                    width: window.screen.width,
                    height: window.screen.height,
                    devicePixelRatio: Math.round(window.devicePixelRatio * 100),
                },
            },
        };
        return api.shared.fetch_twilio_token({ data });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.profile && this.props.profile) {
            let callId = getQueryParams("callId", this.props.match.search);
            if (!callId) {
                this.props.showAlertWithAction("Invalid Request. Missing required field: callId.");
                this.props.history.push("/app/home");
            }
        }
    }

    componentDidCatch(error, errorInfo) {
        let data = generateVideoCallErrorLog(
            this.state.callDetails.callId,
            error,
            "VideoClient.componentDidCatch",
            errorInfo,
        );
        api.shared.report_video_call_error({ data });
    }

    componentWillUnmount() {
        /**
         * Clean up after component unmounts
         */
        if (this.state.activeRoom) {
            this.endCall();
        }

        if (this.state.localTracks.length > 0) {
            this.detachTracks(this.state.localTracks);
        }

        if (this.resetSessionInterval) {
            clearInterval(this.resetSessionInterval);
        }
    }

    startPreview = async () => {
        try {
            const localTracks = await video.createLocalTracks();

            await this.setState({
                step: "PREVIEW",
                localTracks,
            });

            this.attachTracks(localTracks, this.localVideoRef, this.localAudioRef);
        } catch (e) {
            console.log(e);
            await this.attachError(e);
        }
    };

    attachLocalTracks = () => {
        if (this.state.localTracks.length > 0) {
            this.attachTracks(this.state.localTracks, this.localVideoRef, this.localAudioRef);
        }
    };

    attachRemoteTracks = () => {
        if (this.state.remoteTracks.length > 0) {
            this.attachTracks(this.state.remoteTracks, this.remoteVideoRef, this.remoteAudioRef);
        }
    };

    setStep = (step) => {
        this.setState({ step });
    };

    startQuestions = () => {
        this.setState({ step: "QUESTIONS" });
    };

    startCall = async () => {
        if (!this.state.roomName.trim()) {
            this.props.showAlertWithAction("No room found");
            return;
        }

        let connectOptions = {
            name: this.state.roomName,
        };

        if (this.state.localTracks) {
            connectOptions.tracks = this.state.localTracks;
        }

        video.connect(this.state.token, connectOptions).then(this.roomJoined, this.attachError);
    };

    attachError = async (error) => {
        let message;
        switch (error.name) {
            case "NotReadableError":
                message =
                    "Close any other applications using your camera or microphone. Close other browser tabs or restart your browser, then rejoin the call.";
                break;
            case "NotFoundError":
                message =
                    "Ensure your camera and microphone are both connected. Verify you have given aptihealth permission to access these devices, then rejoin the call.";
                break;
            case "OverconstrainedError":
                message =
                    "Ensure your camera and microphone are still connected, then rejoin the call.";
                break;
            case "NotAllowedError":
                message =
                    "You have denied permission to let aptihealth use your microphone and camera. " +
                    "The video call will not be able to proceed. Please contact support at support@aptihealth.com if you need help enabling permissions.";
                break;
            case "TwilioError":
                switch (error.code) {
                    case 20104:
                        if (!this.state.tokenErrorRefreshAttempted) {
                            let tokenResponse = await this.fetchTwilioToken();
                            if (!tokenResponse) {
                                return;
                            }
                            this.setState(
                                {
                                    token: tokenResponse.access_token,
                                    tokenErrorRefreshAttempted: true,
                                },
                                this.startCall,
                            );
                        } else {
                            let data = generateVideoCallErrorLog(
                                this.state.callDetails.callId,
                                error,
                                "VideoClient.attachError",
                            );
                            api.shared.report_video_call_error({ data });
                        }
                        break;
                    case 53000:
                        message = UNSTABLE_CONNECT_ERROR_MESSAGE;
                        break;
                    case 53205:
                        message =
                            "You may have joined this call from multiple browsers or browser tabs. Close out all other browsers and browser tabs, then rejoin the call.";
                        break;
                    case 53404:
                        message =
                            "Your browser or browser version may not be supported. Try another browser, or update your current browser, then rejoin the call.";
                        break;
                    default:
                        break;
                }
                break;
            case "AbortError":
                message =
                    "We're setting up your video. Please wait a few more seconds. If nothing happens, try refreshing the page.";
                LogRocket.captureMessage(error.toString());
                break;
            default:
                let data = generateVideoCallErrorLog(
                    this.state.callDetails.callId,
                    error,
                    "VideoClient.attachError",
                );
                api.shared.report_video_call_error({ data });
                if (error.message === "getUserMedia is not supported") {
                    message =
                        "You are using an unsupported OS and/or Browser. " +
                        "If you are using an iOS device try switching to Safari. " +
                        "Please contact support at support@aptihealth.com if you continue to have issues.";
                }
                break;
        }

        if (message) {
            this.props.showAlertWithAction(message);
        } else{
            const unknownErrorMessage = 
                <div>
                    <p>An error occurred while starting the call. Please try the following:</p>
                    <ul>
                        <li> - Close other apps or tabs using the camera.</li>
                        <li> - Restart your browser.</li>
                        <li> - Try a different browser.</li>
                        <li> - Check your camera permissions.</li>
                        <li> - Ensure your camera is connected.</li>
                    </ul>
                    <p>If the issue persists, contact support at <a style={{ color: 'white', textDecoration: 'underline' }} href="mailto:support@aptihealth.com">support@aptihealth.com</a>.</p>
                </div>;
            this.props.showAlertWithCustomHTML(unknownErrorMessage, EXTENSIBLE_TOAST_ERROR, false, "SnackBar__GenericVideoError");
        }
    };

    roomJoined = async (room) => {
        room.on("participantConnected", this.participantConnected);
        room.on("participantDisconnected", this.participantDisconnected);
        room.on("disconnected", () => this.detachTracks(this.state.localTracks));
        room.participants.forEach(this.participantConnected);

        let tokenResponse = await this.fetchTwilioToken();
        if (!tokenResponse) {
            return;
        }
        let callDetails = tokenResponse.call_details;

        await this.setState({
            activeRoom: room,
            step: "IN_ROOM",
            callDetails,
        });

        this.attachLocalTracks();
        this.attachRemoteTracks();
    };

    participantConnected = async (participant) => {
        participant.on("trackPublished", this.trackPublished);
        participant.on("trackSubscriptionFailed", this.trackSubscriptionFailed);
        participant.tracks.forEach(this.trackPublished);
        if (!isAuthorized("user:patient")) {
            let tokenResponse = await this.fetchTwilioToken();
            if (!tokenResponse) {
                return;
            }
            let callDetails = tokenResponse.call_details;
            await this.setState({ callDetails });
        }
    };

    trackPublished = async (publication) => {
        publication.on("subscribed", this.trackSubscribed);
    };

    trackSubscribed = async (track) => {
        let remoteTracks = this.state.remoteTracks;
        remoteTracks.push(track);
        await this.setState({ remoteTracks });
        this.attachTracks(remoteTracks, this.remoteVideoRef, this.remoteAudioRef);
    };

    trackSubscriptionFailed = async (error) => {
        let data = generateVideoCallErrorLog(
            this.state.callDetails.callId,
            error,
            "VideoClient.trackSubscriptionFailed",
        );
        api.shared.report_video_call_error({ data });
    };

    participantDisconnected = (participant) => {
        this.setState({
            remoteTracks: [],
            participantDisconnected: true,
        });
    };

    endCall = (route = true) => {
        this.state.activeRoom.disconnect();

        if (!route) {
            return;
        }

        if (isAuthorized("user:patient")) {
            if (!this.state.pss_enabled) {
                this.props.history.push(`/app/video-call/ratings?callId=${this.state.roomName}`);
                return;
            }

            if (
                this.state.remoteTracks.length === 0 &&
                !this.state.participantDisconnected &&
                this.state.callDetails.provider_role !== "BEHAVIORAL_INTAKE" &&
                this.state.callDetails.provider_role !== "PRESCRIBE"
            ) {
                this.setStep("PROVIDER_MESSAGE_STEP");
            } else {
                this.setStep("RECONNECT");
            }
            return;
        }

        if (!isAuthorized("user:patient")) {
            this.props.history.push(this.props.authRedirectPath);
        }
    };

    redirectToMessageProviderDialog = () => {
        if (
            this.state.pss_enabled &&
            this.state.callDetails.provider_role !== "BEHAVIORAL_INTAKE" &&
            this.state.callDetails.provider_role !== "PRESCRIBE"
        ) {
            this.setStep("PROVIDER_MESSAGE_STEP");
        } else {
            this.props.history.push("/app/home");
        }
    };

    attachTracks = (tracks, videoRef, audioRef) => {
        tracks.forEach((track) => {
            if (track.kind === "data") {
                return;
            }

            let ref = track.kind === "video" ? videoRef : audioRef;

            if (track?.attach && typeof track.attach == "function") {
                track.attach(ref.current);
            } else {
                this.attachError(
                    `TypeError: e.attach is not a function. track: ${JSON.stringify(track)} \n`,
                );
            }
        });
    };

    detachTracks = (tracks) => {
        tracks.forEach((track) => {
            track.stop();
        });
    };

    diffTimesInMinutes = (startTime, endTime) => {
        return moment.duration(startTime.diff(endTime)).asMinutes();
    };

    render() {
        if (!this.state.callDetails) {
            return <Spinner />;
        }

        return (
            <GuidedVideoCall
                step={this.state.step}
                activeRoom={this.state.activeRoom}
                setStep={this.setStep}
                startQuestions={this.startQuestions}
                startPreview={this.startPreview}
                startCall={this.startCall}
                endCall={this.endCall}
                localTracks={this.state.localTracks}
                localMedia={this.localMedia}
                localVideoRef={this.localVideoRef}
                localAudioRef={this.localAudioRef}
                attachLocalTracks={this.attachLocalTracks}
                remoteTracks={this.state.remoteTracks}
                remoteMedia={this.remoteMedia}
                remoteVideoRef={this.remoteVideoRef}
                remoteAudioRef={this.remoteAudioRef}
                remoteVideoPlaceholderRef={this.remoteVideoPlaceholderRef}
                attachRemoteTracks={this.attachRemoteTracks}
                otherParticipantName={this.state.otherParticipantName}
                startTime={this.state.startTime}
                warningTime={this.state.warningTime}
                allottedTime={this.state.allottedTime}
                participantDisconnected={this.state.participantDisconnected}
                callDetails={this.state.callDetails}
                workflow={this.state.workflow}
                redirectToMessageProviderDialog={this.redirectToMessageProviderDialog}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        configs: state.configs,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

export default connect(mapStateToProps, { showAlertWithAction, showAlertWithCustomHTML })(VideoClient);

export const generateVideoCallErrorLog = (callId, error, location, additionalInfo = {}) => {
    return {
        call_id: callId,
        error: error.toString(),
        stack: error.stack,
        additional_info: additionalInfo,
        location: location,
        client: "web",
    };
};
